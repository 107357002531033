.pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis, .file-cta, .file-name, .select select, .textarea, .input, .button {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: none;
  vertical-align: top;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 4px;
  justify-content: flex-start;
  align-items: center;
  height: 2.5em;
  padding: calc(.5em - 1px) calc(.75em - 1px);
  font-size: 1rem;
  line-height: 1.5;
  display: inline-flex;
  position: relative;
}

.pagination-previous:focus, .pagination-next:focus, .pagination-link:focus, .pagination-ellipsis:focus, .file-cta:focus, .file-name:focus, .select select:focus, .textarea:focus, .input:focus, .button:focus, .is-focused.pagination-previous, .is-focused.pagination-next, .is-focused.pagination-link, .is-focused.pagination-ellipsis, .is-focused.file-cta, .is-focused.file-name, .select select.is-focused, .is-focused.textarea, .is-focused.input, .is-focused.button, .pagination-previous:active, .pagination-next:active, .pagination-link:active, .pagination-ellipsis:active, .file-cta:active, .file-name:active, .select select:active, .textarea:active, .input:active, .button:active, .is-active.pagination-previous, .is-active.pagination-next, .is-active.pagination-link, .is-active.pagination-ellipsis, .is-active.file-cta, .is-active.file-name, .select select.is-active, .is-active.textarea, .is-active.input, .is-active.button {
  outline: none;
}

[disabled].pagination-previous, [disabled].pagination-next, [disabled].pagination-link, [disabled].pagination-ellipsis, [disabled].file-cta, [disabled].file-name, .select select[disabled], [disabled].textarea, [disabled].input, [disabled].button, fieldset[disabled] .pagination-previous, fieldset[disabled] .pagination-next, fieldset[disabled] .pagination-link, fieldset[disabled] .pagination-ellipsis, fieldset[disabled] .file-cta, fieldset[disabled] .file-name, fieldset[disabled] .select select, .select fieldset[disabled] select, fieldset[disabled] .textarea, fieldset[disabled] .input, fieldset[disabled] .button {
  cursor: not-allowed;
}

.is-unselectable, .tabs, .pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis, .breadcrumb, .file, .button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.navbar-link:not(.is-arrowless):after, .select:not(.is-multiple):not(.is-loading):after {
  content: " ";
  pointer-events: none;
  -webkit-transform-origin: center;
  transform-origin: center;
  border-top: 0;
  border-bottom: 3px solid rgba(0, 0, 0, 0);
  border-left: 3px solid rgba(0, 0, 0, 0);
  border-right: 0;
  border-radius: 2px;
  width: .625em;
  height: .625em;
  margin-top: -.4375em;
  display: block;
  position: absolute;
  top: 50%;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.tabs:not(:last-child), .pagination:not(:last-child), .message:not(:last-child), .level:not(:last-child), .breadcrumb:not(:last-child), .block:not(:last-child), .title:not(:last-child), .subtitle:not(:last-child), .table-container:not(:last-child), .table:not(:last-child), .progress:not(:last-child), .notification:not(:last-child), .content:not(:last-child), .box:not(:last-child) {
  margin-bottom: 1.5rem;
}

.modal-close, .delete {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  pointer-events: auto;
  vertical-align: top;
  background-color: rgba(10, 10, 10, .2);
  border: none;
  border-radius: 9999px;
  outline: none;
  flex-grow: 0;
  flex-shrink: 0;
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  min-height: 20px;
  max-height: 20px;
  font-size: 0;
  display: inline-block;
  position: relative;
}

.modal-close:before, .delete:before, .modal-close:after, .delete:after {
  content: "";
  -webkit-transform-origin: center;
  transform-origin: center;
  background-color: #fff;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%)translateY(-50%)rotate(45deg);
  transform: translateX(-50%)translateY(-50%)rotate(45deg);
}

.modal-close:before, .delete:before {
  width: 50%;
  height: 2px;
}

.modal-close:after, .delete:after {
  width: 2px;
  height: 50%;
}

.modal-close:hover, .delete:hover, .modal-close:focus, .delete:focus {
  background-color: rgba(10, 10, 10, .3);
}

.modal-close:active, .delete:active {
  background-color: rgba(10, 10, 10, .4);
}

.is-small.modal-close, .is-small.delete {
  width: 16px;
  min-width: 16px;
  max-width: 16px;
  height: 16px;
  min-height: 16px;
  max-height: 16px;
}

.is-medium.modal-close, .is-medium.delete {
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
}

.is-large.modal-close, .is-large.delete {
  width: 32px;
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  min-height: 32px;
  max-height: 32px;
}

.control.is-loading:after, .select.is-loading:after, .loader, .button.is-loading:after {
  content: "";
  border: 2px solid rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #dbdbdb #dbdbdb;
  border-radius: 9999px;
  width: 1em;
  height: 1em;
  -webkit-animation: .5s linear infinite spinAround;
  animation: .5s linear infinite spinAround;
  display: block;
  position: relative;
}

.hero-video, .is-overlay, .modal-background, .modal, .image.is-square img, .image.is-square .has-ratio, .image.is-1by1 img, .image.is-1by1 .has-ratio, .image.is-5by4 img, .image.is-5by4 .has-ratio, .image.is-4by3 img, .image.is-4by3 .has-ratio, .image.is-3by2 img, .image.is-3by2 .has-ratio, .image.is-5by3 img, .image.is-5by3 .has-ratio, .image.is-16by9 img, .image.is-16by9 .has-ratio, .image.is-2by1 img, .image.is-2by1 .has-ratio, .image.is-3by1 img, .image.is-3by1 .has-ratio, .image.is-4by5 img, .image.is-4by5 .has-ratio, .image.is-3by4 img, .image.is-3by4 .has-ratio, .image.is-2by3 img, .image.is-2by3 .has-ratio, .image.is-3by5 img, .image.is-3by5 .has-ratio, .image.is-9by16 img, .image.is-9by16 .has-ratio, .image.is-1by2 img, .image.is-1by2 .has-ratio, .image.is-1by3 img, .image.is-1by3 .has-ratio {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.navbar-burger {
  -webkit-appearance: none;
  appearance: none;
  color: currentColor;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 1em;
}

html, body, p, ol, ul, li, dl, dt, dd, blockquote, figure, fieldset, legend, textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

ul {
  list-style: none;
}

button, input, select, textarea {
  margin: 0;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

img, video {
  max-width: 100%;
  height: auto;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

td:not([align]), th:not([align]) {
  text-align: inherit;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  background-color: #fff;
  min-width: 300px;
  font-size: 16px;
  overflow-x: hidden;
  overflow-y: scroll;
}

article, aside, figure, footer, header, hgroup, section {
  display: block;
}

body, button, input, optgroup, select, textarea {
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
}

code, pre {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  font-family: monospace;
}

body {
  color: #4a4a4a;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
}

a {
  color: #485fc7;
  cursor: pointer;
  text-decoration: none;
}

a strong {
  color: currentColor;
}

a:hover {
  color: #363636;
}

code {
  color: #da1039;
  background-color: #f5f5f5;
  padding: .25em .5em;
  font-size: .875em;
  font-weight: normal;
}

hr {
  background-color: #f5f5f5;
  border: none;
  height: 2px;
  margin: 1.5rem 0;
  display: block;
}

img {
  max-width: 100%;
  height: auto;
}

input[type="checkbox"] {
  vertical-align: baseline;
}

input[type="radio"] {
  vertical-align: baseline;
}

small {
  font-size: .875em;
}

span {
  font-style: inherit;
  font-weight: inherit;
}

strong {
  color: #363636;
  font-weight: 700;
}

fieldset {
  border: none;
}

pre {
  -webkit-overflow-scrolling: touch;
  color: #4a4a4a;
  white-space: pre;
  word-wrap: normal;
  background-color: #f5f5f5;
  padding: 1.25rem 1.5rem;
  font-size: .875em;
  overflow-x: auto;
}

pre code {
  color: currentColor;
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
  font-size: 1em;
}

table td, table th {
  vertical-align: top;
}

table td:not([align]), table th:not([align]) {
  text-align: inherit;
}

table th {
  color: #363636;
}

@-webkit-keyframes spinAround {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes spinAround {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.box {
  color: #4a4a4a;
  background-color: #fff;
  border-radius: 6px;
  padding: 1.25rem;
  display: block;
  box-shadow: 0 .5em 1em -.125em rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .02);
}

a.box:hover, a.box:focus {
  box-shadow: 0 .5em 1em -.125em rgba(10, 10, 10, .1), 0 0 0 1px #485fc7;
}

a.box:active {
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, .2), 0 0 0 1px #485fc7;
}

.button {
  color: #363636;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border-width: 1px;
  border-color: #dbdbdb;
  justify-content: center;
  padding: calc(.5em - 1px) 1em;
}

.button strong {
  color: inherit;
}

.button .icon, .button .icon.is-small, .button .icon.is-medium, .button .icon.is-large {
  width: 1.5em;
  height: 1.5em;
}

.button .icon:first-child:not(:last-child) {
  margin-left: calc(-.5em - 1px);
  margin-right: .25em;
}

.button .icon:last-child:not(:first-child) {
  margin-left: .25em;
  margin-right: calc(-.5em - 1px);
}

.button .icon:first-child:last-child {
  margin-left: calc(-.5em - 1px);
  margin-right: calc(-.5em - 1px);
}

.button:hover, .button.is-hovered {
  color: #363636;
  border-color: #b5b5b5;
}

.button:focus, .button.is-focused {
  color: #363636;
  border-color: #485fc7;
}

.button:focus:not(:active), .button.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em rgba(72, 95, 199, .25);
}

.button:active, .button.is-active {
  color: #363636;
  border-color: #4a4a4a;
}

.button.is-text {
  color: #4a4a4a;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
  text-decoration: underline;
}

.button.is-text:hover, .button.is-text.is-hovered, .button.is-text:focus, .button.is-text.is-focused {
  color: #363636;
  background-color: #f5f5f5;
}

.button.is-text:active, .button.is-text.is-active {
  color: #363636;
  background-color: #e8e8e8;
}

.button.is-text[disabled], fieldset[disabled] .button.is-text {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}

.button.is-ghost {
  color: #485fc7;
  background: none;
  border-color: rgba(0, 0, 0, 0);
  text-decoration: none;
}

.button.is-ghost:hover, .button.is-ghost.is-hovered {
  color: #485fc7;
  text-decoration: underline;
}

.button.is-white {
  color: #0a0a0a;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-white:hover, .button.is-white.is-hovered {
  color: #0a0a0a;
  background-color: #f9f9f9;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-white:focus, .button.is-white.is-focused {
  color: #0a0a0a;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-white:focus:not(:active), .button.is-white.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em rgba(255, 255, 255, .25);
}

.button.is-white:active, .button.is-white.is-active {
  color: #0a0a0a;
  background-color: #f2f2f2;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-white[disabled], fieldset[disabled] .button.is-white {
  box-shadow: none;
  background-color: #fff;
  border-color: #fff;
}

.button.is-white.is-inverted {
  color: #fff;
  background-color: #0a0a0a;
}

.button.is-white.is-inverted:hover, .button.is-white.is-inverted.is-hovered {
  background-color: #000;
}

.button.is-white.is-inverted[disabled], fieldset[disabled] .button.is-white.is-inverted {
  box-shadow: none;
  color: #fff;
  background-color: #0a0a0a;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-white.is-loading:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #0a0a0a #0a0a0a !important;
}

.button.is-white.is-outlined {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #fff;
}

.button.is-white.is-outlined:hover, .button.is-white.is-outlined.is-hovered, .button.is-white.is-outlined:focus, .button.is-white.is-outlined.is-focused {
  color: #0a0a0a;
  background-color: #fff;
  border-color: #fff;
}

.button.is-white.is-outlined.is-loading:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fff #fff !important;
}

.button.is-white.is-outlined.is-loading:hover:after, .button.is-white.is-outlined.is-loading.is-hovered:after, .button.is-white.is-outlined.is-loading:focus:after, .button.is-white.is-outlined.is-loading.is-focused:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #0a0a0a #0a0a0a !important;
}

.button.is-white.is-outlined[disabled], fieldset[disabled] .button.is-white.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #fff;
}

.button.is-white.is-inverted.is-outlined {
  color: #0a0a0a;
  background-color: rgba(0, 0, 0, 0);
  border-color: #0a0a0a;
}

.button.is-white.is-inverted.is-outlined:hover, .button.is-white.is-inverted.is-outlined.is-hovered, .button.is-white.is-inverted.is-outlined:focus, .button.is-white.is-inverted.is-outlined.is-focused {
  color: #fff;
  background-color: #0a0a0a;
}

.button.is-white.is-inverted.is-outlined.is-loading:hover:after, .button.is-white.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-white.is-inverted.is-outlined.is-loading:focus:after, .button.is-white.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fff #fff !important;
}

.button.is-white.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-white.is-inverted.is-outlined {
  box-shadow: none;
  color: #0a0a0a;
  background-color: rgba(0, 0, 0, 0);
  border-color: #0a0a0a;
}

.button.is-black {
  color: #fff;
  background-color: #0a0a0a;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-black:hover, .button.is-black.is-hovered {
  color: #fff;
  background-color: #040404;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-black:focus, .button.is-black.is-focused {
  color: #fff;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-black:focus:not(:active), .button.is-black.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em rgba(10, 10, 10, .25);
}

.button.is-black:active, .button.is-black.is-active {
  color: #fff;
  background-color: #000;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-black[disabled], fieldset[disabled] .button.is-black {
  box-shadow: none;
  background-color: #0a0a0a;
  border-color: #0a0a0a;
}

.button.is-black.is-inverted {
  color: #0a0a0a;
  background-color: #fff;
}

.button.is-black.is-inverted:hover, .button.is-black.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-black.is-inverted[disabled], fieldset[disabled] .button.is-black.is-inverted {
  box-shadow: none;
  color: #0a0a0a;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-black.is-loading:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fff #fff !important;
}

.button.is-black.is-outlined {
  color: #0a0a0a;
  background-color: rgba(0, 0, 0, 0);
  border-color: #0a0a0a;
}

.button.is-black.is-outlined:hover, .button.is-black.is-outlined.is-hovered, .button.is-black.is-outlined:focus, .button.is-black.is-outlined.is-focused {
  color: #fff;
  background-color: #0a0a0a;
  border-color: #0a0a0a;
}

.button.is-black.is-outlined.is-loading:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #0a0a0a #0a0a0a !important;
}

.button.is-black.is-outlined.is-loading:hover:after, .button.is-black.is-outlined.is-loading.is-hovered:after, .button.is-black.is-outlined.is-loading:focus:after, .button.is-black.is-outlined.is-loading.is-focused:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fff #fff !important;
}

.button.is-black.is-outlined[disabled], fieldset[disabled] .button.is-black.is-outlined {
  box-shadow: none;
  color: #0a0a0a;
  background-color: rgba(0, 0, 0, 0);
  border-color: #0a0a0a;
}

.button.is-black.is-inverted.is-outlined {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #fff;
}

.button.is-black.is-inverted.is-outlined:hover, .button.is-black.is-inverted.is-outlined.is-hovered, .button.is-black.is-inverted.is-outlined:focus, .button.is-black.is-inverted.is-outlined.is-focused {
  color: #0a0a0a;
  background-color: #fff;
}

.button.is-black.is-inverted.is-outlined.is-loading:hover:after, .button.is-black.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-black.is-inverted.is-outlined.is-loading:focus:after, .button.is-black.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #0a0a0a #0a0a0a !important;
}

.button.is-black.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-black.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #fff;
}

.button.is-light {
  color: rgba(0, 0, 0, .7);
  background-color: #f5f5f5;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-light:hover, .button.is-light.is-hovered {
  color: rgba(0, 0, 0, .7);
  background-color: #eee;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-light:focus, .button.is-light.is-focused {
  color: rgba(0, 0, 0, .7);
  border-color: rgba(0, 0, 0, 0);
}

.button.is-light:focus:not(:active), .button.is-light.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em rgba(245, 245, 245, .25);
}

.button.is-light:active, .button.is-light.is-active {
  color: rgba(0, 0, 0, .7);
  background-color: #e8e8e8;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-light[disabled], fieldset[disabled] .button.is-light {
  box-shadow: none;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.button.is-light.is-inverted {
  color: #f5f5f5;
  background-color: rgba(0, 0, 0, .7);
}

.button.is-light.is-inverted:hover, .button.is-light.is-inverted.is-hovered {
  background-color: rgba(0, 0, 0, .7);
}

.button.is-light.is-inverted[disabled], fieldset[disabled] .button.is-light.is-inverted {
  box-shadow: none;
  color: #f5f5f5;
  background-color: rgba(0, 0, 0, .7);
  border-color: rgba(0, 0, 0, 0);
}

.button.is-light.is-loading:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, .7) rgba(0, 0, 0, .7) !important;
}

.button.is-light.is-outlined {
  color: #f5f5f5;
  background-color: rgba(0, 0, 0, 0);
  border-color: #f5f5f5;
}

.button.is-light.is-outlined:hover, .button.is-light.is-outlined.is-hovered, .button.is-light.is-outlined:focus, .button.is-light.is-outlined.is-focused {
  color: rgba(0, 0, 0, .7);
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.button.is-light.is-outlined.is-loading:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #f5f5f5 #f5f5f5 !important;
}

.button.is-light.is-outlined.is-loading:hover:after, .button.is-light.is-outlined.is-loading.is-hovered:after, .button.is-light.is-outlined.is-loading:focus:after, .button.is-light.is-outlined.is-loading.is-focused:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, .7) rgba(0, 0, 0, .7) !important;
}

.button.is-light.is-outlined[disabled], fieldset[disabled] .button.is-light.is-outlined {
  box-shadow: none;
  color: #f5f5f5;
  background-color: rgba(0, 0, 0, 0);
  border-color: #f5f5f5;
}

.button.is-light.is-inverted.is-outlined {
  color: rgba(0, 0, 0, .7);
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, .7);
}

.button.is-light.is-inverted.is-outlined:hover, .button.is-light.is-inverted.is-outlined.is-hovered, .button.is-light.is-inverted.is-outlined:focus, .button.is-light.is-inverted.is-outlined.is-focused {
  color: #f5f5f5;
  background-color: rgba(0, 0, 0, .7);
}

.button.is-light.is-inverted.is-outlined.is-loading:hover:after, .button.is-light.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-light.is-inverted.is-outlined.is-loading:focus:after, .button.is-light.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #f5f5f5 #f5f5f5 !important;
}

.button.is-light.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-light.is-inverted.is-outlined {
  box-shadow: none;
  color: rgba(0, 0, 0, .7);
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, .7);
}

.button.is-dark {
  color: #fff;
  background-color: #363636;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-dark:hover, .button.is-dark.is-hovered {
  color: #fff;
  background-color: #2f2f2f;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-dark:focus, .button.is-dark.is-focused {
  color: #fff;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-dark:focus:not(:active), .button.is-dark.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em rgba(54, 54, 54, .25);
}

.button.is-dark:active, .button.is-dark.is-active {
  color: #fff;
  background-color: #292929;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-dark[disabled], fieldset[disabled] .button.is-dark {
  box-shadow: none;
  background-color: #363636;
  border-color: #363636;
}

.button.is-dark.is-inverted {
  color: #363636;
  background-color: #fff;
}

.button.is-dark.is-inverted:hover, .button.is-dark.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-dark.is-inverted[disabled], fieldset[disabled] .button.is-dark.is-inverted {
  box-shadow: none;
  color: #363636;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-dark.is-loading:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fff #fff !important;
}

.button.is-dark.is-outlined {
  color: #363636;
  background-color: rgba(0, 0, 0, 0);
  border-color: #363636;
}

.button.is-dark.is-outlined:hover, .button.is-dark.is-outlined.is-hovered, .button.is-dark.is-outlined:focus, .button.is-dark.is-outlined.is-focused {
  color: #fff;
  background-color: #363636;
  border-color: #363636;
}

.button.is-dark.is-outlined.is-loading:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #363636 #363636 !important;
}

.button.is-dark.is-outlined.is-loading:hover:after, .button.is-dark.is-outlined.is-loading.is-hovered:after, .button.is-dark.is-outlined.is-loading:focus:after, .button.is-dark.is-outlined.is-loading.is-focused:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fff #fff !important;
}

.button.is-dark.is-outlined[disabled], fieldset[disabled] .button.is-dark.is-outlined {
  box-shadow: none;
  color: #363636;
  background-color: rgba(0, 0, 0, 0);
  border-color: #363636;
}

.button.is-dark.is-inverted.is-outlined {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #fff;
}

.button.is-dark.is-inverted.is-outlined:hover, .button.is-dark.is-inverted.is-outlined.is-hovered, .button.is-dark.is-inverted.is-outlined:focus, .button.is-dark.is-inverted.is-outlined.is-focused {
  color: #363636;
  background-color: #fff;
}

.button.is-dark.is-inverted.is-outlined.is-loading:hover:after, .button.is-dark.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-dark.is-inverted.is-outlined.is-loading:focus:after, .button.is-dark.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #363636 #363636 !important;
}

.button.is-dark.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-dark.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #fff;
}

.button.is-primary {
  color: #fff;
  background-color: #00d1b2;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-primary:hover, .button.is-primary.is-hovered {
  color: #fff;
  background-color: #00c4a7;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-primary:focus, .button.is-primary.is-focused {
  color: #fff;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-primary:focus:not(:active), .button.is-primary.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em rgba(0, 209, 178, .25);
}

.button.is-primary:active, .button.is-primary.is-active {
  color: #fff;
  background-color: #00b89c;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-primary[disabled], fieldset[disabled] .button.is-primary {
  box-shadow: none;
  background-color: #00d1b2;
  border-color: #00d1b2;
}

.button.is-primary.is-inverted {
  color: #00d1b2;
  background-color: #fff;
}

.button.is-primary.is-inverted:hover, .button.is-primary.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-primary.is-inverted[disabled], fieldset[disabled] .button.is-primary.is-inverted {
  box-shadow: none;
  color: #00d1b2;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-primary.is-loading:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fff #fff !important;
}

.button.is-primary.is-outlined {
  color: #00d1b2;
  background-color: rgba(0, 0, 0, 0);
  border-color: #00d1b2;
}

.button.is-primary.is-outlined:hover, .button.is-primary.is-outlined.is-hovered, .button.is-primary.is-outlined:focus, .button.is-primary.is-outlined.is-focused {
  color: #fff;
  background-color: #00d1b2;
  border-color: #00d1b2;
}

.button.is-primary.is-outlined.is-loading:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #00d1b2 #00d1b2 !important;
}

.button.is-primary.is-outlined.is-loading:hover:after, .button.is-primary.is-outlined.is-loading.is-hovered:after, .button.is-primary.is-outlined.is-loading:focus:after, .button.is-primary.is-outlined.is-loading.is-focused:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fff #fff !important;
}

.button.is-primary.is-outlined[disabled], fieldset[disabled] .button.is-primary.is-outlined {
  box-shadow: none;
  color: #00d1b2;
  background-color: rgba(0, 0, 0, 0);
  border-color: #00d1b2;
}

.button.is-primary.is-inverted.is-outlined {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #fff;
}

.button.is-primary.is-inverted.is-outlined:hover, .button.is-primary.is-inverted.is-outlined.is-hovered, .button.is-primary.is-inverted.is-outlined:focus, .button.is-primary.is-inverted.is-outlined.is-focused {
  color: #00d1b2;
  background-color: #fff;
}

.button.is-primary.is-inverted.is-outlined.is-loading:hover:after, .button.is-primary.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-primary.is-inverted.is-outlined.is-loading:focus:after, .button.is-primary.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #00d1b2 #00d1b2 !important;
}

.button.is-primary.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-primary.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #fff;
}

.button.is-primary.is-light {
  color: #00947e;
  background-color: #ebfffc;
}

.button.is-primary.is-light:hover, .button.is-primary.is-light.is-hovered {
  color: #00947e;
  background-color: #defffa;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-primary.is-light:active, .button.is-primary.is-light.is-active {
  color: #00947e;
  background-color: #d1fff8;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-link {
  color: #fff;
  background-color: #485fc7;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-link:hover, .button.is-link.is-hovered {
  color: #fff;
  background-color: #3e56c4;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-link:focus, .button.is-link.is-focused {
  color: #fff;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-link:focus:not(:active), .button.is-link.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em rgba(72, 95, 199, .25);
}

.button.is-link:active, .button.is-link.is-active {
  color: #fff;
  background-color: #3a51bb;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-link[disabled], fieldset[disabled] .button.is-link {
  box-shadow: none;
  background-color: #485fc7;
  border-color: #485fc7;
}

.button.is-link.is-inverted {
  color: #485fc7;
  background-color: #fff;
}

.button.is-link.is-inverted:hover, .button.is-link.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-link.is-inverted[disabled], fieldset[disabled] .button.is-link.is-inverted {
  box-shadow: none;
  color: #485fc7;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-link.is-loading:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fff #fff !important;
}

.button.is-link.is-outlined {
  color: #485fc7;
  background-color: rgba(0, 0, 0, 0);
  border-color: #485fc7;
}

.button.is-link.is-outlined:hover, .button.is-link.is-outlined.is-hovered, .button.is-link.is-outlined:focus, .button.is-link.is-outlined.is-focused {
  color: #fff;
  background-color: #485fc7;
  border-color: #485fc7;
}

.button.is-link.is-outlined.is-loading:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #485fc7 #485fc7 !important;
}

.button.is-link.is-outlined.is-loading:hover:after, .button.is-link.is-outlined.is-loading.is-hovered:after, .button.is-link.is-outlined.is-loading:focus:after, .button.is-link.is-outlined.is-loading.is-focused:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fff #fff !important;
}

.button.is-link.is-outlined[disabled], fieldset[disabled] .button.is-link.is-outlined {
  box-shadow: none;
  color: #485fc7;
  background-color: rgba(0, 0, 0, 0);
  border-color: #485fc7;
}

.button.is-link.is-inverted.is-outlined {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #fff;
}

.button.is-link.is-inverted.is-outlined:hover, .button.is-link.is-inverted.is-outlined.is-hovered, .button.is-link.is-inverted.is-outlined:focus, .button.is-link.is-inverted.is-outlined.is-focused {
  color: #485fc7;
  background-color: #fff;
}

.button.is-link.is-inverted.is-outlined.is-loading:hover:after, .button.is-link.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-link.is-inverted.is-outlined.is-loading:focus:after, .button.is-link.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #485fc7 #485fc7 !important;
}

.button.is-link.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-link.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #fff;
}

.button.is-link.is-light {
  color: #3850b7;
  background-color: #eff1fa;
}

.button.is-link.is-light:hover, .button.is-link.is-light.is-hovered {
  color: #3850b7;
  background-color: #e6e9f7;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-link.is-light:active, .button.is-link.is-light.is-active {
  color: #3850b7;
  background-color: #dce0f4;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-info {
  color: #fff;
  background-color: #3e8ed0;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-info:hover, .button.is-info.is-hovered {
  color: #fff;
  background-color: #3488ce;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-info:focus, .button.is-info.is-focused {
  color: #fff;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-info:focus:not(:active), .button.is-info.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em rgba(62, 142, 208, .25);
}

.button.is-info:active, .button.is-info.is-active {
  color: #fff;
  background-color: #3082c5;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-info[disabled], fieldset[disabled] .button.is-info {
  box-shadow: none;
  background-color: #3e8ed0;
  border-color: #3e8ed0;
}

.button.is-info.is-inverted {
  color: #3e8ed0;
  background-color: #fff;
}

.button.is-info.is-inverted:hover, .button.is-info.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-info.is-inverted[disabled], fieldset[disabled] .button.is-info.is-inverted {
  box-shadow: none;
  color: #3e8ed0;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-info.is-loading:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fff #fff !important;
}

.button.is-info.is-outlined {
  color: #3e8ed0;
  background-color: rgba(0, 0, 0, 0);
  border-color: #3e8ed0;
}

.button.is-info.is-outlined:hover, .button.is-info.is-outlined.is-hovered, .button.is-info.is-outlined:focus, .button.is-info.is-outlined.is-focused {
  color: #fff;
  background-color: #3e8ed0;
  border-color: #3e8ed0;
}

.button.is-info.is-outlined.is-loading:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #3e8ed0 #3e8ed0 !important;
}

.button.is-info.is-outlined.is-loading:hover:after, .button.is-info.is-outlined.is-loading.is-hovered:after, .button.is-info.is-outlined.is-loading:focus:after, .button.is-info.is-outlined.is-loading.is-focused:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fff #fff !important;
}

.button.is-info.is-outlined[disabled], fieldset[disabled] .button.is-info.is-outlined {
  box-shadow: none;
  color: #3e8ed0;
  background-color: rgba(0, 0, 0, 0);
  border-color: #3e8ed0;
}

.button.is-info.is-inverted.is-outlined {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #fff;
}

.button.is-info.is-inverted.is-outlined:hover, .button.is-info.is-inverted.is-outlined.is-hovered, .button.is-info.is-inverted.is-outlined:focus, .button.is-info.is-inverted.is-outlined.is-focused {
  color: #3e8ed0;
  background-color: #fff;
}

.button.is-info.is-inverted.is-outlined.is-loading:hover:after, .button.is-info.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-info.is-inverted.is-outlined.is-loading:focus:after, .button.is-info.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #3e8ed0 #3e8ed0 !important;
}

.button.is-info.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-info.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #fff;
}

.button.is-info.is-light {
  color: #296fa8;
  background-color: #eff5fb;
}

.button.is-info.is-light:hover, .button.is-info.is-light.is-hovered {
  color: #296fa8;
  background-color: #e4eff9;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-info.is-light:active, .button.is-info.is-light.is-active {
  color: #296fa8;
  background-color: #dae9f6;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-success {
  color: #fff;
  background-color: #48c78e;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-success:hover, .button.is-success.is-hovered {
  color: #fff;
  background-color: #3ec487;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-success:focus, .button.is-success.is-focused {
  color: #fff;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-success:focus:not(:active), .button.is-success.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em rgba(72, 199, 142, .25);
}

.button.is-success:active, .button.is-success.is-active {
  color: #fff;
  background-color: #3abb81;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-success[disabled], fieldset[disabled] .button.is-success {
  box-shadow: none;
  background-color: #48c78e;
  border-color: #48c78e;
}

.button.is-success.is-inverted {
  color: #48c78e;
  background-color: #fff;
}

.button.is-success.is-inverted:hover, .button.is-success.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-success.is-inverted[disabled], fieldset[disabled] .button.is-success.is-inverted {
  box-shadow: none;
  color: #48c78e;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-success.is-loading:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fff #fff !important;
}

.button.is-success.is-outlined {
  color: #48c78e;
  background-color: rgba(0, 0, 0, 0);
  border-color: #48c78e;
}

.button.is-success.is-outlined:hover, .button.is-success.is-outlined.is-hovered, .button.is-success.is-outlined:focus, .button.is-success.is-outlined.is-focused {
  color: #fff;
  background-color: #48c78e;
  border-color: #48c78e;
}

.button.is-success.is-outlined.is-loading:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #48c78e #48c78e !important;
}

.button.is-success.is-outlined.is-loading:hover:after, .button.is-success.is-outlined.is-loading.is-hovered:after, .button.is-success.is-outlined.is-loading:focus:after, .button.is-success.is-outlined.is-loading.is-focused:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fff #fff !important;
}

.button.is-success.is-outlined[disabled], fieldset[disabled] .button.is-success.is-outlined {
  box-shadow: none;
  color: #48c78e;
  background-color: rgba(0, 0, 0, 0);
  border-color: #48c78e;
}

.button.is-success.is-inverted.is-outlined {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #fff;
}

.button.is-success.is-inverted.is-outlined:hover, .button.is-success.is-inverted.is-outlined.is-hovered, .button.is-success.is-inverted.is-outlined:focus, .button.is-success.is-inverted.is-outlined.is-focused {
  color: #48c78e;
  background-color: #fff;
}

.button.is-success.is-inverted.is-outlined.is-loading:hover:after, .button.is-success.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-success.is-inverted.is-outlined.is-loading:focus:after, .button.is-success.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #48c78e #48c78e !important;
}

.button.is-success.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-success.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #fff;
}

.button.is-success.is-light {
  color: #257953;
  background-color: #effaf5;
}

.button.is-success.is-light:hover, .button.is-success.is-light.is-hovered {
  color: #257953;
  background-color: #e6f7ef;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-success.is-light:active, .button.is-success.is-light.is-active {
  color: #257953;
  background-color: #dcf4e9;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-warning {
  color: rgba(0, 0, 0, .7);
  background-color: #ffe08a;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-warning:hover, .button.is-warning.is-hovered {
  color: rgba(0, 0, 0, .7);
  background-color: #ffdc7d;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-warning:focus, .button.is-warning.is-focused {
  color: rgba(0, 0, 0, .7);
  border-color: rgba(0, 0, 0, 0);
}

.button.is-warning:focus:not(:active), .button.is-warning.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em rgba(255, 224, 138, .25);
}

.button.is-warning:active, .button.is-warning.is-active {
  color: rgba(0, 0, 0, .7);
  background-color: #ffd970;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-warning[disabled], fieldset[disabled] .button.is-warning {
  box-shadow: none;
  background-color: #ffe08a;
  border-color: #ffe08a;
}

.button.is-warning.is-inverted {
  color: #ffe08a;
  background-color: rgba(0, 0, 0, .7);
}

.button.is-warning.is-inverted:hover, .button.is-warning.is-inverted.is-hovered {
  background-color: rgba(0, 0, 0, .7);
}

.button.is-warning.is-inverted[disabled], fieldset[disabled] .button.is-warning.is-inverted {
  box-shadow: none;
  color: #ffe08a;
  background-color: rgba(0, 0, 0, .7);
  border-color: rgba(0, 0, 0, 0);
}

.button.is-warning.is-loading:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, .7) rgba(0, 0, 0, .7) !important;
}

.button.is-warning.is-outlined {
  color: #ffe08a;
  background-color: rgba(0, 0, 0, 0);
  border-color: #ffe08a;
}

.button.is-warning.is-outlined:hover, .button.is-warning.is-outlined.is-hovered, .button.is-warning.is-outlined:focus, .button.is-warning.is-outlined.is-focused {
  color: rgba(0, 0, 0, .7);
  background-color: #ffe08a;
  border-color: #ffe08a;
}

.button.is-warning.is-outlined.is-loading:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #ffe08a #ffe08a !important;
}

.button.is-warning.is-outlined.is-loading:hover:after, .button.is-warning.is-outlined.is-loading.is-hovered:after, .button.is-warning.is-outlined.is-loading:focus:after, .button.is-warning.is-outlined.is-loading.is-focused:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, .7) rgba(0, 0, 0, .7) !important;
}

.button.is-warning.is-outlined[disabled], fieldset[disabled] .button.is-warning.is-outlined {
  box-shadow: none;
  color: #ffe08a;
  background-color: rgba(0, 0, 0, 0);
  border-color: #ffe08a;
}

.button.is-warning.is-inverted.is-outlined {
  color: rgba(0, 0, 0, .7);
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, .7);
}

.button.is-warning.is-inverted.is-outlined:hover, .button.is-warning.is-inverted.is-outlined.is-hovered, .button.is-warning.is-inverted.is-outlined:focus, .button.is-warning.is-inverted.is-outlined.is-focused {
  color: #ffe08a;
  background-color: rgba(0, 0, 0, .7);
}

.button.is-warning.is-inverted.is-outlined.is-loading:hover:after, .button.is-warning.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-warning.is-inverted.is-outlined.is-loading:focus:after, .button.is-warning.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #ffe08a #ffe08a !important;
}

.button.is-warning.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-warning.is-inverted.is-outlined {
  box-shadow: none;
  color: rgba(0, 0, 0, .7);
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, .7);
}

.button.is-warning.is-light {
  color: #946c00;
  background-color: #fffaeb;
}

.button.is-warning.is-light:hover, .button.is-warning.is-light.is-hovered {
  color: #946c00;
  background-color: #fff6de;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-warning.is-light:active, .button.is-warning.is-light.is-active {
  color: #946c00;
  background-color: #fff3d1;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-danger {
  color: #fff;
  background-color: #f14668;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-danger:hover, .button.is-danger.is-hovered {
  color: #fff;
  background-color: #f03a5f;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-danger:focus, .button.is-danger.is-focused {
  color: #fff;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-danger:focus:not(:active), .button.is-danger.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em rgba(241, 70, 104, .25);
}

.button.is-danger:active, .button.is-danger.is-active {
  color: #fff;
  background-color: #ef2e55;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-danger[disabled], fieldset[disabled] .button.is-danger {
  box-shadow: none;
  background-color: #f14668;
  border-color: #f14668;
}

.button.is-danger.is-inverted {
  color: #f14668;
  background-color: #fff;
}

.button.is-danger.is-inverted:hover, .button.is-danger.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-danger.is-inverted[disabled], fieldset[disabled] .button.is-danger.is-inverted {
  box-shadow: none;
  color: #f14668;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-danger.is-loading:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fff #fff !important;
}

.button.is-danger.is-outlined {
  color: #f14668;
  background-color: rgba(0, 0, 0, 0);
  border-color: #f14668;
}

.button.is-danger.is-outlined:hover, .button.is-danger.is-outlined.is-hovered, .button.is-danger.is-outlined:focus, .button.is-danger.is-outlined.is-focused {
  color: #fff;
  background-color: #f14668;
  border-color: #f14668;
}

.button.is-danger.is-outlined.is-loading:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #f14668 #f14668 !important;
}

.button.is-danger.is-outlined.is-loading:hover:after, .button.is-danger.is-outlined.is-loading.is-hovered:after, .button.is-danger.is-outlined.is-loading:focus:after, .button.is-danger.is-outlined.is-loading.is-focused:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fff #fff !important;
}

.button.is-danger.is-outlined[disabled], fieldset[disabled] .button.is-danger.is-outlined {
  box-shadow: none;
  color: #f14668;
  background-color: rgba(0, 0, 0, 0);
  border-color: #f14668;
}

.button.is-danger.is-inverted.is-outlined {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #fff;
}

.button.is-danger.is-inverted.is-outlined:hover, .button.is-danger.is-inverted.is-outlined.is-hovered, .button.is-danger.is-inverted.is-outlined:focus, .button.is-danger.is-inverted.is-outlined.is-focused {
  color: #f14668;
  background-color: #fff;
}

.button.is-danger.is-inverted.is-outlined.is-loading:hover:after, .button.is-danger.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-danger.is-inverted.is-outlined.is-loading:focus:after, .button.is-danger.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #f14668 #f14668 !important;
}

.button.is-danger.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-danger.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #fff;
}

.button.is-danger.is-light {
  color: #cc0f35;
  background-color: #feecf0;
}

.button.is-danger.is-light:hover, .button.is-danger.is-light.is-hovered {
  color: #cc0f35;
  background-color: #fde0e6;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-danger.is-light:active, .button.is-danger.is-light.is-active {
  color: #cc0f35;
  background-color: #fcd4dc;
  border-color: rgba(0, 0, 0, 0);
}

.button.is-small {
  font-size: .75rem;
}

.button.is-small:not(.is-rounded) {
  border-radius: 2px;
}

.button.is-normal {
  font-size: 1rem;
}

.button.is-medium {
  font-size: 1.25rem;
}

.button.is-large {
  font-size: 1.5rem;
}

.button[disabled], fieldset[disabled] .button {
  box-shadow: none;
  opacity: .5;
  background-color: #fff;
  border-color: #dbdbdb;
}

.button.is-fullwidth {
  width: 100%;
  display: flex;
}

.button.is-loading {
  pointer-events: none;
  color: rgba(0, 0, 0, 0) !important;
}

.button.is-loading:after {
  position: absolute;
  top: calc(50% - .5em);
  left: calc(50% - .5em);
  position: absolute !important;
}

.button.is-static {
  color: #7a7a7a;
  box-shadow: none;
  pointer-events: none;
  background-color: #f5f5f5;
  border-color: #dbdbdb;
}

.button.is-rounded {
  border-radius: 9999px;
  padding-left: 1.25em;
  padding-right: 1.25em;
}

.buttons {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.buttons .button {
  margin-bottom: .5rem;
}

.buttons .button:not(:last-child):not(.is-fullwidth) {
  margin-right: .5rem;
}

.buttons:last-child {
  margin-bottom: -.5rem;
}

.buttons:not(:last-child) {
  margin-bottom: 1rem;
}

.buttons.are-small .button:not(.is-normal):not(.is-medium):not(.is-large) {
  font-size: .75rem;
}

.buttons.are-small .button:not(.is-normal):not(.is-medium):not(.is-large):not(.is-rounded) {
  border-radius: 2px;
}

.buttons.are-medium .button:not(.is-small):not(.is-normal):not(.is-large) {
  font-size: 1.25rem;
}

.buttons.are-large .button:not(.is-small):not(.is-normal):not(.is-medium) {
  font-size: 1.5rem;
}

.buttons.has-addons .button:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.buttons.has-addons .button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: -1px;
}

.buttons.has-addons .button:last-child {
  margin-right: 0;
}

.buttons.has-addons .button:hover, .buttons.has-addons .button.is-hovered {
  z-index: 2;
}

.buttons.has-addons .button:focus, .buttons.has-addons .button.is-focused, .buttons.has-addons .button:active, .buttons.has-addons .button.is-active, .buttons.has-addons .button.is-selected {
  z-index: 3;
}

.buttons.has-addons .button:focus:hover, .buttons.has-addons .button.is-focused:hover, .buttons.has-addons .button:active:hover, .buttons.has-addons .button.is-active:hover, .buttons.has-addons .button.is-selected:hover {
  z-index: 4;
}

.buttons.has-addons .button.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.buttons.is-centered {
  justify-content: center;
}

.buttons.is-centered:not(.has-addons) .button:not(.is-fullwidth) {
  margin-left: .25rem;
  margin-right: .25rem;
}

.buttons.is-right {
  justify-content: flex-end;
}

.buttons.is-right:not(.has-addons) .button:not(.is-fullwidth) {
  margin-left: .25rem;
  margin-right: .25rem;
}

@media screen and (max-width: 768px) {
  .button.is-responsive.is-small {
    font-size: .5625rem;
  }

  .button.is-responsive, .button.is-responsive.is-normal {
    font-size: .65625rem;
  }

  .button.is-responsive.is-medium {
    font-size: .75rem;
  }

  .button.is-responsive.is-large {
    font-size: 1rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .button.is-responsive.is-small {
    font-size: .65625rem;
  }

  .button.is-responsive, .button.is-responsive.is-normal {
    font-size: .75rem;
  }

  .button.is-responsive.is-medium {
    font-size: 1rem;
  }

  .button.is-responsive.is-large {
    font-size: 1.25rem;
  }
}

.container {
  flex-grow: 1;
  width: auto;
  margin: 0 auto;
  position: relative;
}

.container.is-fluid {
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;
  max-width: none !important;
}

@media screen and (min-width: 1024px) {
  .container {
    max-width: 960px;
  }
}

@media screen and (max-width: 1215px) {
  .container.is-widescreen:not(.is-max-desktop) {
    max-width: 1152px;
  }
}

@media screen and (max-width: 1407px) {
  .container.is-fullhd:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1344px;
  }
}

@media screen and (min-width: 1216px) {
  .container:not(.is-max-desktop) {
    max-width: 1152px;
  }
}

@media screen and (min-width: 1408px) {
  .container:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1344px;
  }
}

.content li + li {
  margin-top: .25em;
}

.content p:not(:last-child), .content dl:not(:last-child), .content ol:not(:last-child), .content ul:not(:last-child), .content blockquote:not(:last-child), .content pre:not(:last-child), .content table:not(:last-child) {
  margin-bottom: 1em;
}

.content h1, .content h2, .content h3, .content h4, .content h5, .content h6 {
  color: #363636;
  font-weight: 600;
  line-height: 1.125;
}

.content h1 {
  margin-bottom: .5em;
  font-size: 2em;
}

.content h1:not(:first-child) {
  margin-top: 1em;
}

.content h2 {
  margin-bottom: .5714em;
  font-size: 1.75em;
}

.content h2:not(:first-child) {
  margin-top: 1.1428em;
}

.content h3 {
  margin-bottom: .6666em;
  font-size: 1.5em;
}

.content h3:not(:first-child) {
  margin-top: 1.3333em;
}

.content h4 {
  margin-bottom: .8em;
  font-size: 1.25em;
}

.content h5 {
  margin-bottom: .8888em;
  font-size: 1.125em;
}

.content h6 {
  margin-bottom: 1em;
  font-size: 1em;
}

.content blockquote {
  background-color: #f5f5f5;
  border-left: 5px solid #dbdbdb;
  padding: 1.25em 1.5em;
}

.content ol {
  margin-top: 1em;
  margin-left: 2em;
  list-style-position: outside;
}

.content ol:not([type]) {
  list-style-type: decimal;
}

.content ol:not([type]).is-lower-alpha {
  list-style-type: lower-alpha;
}

.content ol:not([type]).is-lower-roman {
  list-style-type: lower-roman;
}

.content ol:not([type]).is-upper-alpha {
  list-style-type: upper-alpha;
}

.content ol:not([type]).is-upper-roman {
  list-style-type: upper-roman;
}

.content ul {
  margin-top: 1em;
  margin-left: 2em;
  list-style: disc;
}

.content ul ul {
  margin-top: .5em;
  list-style-type: circle;
}

.content ul ul ul {
  list-style-type: square;
}

.content dd {
  margin-left: 2em;
}

.content figure {
  text-align: center;
  margin-left: 2em;
  margin-right: 2em;
}

.content figure:not(:first-child) {
  margin-top: 2em;
}

.content figure:not(:last-child) {
  margin-bottom: 2em;
}

.content figure img {
  display: inline-block;
}

.content figure figcaption {
  font-style: italic;
}

.content pre {
  -webkit-overflow-scrolling: touch;
  white-space: pre;
  word-wrap: normal;
  padding: 1.25em 1.5em;
  overflow-x: auto;
}

.content sup, .content sub {
  font-size: 75%;
}

.content table {
  width: 100%;
}

.content table td, .content table th {
  vertical-align: top;
  border: 0 solid #dbdbdb;
  border-bottom-width: 1px;
  padding: .5em .75em;
}

.content table th {
  color: #363636;
}

.content table th:not([align]) {
  text-align: inherit;
}

.content table thead td, .content table thead th {
  color: #363636;
  border-width: 0 0 2px;
}

.content table tfoot td, .content table tfoot th {
  color: #363636;
  border-width: 2px 0 0;
}

.content table tbody tr:last-child td, .content table tbody tr:last-child th {
  border-bottom-width: 0;
}

.content .tabs li + li {
  margin-top: 0;
}

.content.is-small {
  font-size: .75rem;
}

.content.is-normal {
  font-size: 1rem;
}

.content.is-medium {
  font-size: 1.25rem;
}

.content.is-large {
  font-size: 1.5rem;
}

.icon {
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  display: inline-flex;
}

.icon.is-small {
  width: 1rem;
  height: 1rem;
}

.icon.is-medium {
  width: 2rem;
  height: 2rem;
}

.icon.is-large {
  width: 3rem;
  height: 3rem;
}

.icon-text {
  color: inherit;
  vertical-align: top;
  flex-wrap: wrap;
  align-items: flex-start;
  line-height: 1.5rem;
  display: inline-flex;
}

.icon-text .icon {
  flex-grow: 0;
  flex-shrink: 0;
}

.icon-text .icon:not(:last-child) {
  margin-right: .25em;
}

.icon-text .icon:not(:first-child) {
  margin-left: .25em;
}

div.icon-text {
  display: flex;
}

.image {
  display: block;
  position: relative;
}

.image img {
  width: 100%;
  height: auto;
  display: block;
}

.image img.is-rounded {
  border-radius: 9999px;
}

.image.is-fullwidth {
  width: 100%;
}

.image.is-square img, .image.is-square .has-ratio, .image.is-1by1 img, .image.is-1by1 .has-ratio, .image.is-5by4 img, .image.is-5by4 .has-ratio, .image.is-4by3 img, .image.is-4by3 .has-ratio, .image.is-3by2 img, .image.is-3by2 .has-ratio, .image.is-5by3 img, .image.is-5by3 .has-ratio, .image.is-16by9 img, .image.is-16by9 .has-ratio, .image.is-2by1 img, .image.is-2by1 .has-ratio, .image.is-3by1 img, .image.is-3by1 .has-ratio, .image.is-4by5 img, .image.is-4by5 .has-ratio, .image.is-3by4 img, .image.is-3by4 .has-ratio, .image.is-2by3 img, .image.is-2by3 .has-ratio, .image.is-3by5 img, .image.is-3by5 .has-ratio, .image.is-9by16 img, .image.is-9by16 .has-ratio, .image.is-1by2 img, .image.is-1by2 .has-ratio, .image.is-1by3 img, .image.is-1by3 .has-ratio {
  width: 100%;
  height: 100%;
}

.image.is-square, .image.is-1by1 {
  padding-top: 100%;
}

.image.is-5by4 {
  padding-top: 80%;
}

.image.is-4by3 {
  padding-top: 75%;
}

.image.is-3by2 {
  padding-top: 66.6666%;
}

.image.is-5by3 {
  padding-top: 60%;
}

.image.is-16by9 {
  padding-top: 56.25%;
}

.image.is-2by1 {
  padding-top: 50%;
}

.image.is-3by1 {
  padding-top: 33.3333%;
}

.image.is-4by5 {
  padding-top: 125%;
}

.image.is-3by4 {
  padding-top: 133.333%;
}

.image.is-2by3 {
  padding-top: 150%;
}

.image.is-3by5 {
  padding-top: 166.667%;
}

.image.is-9by16 {
  padding-top: 177.778%;
}

.image.is-1by2 {
  padding-top: 200%;
}

.image.is-1by3 {
  padding-top: 300%;
}

.image.is-16x16 {
  width: 16px;
  height: 16px;
}

.image.is-24x24 {
  width: 24px;
  height: 24px;
}

.image.is-32x32 {
  width: 32px;
  height: 32px;
}

.image.is-48x48 {
  width: 48px;
  height: 48px;
}

.image.is-64x64 {
  width: 64px;
  height: 64px;
}

.image.is-96x96 {
  width: 96px;
  height: 96px;
}

.image.is-128x128 {
  width: 128px;
  height: 128px;
}

.notification {
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 1.25rem 2.5rem 1.25rem 1.5rem;
  position: relative;
}

.notification a:not(.button):not(.dropdown-item) {
  color: currentColor;
  text-decoration: underline;
}

.notification strong {
  color: currentColor;
}

.notification code, .notification pre {
  background: #fff;
}

.notification pre code {
  background: none;
}

.notification > .delete {
  position: absolute;
  top: .5rem;
  right: .5rem;
}

.notification .title, .notification .subtitle, .notification .content {
  color: currentColor;
}

.notification.is-white {
  color: #0a0a0a;
  background-color: #fff;
}

.notification.is-black {
  color: #fff;
  background-color: #0a0a0a;
}

.notification.is-light {
  color: rgba(0, 0, 0, .7);
  background-color: #f5f5f5;
}

.notification.is-dark {
  color: #fff;
  background-color: #363636;
}

.notification.is-primary {
  color: #fff;
  background-color: #00d1b2;
}

.notification.is-primary.is-light {
  color: #00947e;
  background-color: #ebfffc;
}

.notification.is-link {
  color: #fff;
  background-color: #485fc7;
}

.notification.is-link.is-light {
  color: #3850b7;
  background-color: #eff1fa;
}

.notification.is-info {
  color: #fff;
  background-color: #3e8ed0;
}

.notification.is-info.is-light {
  color: #296fa8;
  background-color: #eff5fb;
}

.notification.is-success {
  color: #fff;
  background-color: #48c78e;
}

.notification.is-success.is-light {
  color: #257953;
  background-color: #effaf5;
}

.notification.is-warning {
  color: rgba(0, 0, 0, .7);
  background-color: #ffe08a;
}

.notification.is-warning.is-light {
  color: #946c00;
  background-color: #fffaeb;
}

.notification.is-danger {
  color: #fff;
  background-color: #f14668;
}

.notification.is-danger.is-light {
  color: #cc0f35;
  background-color: #feecf0;
}

.progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  border-radius: 9999px;
  width: 100%;
  height: 1rem;
  padding: 0;
  display: block;
  overflow: hidden;
}

.progress::-webkit-progress-bar {
  background-color: #ededed;
}

.progress::-webkit-progress-value {
  background-color: #4a4a4a;
}

.progress::-moz-progress-bar {
  background-color: #4a4a4a;
}

.progress::-ms-fill {
  background-color: #4a4a4a;
  border: none;
}

.progress.is-white::-webkit-progress-value {
  background-color: #fff;
}

.progress.is-white::-moz-progress-bar {
  background-color: #fff;
}

.progress.is-white::-ms-fill {
  background-color: #fff;
}

.progress.is-white:indeterminate {
  background-image: linear-gradient(to right, #fff 30%, #ededed 30%);
}

.progress.is-black::-webkit-progress-value {
  background-color: #0a0a0a;
}

.progress.is-black::-moz-progress-bar {
  background-color: #0a0a0a;
}

.progress.is-black::-ms-fill {
  background-color: #0a0a0a;
}

.progress.is-black:indeterminate {
  background-image: linear-gradient(to right, #0a0a0a 30%, #ededed 30%);
}

.progress.is-light::-webkit-progress-value {
  background-color: #f5f5f5;
}

.progress.is-light::-moz-progress-bar {
  background-color: #f5f5f5;
}

.progress.is-light::-ms-fill {
  background-color: #f5f5f5;
}

.progress.is-light:indeterminate {
  background-image: linear-gradient(to right, #f5f5f5 30%, #ededed 30%);
}

.progress.is-dark::-webkit-progress-value {
  background-color: #363636;
}

.progress.is-dark::-moz-progress-bar {
  background-color: #363636;
}

.progress.is-dark::-ms-fill {
  background-color: #363636;
}

.progress.is-dark:indeterminate {
  background-image: linear-gradient(to right, #363636 30%, #ededed 30%);
}

.progress.is-primary::-webkit-progress-value {
  background-color: #00d1b2;
}

.progress.is-primary::-moz-progress-bar {
  background-color: #00d1b2;
}

.progress.is-primary::-ms-fill {
  background-color: #00d1b2;
}

.progress.is-primary:indeterminate {
  background-image: linear-gradient(to right, #00d1b2 30%, #ededed 30%);
}

.progress.is-link::-webkit-progress-value {
  background-color: #485fc7;
}

.progress.is-link::-moz-progress-bar {
  background-color: #485fc7;
}

.progress.is-link::-ms-fill {
  background-color: #485fc7;
}

.progress.is-link:indeterminate {
  background-image: linear-gradient(to right, #485fc7 30%, #ededed 30%);
}

.progress.is-info::-webkit-progress-value {
  background-color: #3e8ed0;
}

.progress.is-info::-moz-progress-bar {
  background-color: #3e8ed0;
}

.progress.is-info::-ms-fill {
  background-color: #3e8ed0;
}

.progress.is-info:indeterminate {
  background-image: linear-gradient(to right, #3e8ed0 30%, #ededed 30%);
}

.progress.is-success::-webkit-progress-value {
  background-color: #48c78e;
}

.progress.is-success::-moz-progress-bar {
  background-color: #48c78e;
}

.progress.is-success::-ms-fill {
  background-color: #48c78e;
}

.progress.is-success:indeterminate {
  background-image: linear-gradient(to right, #48c78e 30%, #ededed 30%);
}

.progress.is-warning::-webkit-progress-value {
  background-color: #ffe08a;
}

.progress.is-warning::-moz-progress-bar {
  background-color: #ffe08a;
}

.progress.is-warning::-ms-fill {
  background-color: #ffe08a;
}

.progress.is-warning:indeterminate {
  background-image: linear-gradient(to right, #ffe08a 30%, #ededed 30%);
}

.progress.is-danger::-webkit-progress-value {
  background-color: #f14668;
}

.progress.is-danger::-moz-progress-bar {
  background-color: #f14668;
}

.progress.is-danger::-ms-fill {
  background-color: #f14668;
}

.progress.is-danger:indeterminate {
  background-image: linear-gradient(to right, #f14668 30%, #ededed 30%);
}

.progress:indeterminate {
  background-color: #ededed;
  background-image: linear-gradient(to right, #4a4a4a 30%, #ededed 30%);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 150% 150%;
  -webkit-animation-name: moveIndeterminate;
  animation-name: moveIndeterminate;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.progress:indeterminate::-webkit-progress-bar {
  background-color: rgba(0, 0, 0, 0);
}

.progress:indeterminate::-moz-progress-bar {
  background-color: rgba(0, 0, 0, 0);
}

.progress:indeterminate::-ms-fill {
  -webkit-animation-name: none;
  animation-name: none;
}

.progress.is-small {
  height: .75rem;
}

.progress.is-medium {
  height: 1.25rem;
}

.progress.is-large {
  height: 1.5rem;
}

@-webkit-keyframes moveIndeterminate {
  from {
    background-position: 200% 0;
  }

  to {
    background-position: -200% 0;
  }
}

@keyframes moveIndeterminate {
  from {
    background-position: 200% 0;
  }

  to {
    background-position: -200% 0;
  }
}

.table {
  color: #363636;
  background-color: #fff;
}

.table td, .table th {
  vertical-align: top;
  border: 0 solid #dbdbdb;
  border-bottom-width: 1px;
  padding: .5em .75em;
}

.table td.is-white, .table th.is-white {
  color: #0a0a0a;
  background-color: #fff;
  border-color: #fff;
}

.table td.is-black, .table th.is-black {
  color: #fff;
  background-color: #0a0a0a;
  border-color: #0a0a0a;
}

.table td.is-light, .table th.is-light {
  color: rgba(0, 0, 0, .7);
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.table td.is-dark, .table th.is-dark {
  color: #fff;
  background-color: #363636;
  border-color: #363636;
}

.table td.is-primary, .table th.is-primary {
  color: #fff;
  background-color: #00d1b2;
  border-color: #00d1b2;
}

.table td.is-link, .table th.is-link {
  color: #fff;
  background-color: #485fc7;
  border-color: #485fc7;
}

.table td.is-info, .table th.is-info {
  color: #fff;
  background-color: #3e8ed0;
  border-color: #3e8ed0;
}

.table td.is-success, .table th.is-success {
  color: #fff;
  background-color: #48c78e;
  border-color: #48c78e;
}

.table td.is-warning, .table th.is-warning {
  color: rgba(0, 0, 0, .7);
  background-color: #ffe08a;
  border-color: #ffe08a;
}

.table td.is-danger, .table th.is-danger {
  color: #fff;
  background-color: #f14668;
  border-color: #f14668;
}

.table td.is-narrow, .table th.is-narrow {
  white-space: nowrap;
  width: 1%;
}

.table td.is-selected, .table th.is-selected {
  color: #fff;
  background-color: #00d1b2;
}

.table td.is-selected a, .table td.is-selected strong, .table th.is-selected a, .table th.is-selected strong {
  color: currentColor;
}

.table td.is-vcentered, .table th.is-vcentered {
  vertical-align: middle;
}

.table th {
  color: #363636;
}

.table th:not([align]) {
  text-align: left;
}

.table tr.is-selected {
  color: #fff;
  background-color: #00d1b2;
}

.table tr.is-selected a, .table tr.is-selected strong {
  color: currentColor;
}

.table tr.is-selected td, .table tr.is-selected th {
  color: currentColor;
  border-color: #fff;
}

.table thead {
  background-color: rgba(0, 0, 0, 0);
}

.table thead td, .table thead th {
  color: #363636;
  border-width: 0 0 2px;
}

.table tfoot {
  background-color: rgba(0, 0, 0, 0);
}

.table tfoot td, .table tfoot th {
  color: #363636;
  border-width: 2px 0 0;
}

.table tbody {
  background-color: rgba(0, 0, 0, 0);
}

.table tbody tr:last-child td, .table tbody tr:last-child th {
  border-bottom-width: 0;
}

.table.is-bordered td, .table.is-bordered th {
  border-width: 1px;
}

.table.is-bordered tr:last-child td, .table.is-bordered tr:last-child th {
  border-bottom-width: 1px;
}

.table.is-fullwidth {
  width: 100%;
}

.table.is-hoverable tbody tr:not(.is-selected):hover, .table.is-hoverable.is-striped tbody tr:not(.is-selected):hover {
  background-color: #fafafa;
}

.table.is-hoverable.is-striped tbody tr:not(.is-selected):hover:nth-child(2n) {
  background-color: #f5f5f5;
}

.table.is-narrow td, .table.is-narrow th {
  padding: .25em .5em;
}

.table.is-striped tbody tr:not(.is-selected):nth-child(2n) {
  background-color: #fafafa;
}

.table-container {
  -webkit-overflow-scrolling: touch;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.tags {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.tags .tag {
  margin-bottom: .5rem;
}

.tags .tag:not(:last-child) {
  margin-right: .5rem;
}

.tags:last-child {
  margin-bottom: -.5rem;
}

.tags:not(:last-child) {
  margin-bottom: 1rem;
}

.tags.are-medium .tag:not(.is-normal):not(.is-large) {
  font-size: 1rem;
}

.tags.are-large .tag:not(.is-normal):not(.is-medium) {
  font-size: 1.25rem;
}

.tags.is-centered {
  justify-content: center;
}

.tags.is-centered .tag {
  margin-left: .25rem;
  margin-right: .25rem;
}

.tags.is-right {
  justify-content: flex-end;
}

.tags.is-right .tag:not(:first-child) {
  margin-left: .5rem;
}

.tags.is-right .tag:not(:last-child), .tags.has-addons .tag {
  margin-right: 0;
}

.tags.has-addons .tag:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 0;
}

.tags.has-addons .tag:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.tag:not(body) {
  color: #4a4a4a;
  white-space: nowrap;
  background-color: #f5f5f5;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  height: 2em;
  padding-left: .75em;
  padding-right: .75em;
  font-size: .75rem;
  line-height: 1.5;
  display: inline-flex;
}

.tag:not(body) .delete {
  margin-left: .25rem;
  margin-right: -.375rem;
}

.tag:not(body).is-white {
  color: #0a0a0a;
  background-color: #fff;
}

.tag:not(body).is-black {
  color: #fff;
  background-color: #0a0a0a;
}

.tag:not(body).is-light {
  color: rgba(0, 0, 0, .7);
  background-color: #f5f5f5;
}

.tag:not(body).is-dark {
  color: #fff;
  background-color: #363636;
}

.tag:not(body).is-primary {
  color: #fff;
  background-color: #00d1b2;
}

.tag:not(body).is-primary.is-light {
  color: #00947e;
  background-color: #ebfffc;
}

.tag:not(body).is-link {
  color: #fff;
  background-color: #485fc7;
}

.tag:not(body).is-link.is-light {
  color: #3850b7;
  background-color: #eff1fa;
}

.tag:not(body).is-info {
  color: #fff;
  background-color: #3e8ed0;
}

.tag:not(body).is-info.is-light {
  color: #296fa8;
  background-color: #eff5fb;
}

.tag:not(body).is-success {
  color: #fff;
  background-color: #48c78e;
}

.tag:not(body).is-success.is-light {
  color: #257953;
  background-color: #effaf5;
}

.tag:not(body).is-warning {
  color: rgba(0, 0, 0, .7);
  background-color: #ffe08a;
}

.tag:not(body).is-warning.is-light {
  color: #946c00;
  background-color: #fffaeb;
}

.tag:not(body).is-danger {
  color: #fff;
  background-color: #f14668;
}

.tag:not(body).is-danger.is-light {
  color: #cc0f35;
  background-color: #feecf0;
}

.tag:not(body).is-normal {
  font-size: .75rem;
}

.tag:not(body).is-medium {
  font-size: 1rem;
}

.tag:not(body).is-large {
  font-size: 1.25rem;
}

.tag:not(body) .icon:first-child:not(:last-child) {
  margin-left: -.375em;
  margin-right: .1875em;
}

.tag:not(body) .icon:last-child:not(:first-child) {
  margin-left: .1875em;
  margin-right: -.375em;
}

.tag:not(body) .icon:first-child:last-child {
  margin-left: -.375em;
  margin-right: -.375em;
}

.tag:not(body).is-delete {
  width: 2em;
  margin-left: 1px;
  padding: 0;
  position: relative;
}

.tag:not(body).is-delete:before, .tag:not(body).is-delete:after {
  content: "";
  -webkit-transform-origin: center;
  transform-origin: center;
  background-color: currentColor;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%)translateY(-50%)rotate(45deg);
  transform: translateX(-50%)translateY(-50%)rotate(45deg);
}

.tag:not(body).is-delete:before {
  width: 50%;
  height: 1px;
}

.tag:not(body).is-delete:after {
  width: 1px;
  height: 50%;
}

.tag:not(body).is-delete:hover, .tag:not(body).is-delete:focus {
  background-color: #e8e8e8;
}

.tag:not(body).is-delete:active {
  background-color: #dbdbdb;
}

.tag:not(body).is-rounded {
  border-radius: 9999px;
}

a.tag:hover {
  text-decoration: underline;
}

.title, .subtitle {
  word-break: break-word;
}

.title em, .title span, .subtitle em, .subtitle span {
  font-weight: inherit;
}

.title sub, .subtitle sub, .title sup, .subtitle sup {
  font-size: .75em;
}

.title .tag, .subtitle .tag {
  vertical-align: middle;
}

.title {
  color: #363636;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.125;
}

.title strong {
  color: inherit;
  font-weight: inherit;
}

.title:not(.is-spaced) + .subtitle {
  margin-top: -1.25rem;
}

.title.is-1 {
  font-size: 3rem;
}

.title.is-2 {
  font-size: 2.5rem;
}

.title.is-3 {
  font-size: 2rem;
}

.title.is-4 {
  font-size: 1.5rem;
}

.title.is-5 {
  font-size: 1.25rem;
}

.title.is-6 {
  font-size: 1rem;
}

.title.is-7 {
  font-size: .75rem;
}

.subtitle {
  color: #4a4a4a;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.25;
}

.subtitle strong {
  color: #363636;
  font-weight: 600;
}

.subtitle:not(.is-spaced) + .title {
  margin-top: -1.25rem;
}

.subtitle.is-1 {
  font-size: 3rem;
}

.subtitle.is-2 {
  font-size: 2.5rem;
}

.subtitle.is-3 {
  font-size: 2rem;
}

.subtitle.is-4 {
  font-size: 1.5rem;
}

.subtitle.is-5 {
  font-size: 1.25rem;
}

.subtitle.is-6 {
  font-size: 1rem;
}

.subtitle.is-7 {
  font-size: .75rem;
}

.heading {
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 11px;
  display: block;
}

.number {
  text-align: center;
  vertical-align: top;
  background-color: #f5f5f5;
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  min-width: 2.5em;
  height: 2em;
  margin-right: 1.5rem;
  padding: .25rem .5rem;
  font-size: 1.25rem;
  display: inline-flex;
}

.select select, .textarea, .input {
  color: #363636;
  background-color: #fff;
  border-color: #dbdbdb;
  border-radius: 4px;
}

.select select::-moz-placeholder {
  color: rgba(54, 54, 54, .3);
}

.textarea::-moz-placeholder {
  color: rgba(54, 54, 54, .3);
}

.input::-moz-placeholder {
  color: rgba(54, 54, 54, .3);
}

.select select::-webkit-input-placeholder {
  color: rgba(54, 54, 54, .3);
}

.textarea::-webkit-input-placeholder {
  color: rgba(54, 54, 54, .3);
}

.input::-webkit-input-placeholder {
  color: rgba(54, 54, 54, .3);
}

.select select:-moz-placeholder {
  color: rgba(54, 54, 54, .3);
}

.textarea:-moz-placeholder {
  color: rgba(54, 54, 54, .3);
}

.input:-moz-placeholder {
  color: rgba(54, 54, 54, .3);
}

.select select:-ms-input-placeholder {
  color: rgba(54, 54, 54, .3);
}

.textarea:-ms-input-placeholder {
  color: rgba(54, 54, 54, .3);
}

.input:-ms-input-placeholder {
  color: rgba(54, 54, 54, .3);
}

.select select:hover, .textarea:hover, .input:hover, .select select.is-hovered, .is-hovered.textarea, .is-hovered.input {
  border-color: #b5b5b5;
}

.select select:focus, .textarea:focus, .input:focus, .select select.is-focused, .is-focused.textarea, .is-focused.input, .select select:active, .textarea:active, .input:active, .select select.is-active, .is-active.textarea, .is-active.input {
  border-color: #485fc7;
  box-shadow: 0 0 0 .125em rgba(72, 95, 199, .25);
}

.select select[disabled], [disabled].textarea, [disabled].input, fieldset[disabled] .select select, .select fieldset[disabled] select, fieldset[disabled] .textarea, fieldset[disabled] .input {
  box-shadow: none;
  color: #7a7a7a;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.select select[disabled]::-moz-placeholder {
  color: rgba(122, 122, 122, .3);
}

[disabled].textarea::-moz-placeholder {
  color: rgba(122, 122, 122, .3);
}

[disabled].input::-moz-placeholder {
  color: rgba(122, 122, 122, .3);
}

fieldset[disabled] .select select::-moz-placeholder {
  color: rgba(122, 122, 122, .3);
}

.select fieldset[disabled] select::-moz-placeholder {
  color: rgba(122, 122, 122, .3);
}

fieldset[disabled] .textarea::-moz-placeholder {
  color: rgba(122, 122, 122, .3);
}

fieldset[disabled] .input::-moz-placeholder {
  color: rgba(122, 122, 122, .3);
}

.select select[disabled]::-webkit-input-placeholder {
  color: rgba(122, 122, 122, .3);
}

[disabled].textarea::-webkit-input-placeholder {
  color: rgba(122, 122, 122, .3);
}

[disabled].input::-webkit-input-placeholder {
  color: rgba(122, 122, 122, .3);
}

fieldset[disabled] .select select::-webkit-input-placeholder {
  color: rgba(122, 122, 122, .3);
}

.select fieldset[disabled] select::-webkit-input-placeholder {
  color: rgba(122, 122, 122, .3);
}

fieldset[disabled] .textarea::-webkit-input-placeholder {
  color: rgba(122, 122, 122, .3);
}

fieldset[disabled] .input::-webkit-input-placeholder {
  color: rgba(122, 122, 122, .3);
}

.select select[disabled]:-moz-placeholder {
  color: rgba(122, 122, 122, .3);
}

[disabled].textarea:-moz-placeholder {
  color: rgba(122, 122, 122, .3);
}

[disabled].input:-moz-placeholder {
  color: rgba(122, 122, 122, .3);
}

fieldset[disabled] .select select:-moz-placeholder {
  color: rgba(122, 122, 122, .3);
}

.select fieldset[disabled] select:-moz-placeholder {
  color: rgba(122, 122, 122, .3);
}

fieldset[disabled] .textarea:-moz-placeholder {
  color: rgba(122, 122, 122, .3);
}

fieldset[disabled] .input:-moz-placeholder {
  color: rgba(122, 122, 122, .3);
}

.select select[disabled]:-ms-input-placeholder {
  color: rgba(122, 122, 122, .3);
}

[disabled].textarea:-ms-input-placeholder {
  color: rgba(122, 122, 122, .3);
}

[disabled].input:-ms-input-placeholder {
  color: rgba(122, 122, 122, .3);
}

fieldset[disabled] .select select:-ms-input-placeholder {
  color: rgba(122, 122, 122, .3);
}

.select fieldset[disabled] select:-ms-input-placeholder {
  color: rgba(122, 122, 122, .3);
}

fieldset[disabled] .textarea:-ms-input-placeholder {
  color: rgba(122, 122, 122, .3);
}

fieldset[disabled] .input:-ms-input-placeholder {
  color: rgba(122, 122, 122, .3);
}

.textarea, .input {
  width: 100%;
  max-width: 100%;
  box-shadow: inset 0 .0625em .125em rgba(10, 10, 10, .05);
}

[readonly].textarea, [readonly].input {
  box-shadow: none;
}

.is-white.textarea, .is-white.input {
  border-color: #fff;
}

.is-white.textarea:focus, .is-white.input:focus, .is-white.is-focused.textarea, .is-white.is-focused.input, .is-white.textarea:active, .is-white.input:active, .is-white.is-active.textarea, .is-white.is-active.input {
  box-shadow: 0 0 0 .125em rgba(255, 255, 255, .25);
}

.is-black.textarea, .is-black.input {
  border-color: #0a0a0a;
}

.is-black.textarea:focus, .is-black.input:focus, .is-black.is-focused.textarea, .is-black.is-focused.input, .is-black.textarea:active, .is-black.input:active, .is-black.is-active.textarea, .is-black.is-active.input {
  box-shadow: 0 0 0 .125em rgba(10, 10, 10, .25);
}

.is-light.textarea, .is-light.input {
  border-color: #f5f5f5;
}

.is-light.textarea:focus, .is-light.input:focus, .is-light.is-focused.textarea, .is-light.is-focused.input, .is-light.textarea:active, .is-light.input:active, .is-light.is-active.textarea, .is-light.is-active.input {
  box-shadow: 0 0 0 .125em rgba(245, 245, 245, .25);
}

.is-dark.textarea, .is-dark.input {
  border-color: #363636;
}

.is-dark.textarea:focus, .is-dark.input:focus, .is-dark.is-focused.textarea, .is-dark.is-focused.input, .is-dark.textarea:active, .is-dark.input:active, .is-dark.is-active.textarea, .is-dark.is-active.input {
  box-shadow: 0 0 0 .125em rgba(54, 54, 54, .25);
}

.is-primary.textarea, .is-primary.input {
  border-color: #00d1b2;
}

.is-primary.textarea:focus, .is-primary.input:focus, .is-primary.is-focused.textarea, .is-primary.is-focused.input, .is-primary.textarea:active, .is-primary.input:active, .is-primary.is-active.textarea, .is-primary.is-active.input {
  box-shadow: 0 0 0 .125em rgba(0, 209, 178, .25);
}

.is-link.textarea, .is-link.input {
  border-color: #485fc7;
}

.is-link.textarea:focus, .is-link.input:focus, .is-link.is-focused.textarea, .is-link.is-focused.input, .is-link.textarea:active, .is-link.input:active, .is-link.is-active.textarea, .is-link.is-active.input {
  box-shadow: 0 0 0 .125em rgba(72, 95, 199, .25);
}

.is-info.textarea, .is-info.input {
  border-color: #3e8ed0;
}

.is-info.textarea:focus, .is-info.input:focus, .is-info.is-focused.textarea, .is-info.is-focused.input, .is-info.textarea:active, .is-info.input:active, .is-info.is-active.textarea, .is-info.is-active.input {
  box-shadow: 0 0 0 .125em rgba(62, 142, 208, .25);
}

.is-success.textarea, .is-success.input {
  border-color: #48c78e;
}

.is-success.textarea:focus, .is-success.input:focus, .is-success.is-focused.textarea, .is-success.is-focused.input, .is-success.textarea:active, .is-success.input:active, .is-success.is-active.textarea, .is-success.is-active.input {
  box-shadow: 0 0 0 .125em rgba(72, 199, 142, .25);
}

.is-warning.textarea, .is-warning.input {
  border-color: #ffe08a;
}

.is-warning.textarea:focus, .is-warning.input:focus, .is-warning.is-focused.textarea, .is-warning.is-focused.input, .is-warning.textarea:active, .is-warning.input:active, .is-warning.is-active.textarea, .is-warning.is-active.input {
  box-shadow: 0 0 0 .125em rgba(255, 224, 138, .25);
}

.is-danger.textarea, .is-danger.input {
  border-color: #f14668;
}

.is-danger.textarea:focus, .is-danger.input:focus, .is-danger.is-focused.textarea, .is-danger.is-focused.input, .is-danger.textarea:active, .is-danger.input:active, .is-danger.is-active.textarea, .is-danger.is-active.input {
  box-shadow: 0 0 0 .125em rgba(241, 70, 104, .25);
}

.is-small.textarea, .is-small.input {
  border-radius: 2px;
  font-size: .75rem;
}

.is-medium.textarea, .is-medium.input {
  font-size: 1.25rem;
}

.is-large.textarea, .is-large.input {
  font-size: 1.5rem;
}

.is-fullwidth.textarea, .is-fullwidth.input {
  width: 100%;
  display: block;
}

.is-inline.textarea, .is-inline.input {
  width: auto;
  display: inline;
}

.input.is-rounded {
  border-radius: 9999px;
  padding-left: calc(1.125em - 1px);
  padding-right: calc(1.125em - 1px);
}

.input.is-static {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
  padding-left: 0;
  padding-right: 0;
}

.textarea {
  resize: vertical;
  min-width: 100%;
  max-width: 100%;
  padding: calc(.75em - 1px);
  display: block;
}

.textarea:not([rows]) {
  min-height: 8em;
  max-height: 40em;
}

.textarea[rows] {
  height: initial;
}

.textarea.has-fixed-size {
  resize: none;
}

.radio, .checkbox {
  cursor: pointer;
  line-height: 1.25;
  display: inline-block;
  position: relative;
}

.radio input, .checkbox input {
  cursor: pointer;
}

.radio:hover, .checkbox:hover {
  color: #363636;
}

[disabled].radio, [disabled].checkbox, fieldset[disabled] .radio, fieldset[disabled] .checkbox, .radio input[disabled], .checkbox input[disabled] {
  color: #7a7a7a;
  cursor: not-allowed;
}

.radio + .radio {
  margin-left: .5em;
}

.select {
  vertical-align: top;
  max-width: 100%;
  display: inline-block;
  position: relative;
}

.select:not(.is-multiple) {
  height: 2.5em;
}

.select:not(.is-multiple):not(.is-loading):after {
  z-index: 4;
  border-color: #485fc7;
  right: 1.125em;
}

.select.is-rounded select {
  border-radius: 9999px;
  padding-left: 1em;
}

.select select {
  cursor: pointer;
  outline: none;
  max-width: 100%;
  font-size: 1em;
  display: block;
}

.select select::-ms-expand {
  display: none;
}

.select select[disabled]:hover, fieldset[disabled] .select select:hover {
  border-color: #f5f5f5;
}

.select select:not([multiple]) {
  padding-right: 2.5em;
}

.select select[multiple] {
  height: auto;
  padding: 0;
}

.select select[multiple] option {
  padding: .5em 1em;
}

.select:not(.is-multiple):not(.is-loading):hover:after {
  border-color: #363636;
}

.select.is-white:not(:hover):after, .select.is-white select {
  border-color: #fff;
}

.select.is-white select:hover, .select.is-white select.is-hovered {
  border-color: #f2f2f2;
}

.select.is-white select:focus, .select.is-white select.is-focused, .select.is-white select:active, .select.is-white select.is-active {
  box-shadow: 0 0 0 .125em rgba(255, 255, 255, .25);
}

.select.is-black:not(:hover):after, .select.is-black select {
  border-color: #0a0a0a;
}

.select.is-black select:hover, .select.is-black select.is-hovered {
  border-color: #000;
}

.select.is-black select:focus, .select.is-black select.is-focused, .select.is-black select:active, .select.is-black select.is-active {
  box-shadow: 0 0 0 .125em rgba(10, 10, 10, .25);
}

.select.is-light:not(:hover):after, .select.is-light select {
  border-color: #f5f5f5;
}

.select.is-light select:hover, .select.is-light select.is-hovered {
  border-color: #e8e8e8;
}

.select.is-light select:focus, .select.is-light select.is-focused, .select.is-light select:active, .select.is-light select.is-active {
  box-shadow: 0 0 0 .125em rgba(245, 245, 245, .25);
}

.select.is-dark:not(:hover):after, .select.is-dark select {
  border-color: #363636;
}

.select.is-dark select:hover, .select.is-dark select.is-hovered {
  border-color: #292929;
}

.select.is-dark select:focus, .select.is-dark select.is-focused, .select.is-dark select:active, .select.is-dark select.is-active {
  box-shadow: 0 0 0 .125em rgba(54, 54, 54, .25);
}

.select.is-primary:not(:hover):after, .select.is-primary select {
  border-color: #00d1b2;
}

.select.is-primary select:hover, .select.is-primary select.is-hovered {
  border-color: #00b89c;
}

.select.is-primary select:focus, .select.is-primary select.is-focused, .select.is-primary select:active, .select.is-primary select.is-active {
  box-shadow: 0 0 0 .125em rgba(0, 209, 178, .25);
}

.select.is-link:not(:hover):after, .select.is-link select {
  border-color: #485fc7;
}

.select.is-link select:hover, .select.is-link select.is-hovered {
  border-color: #3a51bb;
}

.select.is-link select:focus, .select.is-link select.is-focused, .select.is-link select:active, .select.is-link select.is-active {
  box-shadow: 0 0 0 .125em rgba(72, 95, 199, .25);
}

.select.is-info:not(:hover):after, .select.is-info select {
  border-color: #3e8ed0;
}

.select.is-info select:hover, .select.is-info select.is-hovered {
  border-color: #3082c5;
}

.select.is-info select:focus, .select.is-info select.is-focused, .select.is-info select:active, .select.is-info select.is-active {
  box-shadow: 0 0 0 .125em rgba(62, 142, 208, .25);
}

.select.is-success:not(:hover):after, .select.is-success select {
  border-color: #48c78e;
}

.select.is-success select:hover, .select.is-success select.is-hovered {
  border-color: #3abb81;
}

.select.is-success select:focus, .select.is-success select.is-focused, .select.is-success select:active, .select.is-success select.is-active {
  box-shadow: 0 0 0 .125em rgba(72, 199, 142, .25);
}

.select.is-warning:not(:hover):after, .select.is-warning select {
  border-color: #ffe08a;
}

.select.is-warning select:hover, .select.is-warning select.is-hovered {
  border-color: #ffd970;
}

.select.is-warning select:focus, .select.is-warning select.is-focused, .select.is-warning select:active, .select.is-warning select.is-active {
  box-shadow: 0 0 0 .125em rgba(255, 224, 138, .25);
}

.select.is-danger:not(:hover):after, .select.is-danger select {
  border-color: #f14668;
}

.select.is-danger select:hover, .select.is-danger select.is-hovered {
  border-color: #ef2e55;
}

.select.is-danger select:focus, .select.is-danger select.is-focused, .select.is-danger select:active, .select.is-danger select.is-active {
  box-shadow: 0 0 0 .125em rgba(241, 70, 104, .25);
}

.select.is-small {
  border-radius: 2px;
  font-size: .75rem;
}

.select.is-medium {
  font-size: 1.25rem;
}

.select.is-large {
  font-size: 1.5rem;
}

.select.is-disabled:after {
  opacity: .5;
  border-color: #7a7a7a !important;
}

.select.is-fullwidth, .select.is-fullwidth select {
  width: 100%;
}

.select.is-loading:after {
  margin-top: 0;
  position: absolute;
  top: .625em;
  right: .625em;
  -webkit-transform: none;
  transform: none;
}

.select.is-loading.is-small:after {
  font-size: .75rem;
}

.select.is-loading.is-medium:after {
  font-size: 1.25rem;
}

.select.is-loading.is-large:after {
  font-size: 1.5rem;
}

.file {
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
  position: relative;
}

.file.is-white .file-cta {
  color: #0a0a0a;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0);
}

.file.is-white:hover .file-cta, .file.is-white.is-hovered .file-cta {
  color: #0a0a0a;
  background-color: #f9f9f9;
  border-color: rgba(0, 0, 0, 0);
}

.file.is-white:focus .file-cta, .file.is-white.is-focused .file-cta {
  color: #0a0a0a;
  border-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0 .5em rgba(255, 255, 255, .25);
}

.file.is-white:active .file-cta, .file.is-white.is-active .file-cta {
  color: #0a0a0a;
  background-color: #f2f2f2;
  border-color: rgba(0, 0, 0, 0);
}

.file.is-black .file-cta {
  color: #fff;
  background-color: #0a0a0a;
  border-color: rgba(0, 0, 0, 0);
}

.file.is-black:hover .file-cta, .file.is-black.is-hovered .file-cta {
  color: #fff;
  background-color: #040404;
  border-color: rgba(0, 0, 0, 0);
}

.file.is-black:focus .file-cta, .file.is-black.is-focused .file-cta {
  color: #fff;
  border-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0 .5em rgba(10, 10, 10, .25);
}

.file.is-black:active .file-cta, .file.is-black.is-active .file-cta {
  color: #fff;
  background-color: #000;
  border-color: rgba(0, 0, 0, 0);
}

.file.is-light .file-cta {
  color: rgba(0, 0, 0, .7);
  background-color: #f5f5f5;
  border-color: rgba(0, 0, 0, 0);
}

.file.is-light:hover .file-cta, .file.is-light.is-hovered .file-cta {
  color: rgba(0, 0, 0, .7);
  background-color: #eee;
  border-color: rgba(0, 0, 0, 0);
}

.file.is-light:focus .file-cta, .file.is-light.is-focused .file-cta {
  color: rgba(0, 0, 0, .7);
  border-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0 .5em rgba(245, 245, 245, .25);
}

.file.is-light:active .file-cta, .file.is-light.is-active .file-cta {
  color: rgba(0, 0, 0, .7);
  background-color: #e8e8e8;
  border-color: rgba(0, 0, 0, 0);
}

.file.is-dark .file-cta {
  color: #fff;
  background-color: #363636;
  border-color: rgba(0, 0, 0, 0);
}

.file.is-dark:hover .file-cta, .file.is-dark.is-hovered .file-cta {
  color: #fff;
  background-color: #2f2f2f;
  border-color: rgba(0, 0, 0, 0);
}

.file.is-dark:focus .file-cta, .file.is-dark.is-focused .file-cta {
  color: #fff;
  border-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0 .5em rgba(54, 54, 54, .25);
}

.file.is-dark:active .file-cta, .file.is-dark.is-active .file-cta {
  color: #fff;
  background-color: #292929;
  border-color: rgba(0, 0, 0, 0);
}

.file.is-primary .file-cta {
  color: #fff;
  background-color: #00d1b2;
  border-color: rgba(0, 0, 0, 0);
}

.file.is-primary:hover .file-cta, .file.is-primary.is-hovered .file-cta {
  color: #fff;
  background-color: #00c4a7;
  border-color: rgba(0, 0, 0, 0);
}

.file.is-primary:focus .file-cta, .file.is-primary.is-focused .file-cta {
  color: #fff;
  border-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0 .5em rgba(0, 209, 178, .25);
}

.file.is-primary:active .file-cta, .file.is-primary.is-active .file-cta {
  color: #fff;
  background-color: #00b89c;
  border-color: rgba(0, 0, 0, 0);
}

.file.is-link .file-cta {
  color: #fff;
  background-color: #485fc7;
  border-color: rgba(0, 0, 0, 0);
}

.file.is-link:hover .file-cta, .file.is-link.is-hovered .file-cta {
  color: #fff;
  background-color: #3e56c4;
  border-color: rgba(0, 0, 0, 0);
}

.file.is-link:focus .file-cta, .file.is-link.is-focused .file-cta {
  color: #fff;
  border-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0 .5em rgba(72, 95, 199, .25);
}

.file.is-link:active .file-cta, .file.is-link.is-active .file-cta {
  color: #fff;
  background-color: #3a51bb;
  border-color: rgba(0, 0, 0, 0);
}

.file.is-info .file-cta {
  color: #fff;
  background-color: #3e8ed0;
  border-color: rgba(0, 0, 0, 0);
}

.file.is-info:hover .file-cta, .file.is-info.is-hovered .file-cta {
  color: #fff;
  background-color: #3488ce;
  border-color: rgba(0, 0, 0, 0);
}

.file.is-info:focus .file-cta, .file.is-info.is-focused .file-cta {
  color: #fff;
  border-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0 .5em rgba(62, 142, 208, .25);
}

.file.is-info:active .file-cta, .file.is-info.is-active .file-cta {
  color: #fff;
  background-color: #3082c5;
  border-color: rgba(0, 0, 0, 0);
}

.file.is-success .file-cta {
  color: #fff;
  background-color: #48c78e;
  border-color: rgba(0, 0, 0, 0);
}

.file.is-success:hover .file-cta, .file.is-success.is-hovered .file-cta {
  color: #fff;
  background-color: #3ec487;
  border-color: rgba(0, 0, 0, 0);
}

.file.is-success:focus .file-cta, .file.is-success.is-focused .file-cta {
  color: #fff;
  border-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0 .5em rgba(72, 199, 142, .25);
}

.file.is-success:active .file-cta, .file.is-success.is-active .file-cta {
  color: #fff;
  background-color: #3abb81;
  border-color: rgba(0, 0, 0, 0);
}

.file.is-warning .file-cta {
  color: rgba(0, 0, 0, .7);
  background-color: #ffe08a;
  border-color: rgba(0, 0, 0, 0);
}

.file.is-warning:hover .file-cta, .file.is-warning.is-hovered .file-cta {
  color: rgba(0, 0, 0, .7);
  background-color: #ffdc7d;
  border-color: rgba(0, 0, 0, 0);
}

.file.is-warning:focus .file-cta, .file.is-warning.is-focused .file-cta {
  color: rgba(0, 0, 0, .7);
  border-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0 .5em rgba(255, 224, 138, .25);
}

.file.is-warning:active .file-cta, .file.is-warning.is-active .file-cta {
  color: rgba(0, 0, 0, .7);
  background-color: #ffd970;
  border-color: rgba(0, 0, 0, 0);
}

.file.is-danger .file-cta {
  color: #fff;
  background-color: #f14668;
  border-color: rgba(0, 0, 0, 0);
}

.file.is-danger:hover .file-cta, .file.is-danger.is-hovered .file-cta {
  color: #fff;
  background-color: #f03a5f;
  border-color: rgba(0, 0, 0, 0);
}

.file.is-danger:focus .file-cta, .file.is-danger.is-focused .file-cta {
  color: #fff;
  border-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0 .5em rgba(241, 70, 104, .25);
}

.file.is-danger:active .file-cta, .file.is-danger.is-active .file-cta {
  color: #fff;
  background-color: #ef2e55;
  border-color: rgba(0, 0, 0, 0);
}

.file.is-small {
  font-size: .75rem;
}

.file.is-normal {
  font-size: 1rem;
}

.file.is-medium {
  font-size: 1.25rem;
}

.file.is-medium .file-icon .fa {
  font-size: 21px;
}

.file.is-large {
  font-size: 1.5rem;
}

.file.is-large .file-icon .fa {
  font-size: 28px;
}

.file.has-name .file-cta {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.file.has-name .file-name {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.file.has-name.is-empty .file-cta {
  border-radius: 4px;
}

.file.has-name.is-empty .file-name {
  display: none;
}

.file.is-boxed .file-label {
  flex-direction: column;
}

.file.is-boxed .file-cta {
  flex-direction: column;
  height: auto;
  padding: 1em 3em;
}

.file.is-boxed .file-name {
  border-width: 0 1px 1px;
}

.file.is-boxed .file-icon {
  width: 1.5em;
  height: 1.5em;
}

.file.is-boxed .file-icon .fa {
  font-size: 21px;
}

.file.is-boxed.is-small .file-icon .fa {
  font-size: 14px;
}

.file.is-boxed.is-medium .file-icon .fa {
  font-size: 28px;
}

.file.is-boxed.is-large .file-icon .fa {
  font-size: 35px;
}

.file.is-boxed.has-name .file-cta {
  border-radius: 4px 4px 0 0;
}

.file.is-boxed.has-name .file-name {
  border-width: 0 1px 1px;
  border-radius: 0 0 4px 4px;
}

.file.is-centered {
  justify-content: center;
}

.file.is-fullwidth .file-label {
  width: 100%;
}

.file.is-fullwidth .file-name {
  flex-grow: 1;
  max-width: none;
}

.file.is-right {
  justify-content: flex-end;
}

.file.is-right .file-cta {
  border-radius: 0 4px 4px 0;
}

.file.is-right .file-name {
  border-width: 1px 0 1px 1px;
  border-radius: 4px 0 0 4px;
  order: -1;
}

.file-label {
  cursor: pointer;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
  position: relative;
  overflow: hidden;
}

.file-label:hover .file-cta {
  color: #363636;
  background-color: #eee;
}

.file-label:hover .file-name {
  border-color: #d5d5d5;
}

.file-label:active .file-cta {
  color: #363636;
  background-color: #e8e8e8;
}

.file-label:active .file-name {
  border-color: #cfcfcf;
}

.file-input {
  opacity: 0;
  outline: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.file-cta, .file-name {
  white-space: nowrap;
  border-color: #dbdbdb;
  border-radius: 4px;
  padding-left: 1em;
  padding-right: 1em;
  font-size: 1em;
}

.file-cta {
  color: #4a4a4a;
  background-color: #f5f5f5;
}

.file-name {
  text-align: inherit;
  text-overflow: ellipsis;
  border: 1px solid #dbdbdb;
  border-left-width: 0;
  max-width: 16em;
  display: block;
  overflow: hidden;
}

.file-icon {
  justify-content: center;
  align-items: center;
  width: 1em;
  height: 1em;
  margin-right: .5em;
  display: flex;
}

.file-icon .fa {
  font-size: 14px;
}

.label {
  color: #363636;
  font-size: 1rem;
  font-weight: 700;
  display: block;
}

.label:not(:last-child) {
  margin-bottom: .5em;
}

.label.is-small {
  font-size: .75rem;
}

.label.is-medium {
  font-size: 1.25rem;
}

.label.is-large {
  font-size: 1.5rem;
}

.help {
  margin-top: .25rem;
  font-size: .75rem;
  display: block;
}

.help.is-white {
  color: #fff;
}

.help.is-black {
  color: #0a0a0a;
}

.help.is-light {
  color: #f5f5f5;
}

.help.is-dark {
  color: #363636;
}

.help.is-primary {
  color: #00d1b2;
}

.help.is-link {
  color: #485fc7;
}

.help.is-info {
  color: #3e8ed0;
}

.help.is-success {
  color: #48c78e;
}

.help.is-warning {
  color: #ffe08a;
}

.help.is-danger {
  color: #f14668;
}

.field:not(:last-child) {
  margin-bottom: .75rem;
}

.field.has-addons {
  justify-content: flex-start;
  display: flex;
}

.field.has-addons .control:not(:last-child) {
  margin-right: -1px;
}

.field.has-addons .control:not(:first-child):not(:last-child) .button, .field.has-addons .control:not(:first-child):not(:last-child) .input, .field.has-addons .control:not(:first-child):not(:last-child) .select select {
  border-radius: 0;
}

.field.has-addons .control:first-child:not(:only-child) .button, .field.has-addons .control:first-child:not(:only-child) .input, .field.has-addons .control:first-child:not(:only-child) .select select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.field.has-addons .control:last-child:not(:only-child) .button, .field.has-addons .control:last-child:not(:only-child) .input, .field.has-addons .control:last-child:not(:only-child) .select select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.field.has-addons .control .button:not([disabled]):hover, .field.has-addons .control .button:not([disabled]).is-hovered, .field.has-addons .control .input:not([disabled]):hover, .field.has-addons .control .input:not([disabled]).is-hovered, .field.has-addons .control .select select:not([disabled]):hover, .field.has-addons .control .select select:not([disabled]).is-hovered {
  z-index: 2;
}

.field.has-addons .control .button:not([disabled]):focus, .field.has-addons .control .button:not([disabled]).is-focused, .field.has-addons .control .button:not([disabled]):active, .field.has-addons .control .button:not([disabled]).is-active, .field.has-addons .control .input:not([disabled]):focus, .field.has-addons .control .input:not([disabled]).is-focused, .field.has-addons .control .input:not([disabled]):active, .field.has-addons .control .input:not([disabled]).is-active, .field.has-addons .control .select select:not([disabled]):focus, .field.has-addons .control .select select:not([disabled]).is-focused, .field.has-addons .control .select select:not([disabled]):active, .field.has-addons .control .select select:not([disabled]).is-active {
  z-index: 3;
}

.field.has-addons .control .button:not([disabled]):focus:hover, .field.has-addons .control .button:not([disabled]).is-focused:hover, .field.has-addons .control .button:not([disabled]):active:hover, .field.has-addons .control .button:not([disabled]).is-active:hover, .field.has-addons .control .input:not([disabled]):focus:hover, .field.has-addons .control .input:not([disabled]).is-focused:hover, .field.has-addons .control .input:not([disabled]):active:hover, .field.has-addons .control .input:not([disabled]).is-active:hover, .field.has-addons .control .select select:not([disabled]):focus:hover, .field.has-addons .control .select select:not([disabled]).is-focused:hover, .field.has-addons .control .select select:not([disabled]):active:hover, .field.has-addons .control .select select:not([disabled]).is-active:hover {
  z-index: 4;
}

.field.has-addons .control.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.field.has-addons.has-addons-centered {
  justify-content: center;
}

.field.has-addons.has-addons-right {
  justify-content: flex-end;
}

.field.has-addons.has-addons-fullwidth .control {
  flex-grow: 1;
  flex-shrink: 0;
}

.field.is-grouped {
  justify-content: flex-start;
  display: flex;
}

.field.is-grouped > .control {
  flex-shrink: 0;
}

.field.is-grouped > .control:not(:last-child) {
  margin-bottom: 0;
  margin-right: .75rem;
}

.field.is-grouped > .control.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.field.is-grouped.is-grouped-centered {
  justify-content: center;
}

.field.is-grouped.is-grouped-right {
  justify-content: flex-end;
}

.field.is-grouped.is-grouped-multiline {
  flex-wrap: wrap;
}

.field.is-grouped.is-grouped-multiline > .control:last-child, .field.is-grouped.is-grouped-multiline > .control:not(:last-child) {
  margin-bottom: .75rem;
}

.field.is-grouped.is-grouped-multiline:last-child {
  margin-bottom: -.75rem;
}

.field.is-grouped.is-grouped-multiline:not(:last-child) {
  margin-bottom: 0;
}

@media screen and (min-width: 769px), print {
  .field.is-horizontal {
    display: flex;
  }
}

.field-label .label {
  font-size: inherit;
}

@media screen and (max-width: 768px) {
  .field-label {
    margin-bottom: .5rem;
  }
}

@media screen and (min-width: 769px), print {
  .field-label {
    text-align: right;
    flex: 1 0 0;
    margin-right: 1.5rem;
  }

  .field-label.is-small {
    padding-top: .375em;
    font-size: .75rem;
  }

  .field-label.is-normal {
    padding-top: .375em;
  }

  .field-label.is-medium {
    padding-top: .375em;
    font-size: 1.25rem;
  }

  .field-label.is-large {
    padding-top: .375em;
    font-size: 1.5rem;
  }
}

.field-body .field .field {
  margin-bottom: 0;
}

@media screen and (min-width: 769px), print {
  .field-body {
    flex: 5 1 0;
    display: flex;
  }

  .field-body .field {
    margin-bottom: 0;
  }

  .field-body > .field {
    flex-shrink: 1;
  }

  .field-body > .field:not(.is-narrow) {
    flex-grow: 1;
  }

  .field-body > .field:not(:last-child) {
    margin-right: .75rem;
  }
}

.control {
  box-sizing: border-box;
  clear: both;
  text-align: inherit;
  font-size: 1rem;
  position: relative;
}

.control.has-icons-left .input:focus ~ .icon, .control.has-icons-left .select:focus ~ .icon, .control.has-icons-right .input:focus ~ .icon, .control.has-icons-right .select:focus ~ .icon {
  color: #4a4a4a;
}

.control.has-icons-left .input.is-small ~ .icon, .control.has-icons-left .select.is-small ~ .icon, .control.has-icons-right .input.is-small ~ .icon, .control.has-icons-right .select.is-small ~ .icon {
  font-size: .75rem;
}

.control.has-icons-left .input.is-medium ~ .icon, .control.has-icons-left .select.is-medium ~ .icon, .control.has-icons-right .input.is-medium ~ .icon, .control.has-icons-right .select.is-medium ~ .icon {
  font-size: 1.25rem;
}

.control.has-icons-left .input.is-large ~ .icon, .control.has-icons-left .select.is-large ~ .icon, .control.has-icons-right .input.is-large ~ .icon, .control.has-icons-right .select.is-large ~ .icon {
  font-size: 1.5rem;
}

.control.has-icons-left .icon, .control.has-icons-right .icon {
  color: #dbdbdb;
  pointer-events: none;
  z-index: 4;
  width: 2.5em;
  height: 2.5em;
  position: absolute;
  top: 0;
}

.control.has-icons-left .input, .control.has-icons-left .select select {
  padding-left: 2.5em;
}

.control.has-icons-left .icon.is-left {
  left: 0;
}

.control.has-icons-right .input, .control.has-icons-right .select select {
  padding-right: 2.5em;
}

.control.has-icons-right .icon.is-right {
  right: 0;
}

.control.is-loading:after {
  z-index: 4;
  top: .625em;
  right: .625em;
  position: absolute !important;
}

.control.is-loading.is-small:after {
  font-size: .75rem;
}

.control.is-loading.is-medium:after {
  font-size: 1.25rem;
}

.control.is-loading.is-large:after {
  font-size: 1.5rem;
}

.breadcrumb {
  white-space: nowrap;
  font-size: 1rem;
}

.breadcrumb a {
  color: #485fc7;
  justify-content: center;
  align-items: center;
  padding: 0 .75em;
  display: flex;
}

.breadcrumb a:hover {
  color: #363636;
}

.breadcrumb li {
  align-items: center;
  display: flex;
}

.breadcrumb li:first-child a {
  padding-left: 0;
}

.breadcrumb li.is-active a {
  color: #363636;
  cursor: default;
  pointer-events: none;
}

.breadcrumb li + li:before {
  color: #b5b5b5;
  content: "/";
}

.breadcrumb ul, .breadcrumb ol {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.breadcrumb .icon:first-child {
  margin-right: .5em;
}

.breadcrumb .icon:last-child {
  margin-left: .5em;
}

.breadcrumb.is-centered ol, .breadcrumb.is-centered ul {
  justify-content: center;
}

.breadcrumb.is-right ol, .breadcrumb.is-right ul {
  justify-content: flex-end;
}

.breadcrumb.is-small {
  font-size: .75rem;
}

.breadcrumb.is-medium {
  font-size: 1.25rem;
}

.breadcrumb.is-large {
  font-size: 1.5rem;
}

.breadcrumb.has-arrow-separator li + li:before {
  content: "→";
}

.breadcrumb.has-bullet-separator li + li:before {
  content: "•";
}

.breadcrumb.has-dot-separator li + li:before {
  content: "·";
}

.breadcrumb.has-succeeds-separator li + li:before {
  content: "≻";
}

.card {
  color: #4a4a4a;
  background-color: #fff;
  border-radius: .25rem;
  max-width: 100%;
  position: relative;
  box-shadow: 0 .5em 1em -.125em rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .02);
}

.card-footer:first-child, .card-content:first-child, .card-header:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.card-footer:last-child, .card-content:last-child, .card-header:last-child {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.card-header {
  background-color: rgba(0, 0, 0, 0);
  align-items: stretch;
  display: flex;
  box-shadow: 0 .125em .25em rgba(10, 10, 10, .1);
}

.card-header-title {
  color: #363636;
  flex-grow: 1;
  align-items: center;
  padding: .75rem 1rem;
  font-weight: 700;
  display: flex;
}

.card-header-title.is-centered {
  justify-content: center;
}

.card-header-icon {
  -webkit-appearance: none;
  appearance: none;
  color: currentColor;
  cursor: pointer;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: .75rem 1rem;
  font-family: inherit;
  font-size: 1em;
  display: flex;
}

.card-image {
  display: block;
  position: relative;
}

.card-image:first-child img {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.card-image:last-child img {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.card-content {
  background-color: rgba(0, 0, 0, 0);
  padding: 1.5rem;
}

.card-footer {
  background-color: rgba(0, 0, 0, 0);
  border-top: 1px solid #ededed;
  align-items: stretch;
  display: flex;
}

.card-footer-item {
  flex: 1 0 0;
  justify-content: center;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.card-footer-item:not(:last-child) {
  border-right: 1px solid #ededed;
}

.card .media:not(:last-child) {
  margin-bottom: 1.5rem;
}

.dropdown {
  vertical-align: top;
  display: inline-flex;
  position: relative;
}

.dropdown.is-active .dropdown-menu, .dropdown.is-hoverable:hover .dropdown-menu {
  display: block;
}

.dropdown.is-right .dropdown-menu {
  left: auto;
  right: 0;
}

.dropdown.is-up .dropdown-menu {
  padding-bottom: 4px;
  padding-top: initial;
  top: auto;
  bottom: 100%;
}

.dropdown-menu {
  z-index: 20;
  min-width: 12rem;
  padding-top: 4px;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.dropdown-content {
  background-color: #fff;
  border-radius: 4px;
  padding-top: .5rem;
  padding-bottom: .5rem;
  box-shadow: 0 .5em 1em -.125em rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .02);
}

.dropdown-item {
  color: #4a4a4a;
  padding: .375rem 1rem;
  font-size: .875rem;
  line-height: 1.5;
  display: block;
  position: relative;
}

a.dropdown-item, button.dropdown-item {
  text-align: inherit;
  white-space: nowrap;
  width: 100%;
  padding-right: 3rem;
}

a.dropdown-item:hover, button.dropdown-item:hover {
  color: #0a0a0a;
  background-color: #f5f5f5;
}

a.dropdown-item.is-active, button.dropdown-item.is-active {
  color: #fff;
  background-color: #485fc7;
}

.dropdown-divider {
  background-color: #ededed;
  border: none;
  height: 1px;
  margin: .5rem 0;
  display: block;
}

.level {
  justify-content: space-between;
  align-items: center;
}

.level code {
  border-radius: 4px;
}

.level img {
  vertical-align: top;
  display: inline-block;
}

.level.is-mobile, .level.is-mobile .level-left, .level.is-mobile .level-right {
  display: flex;
}

.level.is-mobile .level-left + .level-right {
  margin-top: 0;
}

.level.is-mobile .level-item:not(:last-child) {
  margin-bottom: 0;
  margin-right: .75rem;
}

.level.is-mobile .level-item:not(.is-narrow) {
  flex-grow: 1;
}

@media screen and (min-width: 769px), print {
  .level {
    display: flex;
  }

  .level > .level-item:not(.is-narrow) {
    flex-grow: 1;
  }
}

.level-item {
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.level-item .title, .level-item .subtitle {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .level-item:not(:last-child) {
    margin-bottom: .75rem;
  }
}

.level-left, .level-right {
  flex: none;
}

.level-left .level-item.is-flexible, .level-right .level-item.is-flexible {
  flex-grow: 1;
}

@media screen and (min-width: 769px), print {
  .level-left .level-item:not(:last-child), .level-right .level-item:not(:last-child) {
    margin-right: .75rem;
  }
}

.level-left {
  justify-content: flex-start;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .level-left + .level-right {
    margin-top: 1.5rem;
  }
}

@media screen and (min-width: 769px), print {
  .level-left {
    display: flex;
  }
}

.level-right {
  justify-content: flex-end;
  align-items: center;
}

@media screen and (min-width: 769px), print {
  .level-right {
    display: flex;
  }
}

.media {
  text-align: inherit;
  align-items: flex-start;
  display: flex;
}

.media .content:not(:last-child) {
  margin-bottom: .75rem;
}

.media .media {
  border-top: 1px solid rgba(219, 219, 219, .5);
  padding-top: .75rem;
  display: flex;
}

.media .media .content:not(:last-child), .media .media .control:not(:last-child) {
  margin-bottom: .5rem;
}

.media .media .media {
  padding-top: .5rem;
}

.media .media .media + .media {
  margin-top: .5rem;
}

.media + .media {
  border-top: 1px solid rgba(219, 219, 219, .5);
  margin-top: 1rem;
  padding-top: 1rem;
}

.media.is-large + .media {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
}

.media-left, .media-right {
  flex: none;
}

.media-left {
  margin-right: 1rem;
}

.media-right {
  margin-left: 1rem;
}

.media-content {
  text-align: inherit;
  flex: auto;
}

@media screen and (max-width: 768px) {
  .media-content {
    overflow-x: auto;
  }
}

.menu {
  font-size: 1rem;
}

.menu.is-small {
  font-size: .75rem;
}

.menu.is-medium {
  font-size: 1.25rem;
}

.menu.is-large {
  font-size: 1.5rem;
}

.menu-list {
  line-height: 1.25;
}

.menu-list a {
  color: #4a4a4a;
  border-radius: 2px;
  padding: .5em .75em;
  display: block;
}

.menu-list a:hover {
  color: #363636;
  background-color: #f5f5f5;
}

.menu-list a.is-active {
  color: #fff;
  background-color: #485fc7;
}

.menu-list li ul {
  border-left: 1px solid #dbdbdb;
  margin: .75em;
  padding-left: .75em;
}

.menu-label {
  color: #7a7a7a;
  letter-spacing: .1em;
  text-transform: uppercase;
  font-size: .75em;
}

.menu-label:not(:first-child) {
  margin-top: 1em;
}

.menu-label:not(:last-child) {
  margin-bottom: 1em;
}

.message {
  background-color: #f5f5f5;
  border-radius: 4px;
  font-size: 1rem;
}

.message strong {
  color: currentColor;
}

.message a:not(.button):not(.tag):not(.dropdown-item) {
  color: currentColor;
  text-decoration: underline;
}

.message.is-small {
  font-size: .75rem;
}

.message.is-medium {
  font-size: 1.25rem;
}

.message.is-large {
  font-size: 1.5rem;
}

.message.is-white {
  background-color: #fff;
}

.message.is-white .message-header {
  color: #0a0a0a;
  background-color: #fff;
}

.message.is-white .message-body {
  border-color: #fff;
}

.message.is-black {
  background-color: #fafafa;
}

.message.is-black .message-header {
  color: #fff;
  background-color: #0a0a0a;
}

.message.is-black .message-body {
  border-color: #0a0a0a;
}

.message.is-light {
  background-color: #fafafa;
}

.message.is-light .message-header {
  color: rgba(0, 0, 0, .7);
  background-color: #f5f5f5;
}

.message.is-light .message-body {
  border-color: #f5f5f5;
}

.message.is-dark {
  background-color: #fafafa;
}

.message.is-dark .message-header {
  color: #fff;
  background-color: #363636;
}

.message.is-dark .message-body {
  border-color: #363636;
}

.message.is-primary {
  background-color: #ebfffc;
}

.message.is-primary .message-header {
  color: #fff;
  background-color: #00d1b2;
}

.message.is-primary .message-body {
  color: #00947e;
  border-color: #00d1b2;
}

.message.is-link {
  background-color: #eff1fa;
}

.message.is-link .message-header {
  color: #fff;
  background-color: #485fc7;
}

.message.is-link .message-body {
  color: #3850b7;
  border-color: #485fc7;
}

.message.is-info {
  background-color: #eff5fb;
}

.message.is-info .message-header {
  color: #fff;
  background-color: #3e8ed0;
}

.message.is-info .message-body {
  color: #296fa8;
  border-color: #3e8ed0;
}

.message.is-success {
  background-color: #effaf5;
}

.message.is-success .message-header {
  color: #fff;
  background-color: #48c78e;
}

.message.is-success .message-body {
  color: #257953;
  border-color: #48c78e;
}

.message.is-warning {
  background-color: #fffaeb;
}

.message.is-warning .message-header {
  color: rgba(0, 0, 0, .7);
  background-color: #ffe08a;
}

.message.is-warning .message-body {
  color: #946c00;
  border-color: #ffe08a;
}

.message.is-danger {
  background-color: #feecf0;
}

.message.is-danger .message-header {
  color: #fff;
  background-color: #f14668;
}

.message.is-danger .message-body {
  color: #cc0f35;
  border-color: #f14668;
}

.message-header {
  color: #fff;
  background-color: #4a4a4a;
  border-radius: 4px 4px 0 0;
  justify-content: space-between;
  align-items: center;
  padding: .75em 1em;
  font-weight: 700;
  line-height: 1.25;
  display: flex;
  position: relative;
}

.message-header .delete {
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: .75em;
}

.message-header + .message-body {
  border-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.message-body {
  color: #4a4a4a;
  border: 0 solid #dbdbdb;
  border-left-width: 4px;
  border-radius: 4px;
  padding: 1.25em 1.5em;
}

.message-body code, .message-body pre {
  background-color: #fff;
}

.message-body pre code {
  background-color: rgba(0, 0, 0, 0);
}

.modal {
  z-index: 40;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  overflow: hidden;
}

.modal.is-active {
  display: flex;
}

.modal-background {
  background-color: rgba(10, 10, 10, .86);
}

.modal-content, .modal-card {
  width: 100%;
  max-height: calc(100vh - 160px);
  margin: 0 20px;
  position: relative;
  overflow: auto;
}

@media screen and (min-width: 769px) {
  .modal-content, .modal-card {
    width: 640px;
    max-height: calc(100vh - 40px);
    margin: 0 auto;
  }
}

.modal-close {
  background: none;
  width: 40px;
  height: 40px;
  position: fixed;
  top: 20px;
  right: 20px;
}

.modal-card {
  -ms-overflow-y: visible;
  flex-direction: column;
  max-height: calc(100vh - 40px);
  display: flex;
  overflow: hidden;
}

.modal-card-head, .modal-card-foot {
  background-color: #f5f5f5;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  display: flex;
  position: relative;
}

.modal-card-head {
  border-bottom: 1px solid #dbdbdb;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.modal-card-title {
  color: #363636;
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 1.5rem;
  line-height: 1;
}

.modal-card-foot {
  border-top: 1px solid #dbdbdb;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.modal-card-foot .button:not(:last-child) {
  margin-right: .5em;
}

.modal-card-body {
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 20px;
  overflow: auto;
}

.navbar {
  z-index: 30;
  background-color: #fff;
  min-height: 3.25rem;
  position: relative;
}

.navbar.is-white {
  color: #0a0a0a;
  background-color: #fff;
}

.navbar.is-white .navbar-brand > .navbar-item, .navbar.is-white .navbar-brand .navbar-link {
  color: #0a0a0a;
}

.navbar.is-white .navbar-brand > a.navbar-item:focus, .navbar.is-white .navbar-brand > a.navbar-item:hover, .navbar.is-white .navbar-brand > a.navbar-item.is-active, .navbar.is-white .navbar-brand .navbar-link:focus, .navbar.is-white .navbar-brand .navbar-link:hover, .navbar.is-white .navbar-brand .navbar-link.is-active {
  color: #0a0a0a;
  background-color: #f2f2f2;
}

.navbar.is-white .navbar-brand .navbar-link:after {
  border-color: #0a0a0a;
}

.navbar.is-white .navbar-burger {
  color: #0a0a0a;
}

@media screen and (min-width: 1024px) {
  .navbar.is-white .navbar-start > .navbar-item, .navbar.is-white .navbar-start .navbar-link, .navbar.is-white .navbar-end > .navbar-item, .navbar.is-white .navbar-end .navbar-link {
    color: #0a0a0a;
  }

  .navbar.is-white .navbar-start > a.navbar-item:focus, .navbar.is-white .navbar-start > a.navbar-item:hover, .navbar.is-white .navbar-start > a.navbar-item.is-active, .navbar.is-white .navbar-start .navbar-link:focus, .navbar.is-white .navbar-start .navbar-link:hover, .navbar.is-white .navbar-start .navbar-link.is-active, .navbar.is-white .navbar-end > a.navbar-item:focus, .navbar.is-white .navbar-end > a.navbar-item:hover, .navbar.is-white .navbar-end > a.navbar-item.is-active, .navbar.is-white .navbar-end .navbar-link:focus, .navbar.is-white .navbar-end .navbar-link:hover, .navbar.is-white .navbar-end .navbar-link.is-active {
    color: #0a0a0a;
    background-color: #f2f2f2;
  }

  .navbar.is-white .navbar-start .navbar-link:after, .navbar.is-white .navbar-end .navbar-link:after {
    border-color: #0a0a0a;
  }

  .navbar.is-white .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-white .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-white .navbar-item.has-dropdown.is-active .navbar-link {
    color: #0a0a0a;
    background-color: #f2f2f2;
  }

  .navbar.is-white .navbar-dropdown a.navbar-item.is-active {
    color: #0a0a0a;
    background-color: #fff;
  }
}

.navbar.is-black {
  color: #fff;
  background-color: #0a0a0a;
}

.navbar.is-black .navbar-brand > .navbar-item, .navbar.is-black .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-black .navbar-brand > a.navbar-item:focus, .navbar.is-black .navbar-brand > a.navbar-item:hover, .navbar.is-black .navbar-brand > a.navbar-item.is-active, .navbar.is-black .navbar-brand .navbar-link:focus, .navbar.is-black .navbar-brand .navbar-link:hover, .navbar.is-black .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #000;
}

.navbar.is-black .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-black .navbar-burger {
  color: #fff;
}

@media screen and (min-width: 1024px) {
  .navbar.is-black .navbar-start > .navbar-item, .navbar.is-black .navbar-start .navbar-link, .navbar.is-black .navbar-end > .navbar-item, .navbar.is-black .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-black .navbar-start > a.navbar-item:focus, .navbar.is-black .navbar-start > a.navbar-item:hover, .navbar.is-black .navbar-start > a.navbar-item.is-active, .navbar.is-black .navbar-start .navbar-link:focus, .navbar.is-black .navbar-start .navbar-link:hover, .navbar.is-black .navbar-start .navbar-link.is-active, .navbar.is-black .navbar-end > a.navbar-item:focus, .navbar.is-black .navbar-end > a.navbar-item:hover, .navbar.is-black .navbar-end > a.navbar-item.is-active, .navbar.is-black .navbar-end .navbar-link:focus, .navbar.is-black .navbar-end .navbar-link:hover, .navbar.is-black .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #000;
  }

  .navbar.is-black .navbar-start .navbar-link:after, .navbar.is-black .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-black .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-black .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-black .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #000;
  }

  .navbar.is-black .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #0a0a0a;
  }
}

.navbar.is-light {
  color: rgba(0, 0, 0, .7);
  background-color: #f5f5f5;
}

.navbar.is-light .navbar-brand > .navbar-item, .navbar.is-light .navbar-brand .navbar-link {
  color: rgba(0, 0, 0, .7);
}

.navbar.is-light .navbar-brand > a.navbar-item:focus, .navbar.is-light .navbar-brand > a.navbar-item:hover, .navbar.is-light .navbar-brand > a.navbar-item.is-active, .navbar.is-light .navbar-brand .navbar-link:focus, .navbar.is-light .navbar-brand .navbar-link:hover, .navbar.is-light .navbar-brand .navbar-link.is-active {
  color: rgba(0, 0, 0, .7);
  background-color: #e8e8e8;
}

.navbar.is-light .navbar-brand .navbar-link:after {
  border-color: rgba(0, 0, 0, .7);
}

.navbar.is-light .navbar-burger {
  color: rgba(0, 0, 0, .7);
}

@media screen and (min-width: 1024px) {
  .navbar.is-light .navbar-start > .navbar-item, .navbar.is-light .navbar-start .navbar-link, .navbar.is-light .navbar-end > .navbar-item, .navbar.is-light .navbar-end .navbar-link {
    color: rgba(0, 0, 0, .7);
  }

  .navbar.is-light .navbar-start > a.navbar-item:focus, .navbar.is-light .navbar-start > a.navbar-item:hover, .navbar.is-light .navbar-start > a.navbar-item.is-active, .navbar.is-light .navbar-start .navbar-link:focus, .navbar.is-light .navbar-start .navbar-link:hover, .navbar.is-light .navbar-start .navbar-link.is-active, .navbar.is-light .navbar-end > a.navbar-item:focus, .navbar.is-light .navbar-end > a.navbar-item:hover, .navbar.is-light .navbar-end > a.navbar-item.is-active, .navbar.is-light .navbar-end .navbar-link:focus, .navbar.is-light .navbar-end .navbar-link:hover, .navbar.is-light .navbar-end .navbar-link.is-active {
    color: rgba(0, 0, 0, .7);
    background-color: #e8e8e8;
  }

  .navbar.is-light .navbar-start .navbar-link:after, .navbar.is-light .navbar-end .navbar-link:after {
    border-color: rgba(0, 0, 0, .7);
  }

  .navbar.is-light .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-light .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-light .navbar-item.has-dropdown.is-active .navbar-link {
    color: rgba(0, 0, 0, .7);
    background-color: #e8e8e8;
  }

  .navbar.is-light .navbar-dropdown a.navbar-item.is-active {
    color: rgba(0, 0, 0, .7);
    background-color: #f5f5f5;
  }
}

.navbar.is-dark {
  color: #fff;
  background-color: #363636;
}

.navbar.is-dark .navbar-brand > .navbar-item, .navbar.is-dark .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-dark .navbar-brand > a.navbar-item:focus, .navbar.is-dark .navbar-brand > a.navbar-item:hover, .navbar.is-dark .navbar-brand > a.navbar-item.is-active, .navbar.is-dark .navbar-brand .navbar-link:focus, .navbar.is-dark .navbar-brand .navbar-link:hover, .navbar.is-dark .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #292929;
}

.navbar.is-dark .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-dark .navbar-burger {
  color: #fff;
}

@media screen and (min-width: 1024px) {
  .navbar.is-dark .navbar-start > .navbar-item, .navbar.is-dark .navbar-start .navbar-link, .navbar.is-dark .navbar-end > .navbar-item, .navbar.is-dark .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-dark .navbar-start > a.navbar-item:focus, .navbar.is-dark .navbar-start > a.navbar-item:hover, .navbar.is-dark .navbar-start > a.navbar-item.is-active, .navbar.is-dark .navbar-start .navbar-link:focus, .navbar.is-dark .navbar-start .navbar-link:hover, .navbar.is-dark .navbar-start .navbar-link.is-active, .navbar.is-dark .navbar-end > a.navbar-item:focus, .navbar.is-dark .navbar-end > a.navbar-item:hover, .navbar.is-dark .navbar-end > a.navbar-item.is-active, .navbar.is-dark .navbar-end .navbar-link:focus, .navbar.is-dark .navbar-end .navbar-link:hover, .navbar.is-dark .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #292929;
  }

  .navbar.is-dark .navbar-start .navbar-link:after, .navbar.is-dark .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-dark .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-dark .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-dark .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #292929;
  }

  .navbar.is-dark .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #363636;
  }
}

.navbar.is-primary {
  color: #fff;
  background-color: #00d1b2;
}

.navbar.is-primary .navbar-brand > .navbar-item, .navbar.is-primary .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-primary .navbar-brand > a.navbar-item:focus, .navbar.is-primary .navbar-brand > a.navbar-item:hover, .navbar.is-primary .navbar-brand > a.navbar-item.is-active, .navbar.is-primary .navbar-brand .navbar-link:focus, .navbar.is-primary .navbar-brand .navbar-link:hover, .navbar.is-primary .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #00b89c;
}

.navbar.is-primary .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-primary .navbar-burger {
  color: #fff;
}

@media screen and (min-width: 1024px) {
  .navbar.is-primary .navbar-start > .navbar-item, .navbar.is-primary .navbar-start .navbar-link, .navbar.is-primary .navbar-end > .navbar-item, .navbar.is-primary .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-primary .navbar-start > a.navbar-item:focus, .navbar.is-primary .navbar-start > a.navbar-item:hover, .navbar.is-primary .navbar-start > a.navbar-item.is-active, .navbar.is-primary .navbar-start .navbar-link:focus, .navbar.is-primary .navbar-start .navbar-link:hover, .navbar.is-primary .navbar-start .navbar-link.is-active, .navbar.is-primary .navbar-end > a.navbar-item:focus, .navbar.is-primary .navbar-end > a.navbar-item:hover, .navbar.is-primary .navbar-end > a.navbar-item.is-active, .navbar.is-primary .navbar-end .navbar-link:focus, .navbar.is-primary .navbar-end .navbar-link:hover, .navbar.is-primary .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #00b89c;
  }

  .navbar.is-primary .navbar-start .navbar-link:after, .navbar.is-primary .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-primary .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-primary .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-primary .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #00b89c;
  }

  .navbar.is-primary .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #00d1b2;
  }
}

.navbar.is-link {
  color: #fff;
  background-color: #485fc7;
}

.navbar.is-link .navbar-brand > .navbar-item, .navbar.is-link .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-link .navbar-brand > a.navbar-item:focus, .navbar.is-link .navbar-brand > a.navbar-item:hover, .navbar.is-link .navbar-brand > a.navbar-item.is-active, .navbar.is-link .navbar-brand .navbar-link:focus, .navbar.is-link .navbar-brand .navbar-link:hover, .navbar.is-link .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #3a51bb;
}

.navbar.is-link .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-link .navbar-burger {
  color: #fff;
}

@media screen and (min-width: 1024px) {
  .navbar.is-link .navbar-start > .navbar-item, .navbar.is-link .navbar-start .navbar-link, .navbar.is-link .navbar-end > .navbar-item, .navbar.is-link .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-link .navbar-start > a.navbar-item:focus, .navbar.is-link .navbar-start > a.navbar-item:hover, .navbar.is-link .navbar-start > a.navbar-item.is-active, .navbar.is-link .navbar-start .navbar-link:focus, .navbar.is-link .navbar-start .navbar-link:hover, .navbar.is-link .navbar-start .navbar-link.is-active, .navbar.is-link .navbar-end > a.navbar-item:focus, .navbar.is-link .navbar-end > a.navbar-item:hover, .navbar.is-link .navbar-end > a.navbar-item.is-active, .navbar.is-link .navbar-end .navbar-link:focus, .navbar.is-link .navbar-end .navbar-link:hover, .navbar.is-link .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #3a51bb;
  }

  .navbar.is-link .navbar-start .navbar-link:after, .navbar.is-link .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-link .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-link .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-link .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #3a51bb;
  }

  .navbar.is-link .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #485fc7;
  }
}

.navbar.is-info {
  color: #fff;
  background-color: #3e8ed0;
}

.navbar.is-info .navbar-brand > .navbar-item, .navbar.is-info .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-info .navbar-brand > a.navbar-item:focus, .navbar.is-info .navbar-brand > a.navbar-item:hover, .navbar.is-info .navbar-brand > a.navbar-item.is-active, .navbar.is-info .navbar-brand .navbar-link:focus, .navbar.is-info .navbar-brand .navbar-link:hover, .navbar.is-info .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #3082c5;
}

.navbar.is-info .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-info .navbar-burger {
  color: #fff;
}

@media screen and (min-width: 1024px) {
  .navbar.is-info .navbar-start > .navbar-item, .navbar.is-info .navbar-start .navbar-link, .navbar.is-info .navbar-end > .navbar-item, .navbar.is-info .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-info .navbar-start > a.navbar-item:focus, .navbar.is-info .navbar-start > a.navbar-item:hover, .navbar.is-info .navbar-start > a.navbar-item.is-active, .navbar.is-info .navbar-start .navbar-link:focus, .navbar.is-info .navbar-start .navbar-link:hover, .navbar.is-info .navbar-start .navbar-link.is-active, .navbar.is-info .navbar-end > a.navbar-item:focus, .navbar.is-info .navbar-end > a.navbar-item:hover, .navbar.is-info .navbar-end > a.navbar-item.is-active, .navbar.is-info .navbar-end .navbar-link:focus, .navbar.is-info .navbar-end .navbar-link:hover, .navbar.is-info .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #3082c5;
  }

  .navbar.is-info .navbar-start .navbar-link:after, .navbar.is-info .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-info .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-info .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-info .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #3082c5;
  }

  .navbar.is-info .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #3e8ed0;
  }
}

.navbar.is-success {
  color: #fff;
  background-color: #48c78e;
}

.navbar.is-success .navbar-brand > .navbar-item, .navbar.is-success .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-success .navbar-brand > a.navbar-item:focus, .navbar.is-success .navbar-brand > a.navbar-item:hover, .navbar.is-success .navbar-brand > a.navbar-item.is-active, .navbar.is-success .navbar-brand .navbar-link:focus, .navbar.is-success .navbar-brand .navbar-link:hover, .navbar.is-success .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #3abb81;
}

.navbar.is-success .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-success .navbar-burger {
  color: #fff;
}

@media screen and (min-width: 1024px) {
  .navbar.is-success .navbar-start > .navbar-item, .navbar.is-success .navbar-start .navbar-link, .navbar.is-success .navbar-end > .navbar-item, .navbar.is-success .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-success .navbar-start > a.navbar-item:focus, .navbar.is-success .navbar-start > a.navbar-item:hover, .navbar.is-success .navbar-start > a.navbar-item.is-active, .navbar.is-success .navbar-start .navbar-link:focus, .navbar.is-success .navbar-start .navbar-link:hover, .navbar.is-success .navbar-start .navbar-link.is-active, .navbar.is-success .navbar-end > a.navbar-item:focus, .navbar.is-success .navbar-end > a.navbar-item:hover, .navbar.is-success .navbar-end > a.navbar-item.is-active, .navbar.is-success .navbar-end .navbar-link:focus, .navbar.is-success .navbar-end .navbar-link:hover, .navbar.is-success .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #3abb81;
  }

  .navbar.is-success .navbar-start .navbar-link:after, .navbar.is-success .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-success .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-success .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-success .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #3abb81;
  }

  .navbar.is-success .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #48c78e;
  }
}

.navbar.is-warning {
  color: rgba(0, 0, 0, .7);
  background-color: #ffe08a;
}

.navbar.is-warning .navbar-brand > .navbar-item, .navbar.is-warning .navbar-brand .navbar-link {
  color: rgba(0, 0, 0, .7);
}

.navbar.is-warning .navbar-brand > a.navbar-item:focus, .navbar.is-warning .navbar-brand > a.navbar-item:hover, .navbar.is-warning .navbar-brand > a.navbar-item.is-active, .navbar.is-warning .navbar-brand .navbar-link:focus, .navbar.is-warning .navbar-brand .navbar-link:hover, .navbar.is-warning .navbar-brand .navbar-link.is-active {
  color: rgba(0, 0, 0, .7);
  background-color: #ffd970;
}

.navbar.is-warning .navbar-brand .navbar-link:after {
  border-color: rgba(0, 0, 0, .7);
}

.navbar.is-warning .navbar-burger {
  color: rgba(0, 0, 0, .7);
}

@media screen and (min-width: 1024px) {
  .navbar.is-warning .navbar-start > .navbar-item, .navbar.is-warning .navbar-start .navbar-link, .navbar.is-warning .navbar-end > .navbar-item, .navbar.is-warning .navbar-end .navbar-link {
    color: rgba(0, 0, 0, .7);
  }

  .navbar.is-warning .navbar-start > a.navbar-item:focus, .navbar.is-warning .navbar-start > a.navbar-item:hover, .navbar.is-warning .navbar-start > a.navbar-item.is-active, .navbar.is-warning .navbar-start .navbar-link:focus, .navbar.is-warning .navbar-start .navbar-link:hover, .navbar.is-warning .navbar-start .navbar-link.is-active, .navbar.is-warning .navbar-end > a.navbar-item:focus, .navbar.is-warning .navbar-end > a.navbar-item:hover, .navbar.is-warning .navbar-end > a.navbar-item.is-active, .navbar.is-warning .navbar-end .navbar-link:focus, .navbar.is-warning .navbar-end .navbar-link:hover, .navbar.is-warning .navbar-end .navbar-link.is-active {
    color: rgba(0, 0, 0, .7);
    background-color: #ffd970;
  }

  .navbar.is-warning .navbar-start .navbar-link:after, .navbar.is-warning .navbar-end .navbar-link:after {
    border-color: rgba(0, 0, 0, .7);
  }

  .navbar.is-warning .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-warning .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-warning .navbar-item.has-dropdown.is-active .navbar-link {
    color: rgba(0, 0, 0, .7);
    background-color: #ffd970;
  }

  .navbar.is-warning .navbar-dropdown a.navbar-item.is-active {
    color: rgba(0, 0, 0, .7);
    background-color: #ffe08a;
  }
}

.navbar.is-danger {
  color: #fff;
  background-color: #f14668;
}

.navbar.is-danger .navbar-brand > .navbar-item, .navbar.is-danger .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-danger .navbar-brand > a.navbar-item:focus, .navbar.is-danger .navbar-brand > a.navbar-item:hover, .navbar.is-danger .navbar-brand > a.navbar-item.is-active, .navbar.is-danger .navbar-brand .navbar-link:focus, .navbar.is-danger .navbar-brand .navbar-link:hover, .navbar.is-danger .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #ef2e55;
}

.navbar.is-danger .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-danger .navbar-burger {
  color: #fff;
}

@media screen and (min-width: 1024px) {
  .navbar.is-danger .navbar-start > .navbar-item, .navbar.is-danger .navbar-start .navbar-link, .navbar.is-danger .navbar-end > .navbar-item, .navbar.is-danger .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-danger .navbar-start > a.navbar-item:focus, .navbar.is-danger .navbar-start > a.navbar-item:hover, .navbar.is-danger .navbar-start > a.navbar-item.is-active, .navbar.is-danger .navbar-start .navbar-link:focus, .navbar.is-danger .navbar-start .navbar-link:hover, .navbar.is-danger .navbar-start .navbar-link.is-active, .navbar.is-danger .navbar-end > a.navbar-item:focus, .navbar.is-danger .navbar-end > a.navbar-item:hover, .navbar.is-danger .navbar-end > a.navbar-item.is-active, .navbar.is-danger .navbar-end .navbar-link:focus, .navbar.is-danger .navbar-end .navbar-link:hover, .navbar.is-danger .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #ef2e55;
  }

  .navbar.is-danger .navbar-start .navbar-link:after, .navbar.is-danger .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-danger .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-danger .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-danger .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #ef2e55;
  }

  .navbar.is-danger .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #f14668;
  }
}

.navbar > .container {
  align-items: stretch;
  width: 100%;
  min-height: 3.25rem;
  display: flex;
}

.navbar.has-shadow {
  box-shadow: 0 2px #f5f5f5;
}

.navbar.is-fixed-bottom, .navbar.is-fixed-top {
  z-index: 30;
  position: fixed;
  left: 0;
  right: 0;
}

.navbar.is-fixed-bottom {
  bottom: 0;
}

.navbar.is-fixed-bottom.has-shadow {
  box-shadow: 0 -2px #f5f5f5;
}

.navbar.is-fixed-top {
  top: 0;
}

html.has-navbar-fixed-top, body.has-navbar-fixed-top {
  padding-top: 3.25rem;
}

html.has-navbar-fixed-bottom, body.has-navbar-fixed-bottom {
  padding-bottom: 3.25rem;
}

.navbar-brand, .navbar-tabs {
  flex-shrink: 0;
  align-items: stretch;
  min-height: 3.25rem;
  display: flex;
}

.navbar-brand a.navbar-item:focus, .navbar-brand a.navbar-item:hover {
  background-color: rgba(0, 0, 0, 0);
}

.navbar-tabs {
  -webkit-overflow-scrolling: touch;
  max-width: 100vw;
  overflow-x: auto;
  overflow-y: hidden;
}

.navbar-burger {
  color: #4a4a4a;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  background: none;
  border: none;
  width: 3.25rem;
  height: 3.25rem;
  margin-left: auto;
  display: block;
  position: relative;
}

.navbar-burger span {
  -webkit-transform-origin: center;
  transform-origin: center;
  background-color: currentColor;
  width: 16px;
  height: 1px;
  transition-property: background-color, opacity, -webkit-transform, transform;
  transition-duration: 86ms;
  transition-timing-function: ease-out;
  display: block;
  position: absolute;
  left: calc(50% - 8px);
}

.navbar-burger span:first-child {
  top: calc(50% - 6px);
}

.navbar-burger span:nth-child(2) {
  top: calc(50% - 1px);
}

.navbar-burger span:nth-child(3) {
  top: calc(50% + 4px);
}

.navbar-burger:hover {
  background-color: rgba(0, 0, 0, .05);
}

.navbar-burger.is-active span:first-child {
  -webkit-transform: translateY(5px)rotate(45deg);
  transform: translateY(5px)rotate(45deg);
}

.navbar-burger.is-active span:nth-child(2) {
  opacity: 0;
}

.navbar-burger.is-active span:nth-child(3) {
  -webkit-transform: translateY(-5px)rotate(-45deg);
  transform: translateY(-5px)rotate(-45deg);
}

.navbar-menu {
  display: none;
}

.navbar-item, .navbar-link {
  color: #4a4a4a;
  padding: .5rem .75rem;
  line-height: 1.5;
  display: block;
  position: relative;
}

.navbar-item .icon:only-child, .navbar-link .icon:only-child {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

a.navbar-item, .navbar-link {
  cursor: pointer;
}

a.navbar-item:focus, a.navbar-item:hover, a.navbar-item.is-active, .navbar-link:focus, .navbar-link:hover, .navbar-link.is-active {
  color: #485fc7;
  background-color: #fafafa;
}

a.navbar-item:focus-within {
  color: #485fc7;
  background-color: #fafafa;
}

.navbar-link:focus-within {
  color: #485fc7;
  background-color: #fafafa;
}

.navbar-item {
  flex-grow: 0;
  flex-shrink: 0;
}

.navbar-item img {
  max-height: 1.75rem;
}

.navbar-item.has-dropdown {
  padding: 0;
}

.navbar-item.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.navbar-item.is-tab {
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  min-height: 3.25rem;
  padding-bottom: calc(.5rem - 1px);
}

.navbar-item.is-tab:focus, .navbar-item.is-tab:hover {
  background-color: rgba(0, 0, 0, 0);
  border-bottom-color: #485fc7;
}

.navbar-item.is-tab.is-active {
  color: #485fc7;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 3px solid #485fc7;
  padding-bottom: calc(.5rem - 3px);
}

.navbar-content {
  flex-grow: 1;
  flex-shrink: 1;
}

.navbar-link:not(.is-arrowless) {
  padding-right: 2.5em;
}

.navbar-link:not(.is-arrowless):after {
  border-color: #485fc7;
  margin-top: -.375em;
  right: 1.125em;
}

.navbar-dropdown {
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: .875rem;
}

.navbar-dropdown .navbar-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.navbar-divider {
  background-color: #f5f5f5;
  border: none;
  height: 2px;
  margin: .5rem 0;
  display: none;
}

@media screen and (max-width: 1023px) {
  .navbar > .container {
    display: block;
  }

  .navbar-brand .navbar-item, .navbar-tabs .navbar-item {
    align-items: center;
    display: flex;
  }

  .navbar-link:after {
    display: none;
  }

  .navbar-menu {
    background-color: #fff;
    padding: .5rem 0;
    box-shadow: 0 8px 16px rgba(10, 10, 10, .1);
  }

  .navbar-menu.is-active {
    display: block;
  }

  .navbar.is-fixed-bottom-touch, .navbar.is-fixed-top-touch {
    z-index: 30;
    position: fixed;
    left: 0;
    right: 0;
  }

  .navbar.is-fixed-bottom-touch {
    bottom: 0;
  }

  .navbar.is-fixed-bottom-touch.has-shadow {
    box-shadow: 0 -2px 3px rgba(10, 10, 10, .1);
  }

  .navbar.is-fixed-top-touch {
    top: 0;
  }

  .navbar.is-fixed-top .navbar-menu, .navbar.is-fixed-top-touch .navbar-menu {
    -webkit-overflow-scrolling: touch;
    max-height: calc(100vh - 3.25rem);
    overflow: auto;
  }

  html.has-navbar-fixed-top-touch, body.has-navbar-fixed-top-touch {
    padding-top: 3.25rem;
  }

  html.has-navbar-fixed-bottom-touch, body.has-navbar-fixed-bottom-touch {
    padding-bottom: 3.25rem;
  }
}

@media screen and (min-width: 1024px) {
  .navbar, .navbar-menu, .navbar-start, .navbar-end {
    align-items: stretch;
    display: flex;
  }

  .navbar {
    min-height: 3.25rem;
  }

  .navbar.is-spaced {
    padding: 1rem 2rem;
  }

  .navbar.is-spaced .navbar-start, .navbar.is-spaced .navbar-end {
    align-items: center;
  }

  .navbar.is-spaced a.navbar-item, .navbar.is-spaced .navbar-link {
    border-radius: 4px;
  }

  .navbar.is-transparent a.navbar-item:focus, .navbar.is-transparent a.navbar-item:hover, .navbar.is-transparent a.navbar-item.is-active, .navbar.is-transparent .navbar-link:focus, .navbar.is-transparent .navbar-link:hover, .navbar.is-transparent .navbar-link.is-active, .navbar.is-transparent .navbar-item.has-dropdown.is-active .navbar-link, .navbar.is-transparent .navbar-item.has-dropdown.is-hoverable:focus .navbar-link, .navbar.is-transparent .navbar-item.has-dropdown.is-hoverable:focus-within .navbar-link, .navbar.is-transparent .navbar-item.has-dropdown.is-hoverable:hover .navbar-link {
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .navbar.is-transparent .navbar-dropdown a.navbar-item:focus, .navbar.is-transparent .navbar-dropdown a.navbar-item:hover {
    color: #0a0a0a;
    background-color: #f5f5f5;
  }

  .navbar.is-transparent .navbar-dropdown a.navbar-item.is-active {
    color: #485fc7;
    background-color: #f5f5f5;
  }

  .navbar-burger {
    display: none;
  }

  .navbar-item, .navbar-link {
    align-items: center;
    display: flex;
  }

  .navbar-item.has-dropdown {
    align-items: stretch;
  }

  .navbar-item.has-dropdown-up .navbar-link:after {
    -webkit-transform: rotate(135deg)translate(.25em, -.25em);
    transform: rotate(135deg)translate(.25em, -.25em);
  }

  .navbar-item.has-dropdown-up .navbar-dropdown {
    border-top: none;
    border-bottom: 2px solid #dbdbdb;
    border-radius: 6px 6px 0 0;
    top: auto;
    bottom: 100%;
    box-shadow: 0 -8px 8px rgba(10, 10, 10, .1);
  }

  .navbar-item.is-active .navbar-dropdown, .navbar-item.is-hoverable:focus .navbar-dropdown, .navbar-item.is-hoverable:focus-within .navbar-dropdown, .navbar-item.is-hoverable:hover .navbar-dropdown {
    display: block;
  }

  .navbar.is-spaced .navbar-item.is-active .navbar-dropdown, .navbar-item.is-active .navbar-dropdown.is-boxed, .navbar.is-spaced .navbar-item.is-hoverable:focus .navbar-dropdown, .navbar-item.is-hoverable:focus .navbar-dropdown.is-boxed, .navbar.is-spaced .navbar-item.is-hoverable:focus-within .navbar-dropdown, .navbar-item.is-hoverable:focus-within .navbar-dropdown.is-boxed, .navbar.is-spaced .navbar-item.is-hoverable:hover .navbar-dropdown, .navbar-item.is-hoverable:hover .navbar-dropdown.is-boxed {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .navbar-menu {
    flex-grow: 1;
    flex-shrink: 0;
  }

  .navbar-start {
    justify-content: flex-start;
    margin-right: auto;
  }

  .navbar-end {
    justify-content: flex-end;
    margin-left: auto;
  }

  .navbar-dropdown {
    z-index: 20;
    background-color: #fff;
    border-top: 2px solid #dbdbdb;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    min-width: 100%;
    font-size: .875rem;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    box-shadow: 0 8px 8px rgba(10, 10, 10, .1);
  }

  .navbar-dropdown .navbar-item {
    white-space: nowrap;
    padding: .375rem 1rem;
  }

  .navbar-dropdown a.navbar-item {
    padding-right: 3rem;
  }

  .navbar-dropdown a.navbar-item:focus, .navbar-dropdown a.navbar-item:hover {
    color: #0a0a0a;
    background-color: #f5f5f5;
  }

  .navbar-dropdown a.navbar-item.is-active {
    color: #485fc7;
    background-color: #f5f5f5;
  }

  .navbar.is-spaced .navbar-dropdown, .navbar-dropdown.is-boxed {
    opacity: 0;
    pointer-events: none;
    border-top: none;
    border-radius: 6px;
    transition-property: opacity, -webkit-transform, transform;
    transition-duration: 86ms;
    display: block;
    top: calc(100% - 4px);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    box-shadow: 0 8px 8px rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .1);
  }

  .navbar-dropdown.is-right {
    left: auto;
    right: 0;
  }

  .navbar-divider {
    display: block;
  }

  .navbar > .container .navbar-brand, .container > .navbar .navbar-brand {
    margin-left: -.75rem;
  }

  .navbar > .container .navbar-menu, .container > .navbar .navbar-menu {
    margin-right: -.75rem;
  }

  .navbar.is-fixed-bottom-desktop, .navbar.is-fixed-top-desktop {
    z-index: 30;
    position: fixed;
    left: 0;
    right: 0;
  }

  .navbar.is-fixed-bottom-desktop {
    bottom: 0;
  }

  .navbar.is-fixed-bottom-desktop.has-shadow {
    box-shadow: 0 -2px 3px rgba(10, 10, 10, .1);
  }

  .navbar.is-fixed-top-desktop {
    top: 0;
  }

  html.has-navbar-fixed-top-desktop, body.has-navbar-fixed-top-desktop {
    padding-top: 3.25rem;
  }

  html.has-navbar-fixed-bottom-desktop, body.has-navbar-fixed-bottom-desktop {
    padding-bottom: 3.25rem;
  }

  html.has-spaced-navbar-fixed-top, body.has-spaced-navbar-fixed-top {
    padding-top: 5.25rem;
  }

  html.has-spaced-navbar-fixed-bottom, body.has-spaced-navbar-fixed-bottom {
    padding-bottom: 5.25rem;
  }

  a.navbar-item.is-active, .navbar-link.is-active {
    color: #0a0a0a;
  }

  a.navbar-item.is-active:not(:focus):not(:hover), .navbar-link.is-active:not(:focus):not(:hover) {
    background-color: rgba(0, 0, 0, 0);
  }

  .navbar-item.has-dropdown:focus .navbar-link, .navbar-item.has-dropdown:hover .navbar-link, .navbar-item.has-dropdown.is-active .navbar-link {
    background-color: #fafafa;
  }
}

.hero.is-fullheight-with-navbar {
  min-height: calc(100vh - 3.25rem);
}

.pagination {
  margin: -.25rem;
  font-size: 1rem;
}

.pagination.is-small {
  font-size: .75rem;
}

.pagination.is-medium {
  font-size: 1.25rem;
}

.pagination.is-large {
  font-size: 1.5rem;
}

.pagination.is-rounded .pagination-previous, .pagination.is-rounded .pagination-next {
  border-radius: 9999px;
  padding-left: 1em;
  padding-right: 1em;
}

.pagination.is-rounded .pagination-link {
  border-radius: 9999px;
}

.pagination, .pagination-list {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis {
  text-align: center;
  justify-content: center;
  margin: .25rem;
  padding-left: .5em;
  padding-right: .5em;
  font-size: 1em;
}

.pagination-previous, .pagination-next, .pagination-link {
  color: #363636;
  border-color: #dbdbdb;
  min-width: 2.5em;
}

.pagination-previous:hover, .pagination-next:hover, .pagination-link:hover {
  color: #363636;
  border-color: #b5b5b5;
}

.pagination-previous:focus, .pagination-next:focus, .pagination-link:focus {
  border-color: #485fc7;
}

.pagination-previous:active, .pagination-next:active, .pagination-link:active {
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, .2);
}

.pagination-previous[disabled], .pagination-previous.is-disabled, .pagination-next[disabled], .pagination-next.is-disabled, .pagination-link[disabled], .pagination-link.is-disabled {
  box-shadow: none;
  color: #7a7a7a;
  opacity: .5;
  background-color: #dbdbdb;
  border-color: #dbdbdb;
}

.pagination-previous, .pagination-next {
  white-space: nowrap;
  padding-left: .75em;
  padding-right: .75em;
}

.pagination-link.is-current {
  color: #fff;
  background-color: #485fc7;
  border-color: #485fc7;
}

.pagination-ellipsis {
  color: #b5b5b5;
  pointer-events: none;
}

.pagination-list {
  flex-wrap: wrap;
}

.pagination-list li {
  list-style: none;
}

@media screen and (max-width: 768px) {
  .pagination {
    flex-wrap: wrap;
  }

  .pagination-previous, .pagination-next, .pagination-list li {
    flex-grow: 1;
    flex-shrink: 1;
  }
}

@media screen and (min-width: 769px), print {
  .pagination-list {
    flex-grow: 1;
    flex-shrink: 1;
    order: 1;
    justify-content: flex-start;
  }

  .pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis {
    margin-top: 0;
    margin-bottom: 0;
  }

  .pagination-previous {
    order: 2;
  }

  .pagination-next {
    order: 3;
  }

  .pagination {
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 0;
  }

  .pagination.is-centered .pagination-previous {
    order: 1;
  }

  .pagination.is-centered .pagination-list {
    order: 2;
    justify-content: center;
  }

  .pagination.is-centered .pagination-next {
    order: 3;
  }

  .pagination.is-right .pagination-previous {
    order: 1;
  }

  .pagination.is-right .pagination-next {
    order: 2;
  }

  .pagination.is-right .pagination-list {
    order: 3;
    justify-content: flex-end;
  }
}

.panel {
  border-radius: 6px;
  font-size: 1rem;
  box-shadow: 0 .5em 1em -.125em rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .02);
}

.panel:not(:last-child) {
  margin-bottom: 1.5rem;
}

.panel.is-white .panel-heading {
  color: #0a0a0a;
  background-color: #fff;
}

.panel.is-white .panel-tabs a.is-active {
  border-bottom-color: #fff;
}

.panel.is-white .panel-block.is-active .panel-icon {
  color: #fff;
}

.panel.is-black .panel-heading {
  color: #fff;
  background-color: #0a0a0a;
}

.panel.is-black .panel-tabs a.is-active {
  border-bottom-color: #0a0a0a;
}

.panel.is-black .panel-block.is-active .panel-icon {
  color: #0a0a0a;
}

.panel.is-light .panel-heading {
  color: rgba(0, 0, 0, .7);
  background-color: #f5f5f5;
}

.panel.is-light .panel-tabs a.is-active {
  border-bottom-color: #f5f5f5;
}

.panel.is-light .panel-block.is-active .panel-icon {
  color: #f5f5f5;
}

.panel.is-dark .panel-heading {
  color: #fff;
  background-color: #363636;
}

.panel.is-dark .panel-tabs a.is-active {
  border-bottom-color: #363636;
}

.panel.is-dark .panel-block.is-active .panel-icon {
  color: #363636;
}

.panel.is-primary .panel-heading {
  color: #fff;
  background-color: #00d1b2;
}

.panel.is-primary .panel-tabs a.is-active {
  border-bottom-color: #00d1b2;
}

.panel.is-primary .panel-block.is-active .panel-icon {
  color: #00d1b2;
}

.panel.is-link .panel-heading {
  color: #fff;
  background-color: #485fc7;
}

.panel.is-link .panel-tabs a.is-active {
  border-bottom-color: #485fc7;
}

.panel.is-link .panel-block.is-active .panel-icon {
  color: #485fc7;
}

.panel.is-info .panel-heading {
  color: #fff;
  background-color: #3e8ed0;
}

.panel.is-info .panel-tabs a.is-active {
  border-bottom-color: #3e8ed0;
}

.panel.is-info .panel-block.is-active .panel-icon {
  color: #3e8ed0;
}

.panel.is-success .panel-heading {
  color: #fff;
  background-color: #48c78e;
}

.panel.is-success .panel-tabs a.is-active {
  border-bottom-color: #48c78e;
}

.panel.is-success .panel-block.is-active .panel-icon {
  color: #48c78e;
}

.panel.is-warning .panel-heading {
  color: rgba(0, 0, 0, .7);
  background-color: #ffe08a;
}

.panel.is-warning .panel-tabs a.is-active {
  border-bottom-color: #ffe08a;
}

.panel.is-warning .panel-block.is-active .panel-icon {
  color: #ffe08a;
}

.panel.is-danger .panel-heading {
  color: #fff;
  background-color: #f14668;
}

.panel.is-danger .panel-tabs a.is-active {
  border-bottom-color: #f14668;
}

.panel.is-danger .panel-block.is-active .panel-icon {
  color: #f14668;
}

.panel-tabs:not(:last-child), .panel-block:not(:last-child) {
  border-bottom: 1px solid #ededed;
}

.panel-heading {
  color: #363636;
  background-color: #ededed;
  border-radius: 6px 6px 0 0;
  padding: .75em 1em;
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.25;
}

.panel-tabs {
  justify-content: center;
  align-items: flex-end;
  font-size: .875em;
  display: flex;
}

.panel-tabs a {
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: -1px;
  padding: .5em;
}

.panel-tabs a.is-active {
  color: #363636;
  border-bottom-color: #4a4a4a;
}

.panel-list a {
  color: #4a4a4a;
}

.panel-list a:hover {
  color: #485fc7;
}

.panel-block {
  color: #363636;
  justify-content: flex-start;
  align-items: center;
  padding: .5em .75em;
  display: flex;
}

.panel-block input[type="checkbox"] {
  margin-right: .75em;
}

.panel-block > .control {
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
}

.panel-block.is-wrapped {
  flex-wrap: wrap;
}

.panel-block.is-active {
  color: #363636;
  border-left-color: #485fc7;
}

.panel-block.is-active .panel-icon {
  color: #485fc7;
}

.panel-block:last-child {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

a.panel-block, label.panel-block {
  cursor: pointer;
}

a.panel-block:hover, label.panel-block:hover {
  background-color: #f5f5f5;
}

.panel-icon {
  text-align: center;
  vertical-align: top;
  color: #7a7a7a;
  width: 1em;
  height: 1em;
  margin-right: .75em;
  font-size: 14px;
  line-height: 1em;
  display: inline-block;
}

.panel-icon .fa {
  font-size: inherit;
  line-height: inherit;
}

.tabs {
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  justify-content: space-between;
  align-items: stretch;
  font-size: 1rem;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}

.tabs a {
  color: #4a4a4a;
  vertical-align: top;
  border-bottom: 1px solid #dbdbdb;
  justify-content: center;
  align-items: center;
  margin-bottom: -1px;
  padding: .5em 1em;
  display: flex;
}

.tabs a:hover {
  color: #363636;
  border-bottom-color: #363636;
}

.tabs li {
  display: block;
}

.tabs li.is-active a {
  color: #485fc7;
  border-bottom-color: #485fc7;
}

.tabs ul {
  border-bottom: 1px solid #dbdbdb;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.tabs ul.is-left {
  padding-right: .75em;
}

.tabs ul.is-center {
  flex: none;
  justify-content: center;
  padding-left: .75em;
  padding-right: .75em;
}

.tabs ul.is-right {
  justify-content: flex-end;
  padding-left: .75em;
}

.tabs .icon:first-child {
  margin-right: .5em;
}

.tabs .icon:last-child {
  margin-left: .5em;
}

.tabs.is-centered ul {
  justify-content: center;
}

.tabs.is-right ul {
  justify-content: flex-end;
}

.tabs.is-boxed a {
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 4px 4px 0 0;
}

.tabs.is-boxed a:hover {
  background-color: #f5f5f5;
  border-bottom-color: #dbdbdb;
}

.tabs.is-boxed li.is-active a {
  background-color: #fff;
  border-color: #dbdbdb;
  border-bottom-color: rgba(0, 0, 0, 0) !important;
}

.tabs.is-fullwidth li {
  flex-grow: 1;
  flex-shrink: 0;
}

.tabs.is-toggle a {
  border: 1px solid #dbdbdb;
  margin-bottom: 0;
  position: relative;
}

.tabs.is-toggle a:hover {
  z-index: 2;
  background-color: #f5f5f5;
  border-color: #b5b5b5;
}

.tabs.is-toggle li + li {
  margin-left: -1px;
}

.tabs.is-toggle li:first-child a {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.tabs.is-toggle li:last-child a {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.tabs.is-toggle li.is-active a {
  color: #fff;
  z-index: 1;
  background-color: #485fc7;
  border-color: #485fc7;
}

.tabs.is-toggle ul {
  border-bottom: none;
}

.tabs.is-toggle.is-toggle-rounded li:first-child a {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
  padding-left: 1.25em;
}

.tabs.is-toggle.is-toggle-rounded li:last-child a {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
  padding-right: 1.25em;
}

.tabs.is-small {
  font-size: .75rem;
}

.tabs.is-medium {
  font-size: 1.25rem;
}

.tabs.is-large {
  font-size: 1.5rem;
}

.column {
  flex: 1 1 0;
  padding: .75rem;
  display: block;
}

.columns.is-mobile > .column.is-narrow {
  width: unset;
  flex: none;
}

.columns.is-mobile > .column.is-full {
  flex: none;
  width: 100%;
}

.columns.is-mobile > .column.is-three-quarters {
  flex: none;
  width: 75%;
}

.columns.is-mobile > .column.is-two-thirds {
  flex: none;
  width: 66.6666%;
}

.columns.is-mobile > .column.is-half {
  flex: none;
  width: 50%;
}

.columns.is-mobile > .column.is-one-third {
  flex: none;
  width: 33.3333%;
}

.columns.is-mobile > .column.is-one-quarter {
  flex: none;
  width: 25%;
}

.columns.is-mobile > .column.is-one-fifth {
  flex: none;
  width: 20%;
}

.columns.is-mobile > .column.is-two-fifths {
  flex: none;
  width: 40%;
}

.columns.is-mobile > .column.is-three-fifths {
  flex: none;
  width: 60%;
}

.columns.is-mobile > .column.is-four-fifths {
  flex: none;
  width: 80%;
}

.columns.is-mobile > .column.is-offset-three-quarters {
  margin-left: 75%;
}

.columns.is-mobile > .column.is-offset-two-thirds {
  margin-left: 66.6666%;
}

.columns.is-mobile > .column.is-offset-half {
  margin-left: 50%;
}

.columns.is-mobile > .column.is-offset-one-third {
  margin-left: 33.3333%;
}

.columns.is-mobile > .column.is-offset-one-quarter {
  margin-left: 25%;
}

.columns.is-mobile > .column.is-offset-one-fifth {
  margin-left: 20%;
}

.columns.is-mobile > .column.is-offset-two-fifths {
  margin-left: 40%;
}

.columns.is-mobile > .column.is-offset-three-fifths {
  margin-left: 60%;
}

.columns.is-mobile > .column.is-offset-four-fifths {
  margin-left: 80%;
}

.columns.is-mobile > .column.is-0 {
  flex: none;
  width: 0%;
}

.columns.is-mobile > .column.is-offset-0 {
  margin-left: 0%;
}

.columns.is-mobile > .column.is-1 {
  flex: none;
  width: 8.33333%;
}

.columns.is-mobile > .column.is-offset-1 {
  margin-left: 8.33333%;
}

.columns.is-mobile > .column.is-2 {
  flex: none;
  width: 16.6667%;
}

.columns.is-mobile > .column.is-offset-2 {
  margin-left: 16.6667%;
}

.columns.is-mobile > .column.is-3 {
  flex: none;
  width: 25%;
}

.columns.is-mobile > .column.is-offset-3 {
  margin-left: 25%;
}

.columns.is-mobile > .column.is-4 {
  flex: none;
  width: 33.3333%;
}

.columns.is-mobile > .column.is-offset-4 {
  margin-left: 33.3333%;
}

.columns.is-mobile > .column.is-5 {
  flex: none;
  width: 41.6667%;
}

.columns.is-mobile > .column.is-offset-5 {
  margin-left: 41.6667%;
}

.columns.is-mobile > .column.is-6 {
  flex: none;
  width: 50%;
}

.columns.is-mobile > .column.is-offset-6 {
  margin-left: 50%;
}

.columns.is-mobile > .column.is-7 {
  flex: none;
  width: 58.3333%;
}

.columns.is-mobile > .column.is-offset-7 {
  margin-left: 58.3333%;
}

.columns.is-mobile > .column.is-8 {
  flex: none;
  width: 66.6667%;
}

.columns.is-mobile > .column.is-offset-8 {
  margin-left: 66.6667%;
}

.columns.is-mobile > .column.is-9 {
  flex: none;
  width: 75%;
}

.columns.is-mobile > .column.is-offset-9 {
  margin-left: 75%;
}

.columns.is-mobile > .column.is-10 {
  flex: none;
  width: 83.3333%;
}

.columns.is-mobile > .column.is-offset-10 {
  margin-left: 83.3333%;
}

.columns.is-mobile > .column.is-11 {
  flex: none;
  width: 91.6667%;
}

.columns.is-mobile > .column.is-offset-11 {
  margin-left: 91.6667%;
}

.columns.is-mobile > .column.is-12 {
  flex: none;
  width: 100%;
}

.columns.is-mobile > .column.is-offset-12 {
  margin-left: 100%;
}

@media screen and (max-width: 768px) {
  .column.is-narrow-mobile {
    width: unset;
    flex: none;
  }

  .column.is-full-mobile {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-mobile {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-mobile {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-mobile {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-mobile {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-mobile {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-mobile {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-mobile {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-mobile {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-mobile {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-mobile {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-mobile {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-mobile {
    margin-left: 50%;
  }

  .column.is-offset-one-third-mobile {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-mobile {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-mobile {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-mobile {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-mobile {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-mobile {
    margin-left: 80%;
  }

  .column.is-0-mobile {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-mobile {
    margin-left: 0%;
  }

  .column.is-1-mobile {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-mobile {
    margin-left: 8.33333%;
  }

  .column.is-2-mobile {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-mobile {
    margin-left: 16.6667%;
  }

  .column.is-3-mobile {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-mobile {
    margin-left: 25%;
  }

  .column.is-4-mobile {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-mobile {
    margin-left: 33.3333%;
  }

  .column.is-5-mobile {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-mobile {
    margin-left: 41.6667%;
  }

  .column.is-6-mobile {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-mobile {
    margin-left: 50%;
  }

  .column.is-7-mobile {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-mobile {
    margin-left: 58.3333%;
  }

  .column.is-8-mobile {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-mobile {
    margin-left: 66.6667%;
  }

  .column.is-9-mobile {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-mobile {
    margin-left: 75%;
  }

  .column.is-10-mobile {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-mobile {
    margin-left: 83.3333%;
  }

  .column.is-11-mobile {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-mobile {
    margin-left: 91.6667%;
  }

  .column.is-12-mobile {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-mobile {
    margin-left: 100%;
  }
}

@media screen and (min-width: 769px), print {
  .column.is-narrow, .column.is-narrow-tablet {
    width: unset;
    flex: none;
  }

  .column.is-full, .column.is-full-tablet {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters, .column.is-three-quarters-tablet {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds, .column.is-two-thirds-tablet {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half, .column.is-half-tablet {
    flex: none;
    width: 50%;
  }

  .column.is-one-third, .column.is-one-third-tablet {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter, .column.is-one-quarter-tablet {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth, .column.is-one-fifth-tablet {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths, .column.is-two-fifths-tablet {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths, .column.is-three-fifths-tablet {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths, .column.is-four-fifths-tablet {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters, .column.is-offset-three-quarters-tablet {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds, .column.is-offset-two-thirds-tablet {
    margin-left: 66.6666%;
  }

  .column.is-offset-half, .column.is-offset-half-tablet {
    margin-left: 50%;
  }

  .column.is-offset-one-third, .column.is-offset-one-third-tablet {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter, .column.is-offset-one-quarter-tablet {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth, .column.is-offset-one-fifth-tablet {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths, .column.is-offset-two-fifths-tablet {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths, .column.is-offset-three-fifths-tablet {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths, .column.is-offset-four-fifths-tablet {
    margin-left: 80%;
  }

  .column.is-0, .column.is-0-tablet {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0, .column.is-offset-0-tablet {
    margin-left: 0%;
  }

  .column.is-1, .column.is-1-tablet {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1, .column.is-offset-1-tablet {
    margin-left: 8.33333%;
  }

  .column.is-2, .column.is-2-tablet {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2, .column.is-offset-2-tablet {
    margin-left: 16.6667%;
  }

  .column.is-3, .column.is-3-tablet {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3, .column.is-offset-3-tablet {
    margin-left: 25%;
  }

  .column.is-4, .column.is-4-tablet {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4, .column.is-offset-4-tablet {
    margin-left: 33.3333%;
  }

  .column.is-5, .column.is-5-tablet {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5, .column.is-offset-5-tablet {
    margin-left: 41.6667%;
  }

  .column.is-6, .column.is-6-tablet {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6, .column.is-offset-6-tablet {
    margin-left: 50%;
  }

  .column.is-7, .column.is-7-tablet {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7, .column.is-offset-7-tablet {
    margin-left: 58.3333%;
  }

  .column.is-8, .column.is-8-tablet {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8, .column.is-offset-8-tablet {
    margin-left: 66.6667%;
  }

  .column.is-9, .column.is-9-tablet {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9, .column.is-offset-9-tablet {
    margin-left: 75%;
  }

  .column.is-10, .column.is-10-tablet {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10, .column.is-offset-10-tablet {
    margin-left: 83.3333%;
  }

  .column.is-11, .column.is-11-tablet {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11, .column.is-offset-11-tablet {
    margin-left: 91.6667%;
  }

  .column.is-12, .column.is-12-tablet {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12, .column.is-offset-12-tablet {
    margin-left: 100%;
  }
}

@media screen and (max-width: 1023px) {
  .column.is-narrow-touch {
    width: unset;
    flex: none;
  }

  .column.is-full-touch {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-touch {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-touch {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-touch {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-touch {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-touch {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-touch {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-touch {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-touch {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-touch {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-touch {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-touch {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-touch {
    margin-left: 50%;
  }

  .column.is-offset-one-third-touch {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-touch {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-touch {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-touch {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-touch {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-touch {
    margin-left: 80%;
  }

  .column.is-0-touch {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-touch {
    margin-left: 0%;
  }

  .column.is-1-touch {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-touch {
    margin-left: 8.33333%;
  }

  .column.is-2-touch {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-touch {
    margin-left: 16.6667%;
  }

  .column.is-3-touch {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-touch {
    margin-left: 25%;
  }

  .column.is-4-touch {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-touch {
    margin-left: 33.3333%;
  }

  .column.is-5-touch {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-touch {
    margin-left: 41.6667%;
  }

  .column.is-6-touch {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-touch {
    margin-left: 50%;
  }

  .column.is-7-touch {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-touch {
    margin-left: 58.3333%;
  }

  .column.is-8-touch {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-touch {
    margin-left: 66.6667%;
  }

  .column.is-9-touch {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-touch {
    margin-left: 75%;
  }

  .column.is-10-touch {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-touch {
    margin-left: 83.3333%;
  }

  .column.is-11-touch {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-touch {
    margin-left: 91.6667%;
  }

  .column.is-12-touch {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-touch {
    margin-left: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .column.is-narrow-desktop {
    width: unset;
    flex: none;
  }

  .column.is-full-desktop {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-desktop {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-desktop {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-desktop {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-desktop {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-desktop {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-desktop {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-desktop {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-desktop {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-desktop {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-desktop {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-desktop {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-desktop {
    margin-left: 50%;
  }

  .column.is-offset-one-third-desktop {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-desktop {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-desktop {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-desktop {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-desktop {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-desktop {
    margin-left: 80%;
  }

  .column.is-0-desktop {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-desktop {
    margin-left: 0%;
  }

  .column.is-1-desktop {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-desktop {
    margin-left: 8.33333%;
  }

  .column.is-2-desktop {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-desktop {
    margin-left: 16.6667%;
  }

  .column.is-3-desktop {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-desktop {
    margin-left: 25%;
  }

  .column.is-4-desktop {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-desktop {
    margin-left: 33.3333%;
  }

  .column.is-5-desktop {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-desktop {
    margin-left: 41.6667%;
  }

  .column.is-6-desktop {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-desktop {
    margin-left: 50%;
  }

  .column.is-7-desktop {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-desktop {
    margin-left: 58.3333%;
  }

  .column.is-8-desktop {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-desktop {
    margin-left: 66.6667%;
  }

  .column.is-9-desktop {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-desktop {
    margin-left: 75%;
  }

  .column.is-10-desktop {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-desktop {
    margin-left: 83.3333%;
  }

  .column.is-11-desktop {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-desktop {
    margin-left: 91.6667%;
  }

  .column.is-12-desktop {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-desktop {
    margin-left: 100%;
  }
}

@media screen and (min-width: 1216px) {
  .column.is-narrow-widescreen {
    width: unset;
    flex: none;
  }

  .column.is-full-widescreen {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-widescreen {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-widescreen {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-widescreen {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-widescreen {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-widescreen {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-widescreen {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-widescreen {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-widescreen {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-widescreen {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-widescreen {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-widescreen {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-widescreen {
    margin-left: 50%;
  }

  .column.is-offset-one-third-widescreen {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-widescreen {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-widescreen {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-widescreen {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-widescreen {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-widescreen {
    margin-left: 80%;
  }

  .column.is-0-widescreen {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-widescreen {
    margin-left: 0%;
  }

  .column.is-1-widescreen {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-widescreen {
    margin-left: 8.33333%;
  }

  .column.is-2-widescreen {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-widescreen {
    margin-left: 16.6667%;
  }

  .column.is-3-widescreen {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-widescreen {
    margin-left: 25%;
  }

  .column.is-4-widescreen {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-widescreen {
    margin-left: 33.3333%;
  }

  .column.is-5-widescreen {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-widescreen {
    margin-left: 41.6667%;
  }

  .column.is-6-widescreen {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-widescreen {
    margin-left: 50%;
  }

  .column.is-7-widescreen {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-widescreen {
    margin-left: 58.3333%;
  }

  .column.is-8-widescreen {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-widescreen {
    margin-left: 66.6667%;
  }

  .column.is-9-widescreen {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-widescreen {
    margin-left: 75%;
  }

  .column.is-10-widescreen {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-widescreen {
    margin-left: 83.3333%;
  }

  .column.is-11-widescreen {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-widescreen {
    margin-left: 91.6667%;
  }

  .column.is-12-widescreen {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-widescreen {
    margin-left: 100%;
  }
}

@media screen and (min-width: 1408px) {
  .column.is-narrow-fullhd {
    width: unset;
    flex: none;
  }

  .column.is-full-fullhd {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-fullhd {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-fullhd {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-fullhd {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-fullhd {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-fullhd {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-fullhd {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-fullhd {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-fullhd {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-fullhd {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-fullhd {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-fullhd {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-fullhd {
    margin-left: 50%;
  }

  .column.is-offset-one-third-fullhd {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-fullhd {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-fullhd {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-fullhd {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-fullhd {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-fullhd {
    margin-left: 80%;
  }

  .column.is-0-fullhd {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-fullhd {
    margin-left: 0%;
  }

  .column.is-1-fullhd {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-fullhd {
    margin-left: 8.33333%;
  }

  .column.is-2-fullhd {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-fullhd {
    margin-left: 16.6667%;
  }

  .column.is-3-fullhd {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-fullhd {
    margin-left: 25%;
  }

  .column.is-4-fullhd {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-fullhd {
    margin-left: 33.3333%;
  }

  .column.is-5-fullhd {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-fullhd {
    margin-left: 41.6667%;
  }

  .column.is-6-fullhd {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-fullhd {
    margin-left: 50%;
  }

  .column.is-7-fullhd {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-fullhd {
    margin-left: 58.3333%;
  }

  .column.is-8-fullhd {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-fullhd {
    margin-left: 66.6667%;
  }

  .column.is-9-fullhd {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-fullhd {
    margin-left: 75%;
  }

  .column.is-10-fullhd {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-fullhd {
    margin-left: 83.3333%;
  }

  .column.is-11-fullhd {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-fullhd {
    margin-left: 91.6667%;
  }

  .column.is-12-fullhd {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-fullhd {
    margin-left: 100%;
  }
}

.columns {
  margin-top: -.75rem;
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.columns:last-child {
  margin-bottom: -.75rem;
}

.columns:not(:last-child) {
  margin-bottom: .75rem;
}

.columns.is-centered {
  justify-content: center;
}

.columns.is-gapless {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.columns.is-gapless > .column {
  margin: 0;
  padding: 0 !important;
}

.columns.is-gapless:not(:last-child) {
  margin-bottom: 1.5rem;
}

.columns.is-gapless:last-child {
  margin-bottom: 0;
}

.columns.is-mobile {
  display: flex;
}

.columns.is-multiline {
  flex-wrap: wrap;
}

.columns.is-vcentered {
  align-items: center;
}

@media screen and (min-width: 769px), print {
  .columns:not(.is-desktop) {
    display: flex;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-desktop {
    display: flex;
  }
}

.columns.is-variable {
  --columnGap: .75rem;
  margin-left: calc(-1 * var(--columnGap));
  margin-right: calc(-1 * var(--columnGap));
}

.columns.is-variable > .column {
  padding-left: var(--columnGap);
  padding-right: var(--columnGap);
}

.columns.is-variable.is-0 {
  --columnGap: 0rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-0-mobile {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-0-tablet {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-0-tablet-only {
    --columnGap: 0rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-0-touch {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-0-desktop {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-0-desktop-only {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-0-widescreen {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-0-widescreen-only {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-0-fullhd {
    --columnGap: 0rem;
  }
}

.columns.is-variable.is-1 {
  --columnGap: .25rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-1-mobile {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-1-tablet {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-1-tablet-only {
    --columnGap: .25rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-1-touch {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-1-desktop {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-1-desktop-only {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-1-widescreen {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-1-widescreen-only {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-1-fullhd {
    --columnGap: .25rem;
  }
}

.columns.is-variable.is-2 {
  --columnGap: .5rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-2-mobile {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-2-tablet {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-2-tablet-only {
    --columnGap: .5rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-2-touch {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-2-desktop {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-2-desktop-only {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-2-widescreen {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-2-widescreen-only {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-2-fullhd {
    --columnGap: .5rem;
  }
}

.columns.is-variable.is-3 {
  --columnGap: .75rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-3-mobile {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-3-tablet {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-3-tablet-only {
    --columnGap: .75rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-3-touch {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-3-desktop {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-3-desktop-only {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-3-widescreen {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-3-widescreen-only {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-3-fullhd {
    --columnGap: .75rem;
  }
}

.columns.is-variable.is-4 {
  --columnGap: 1rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-4-mobile {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-4-tablet {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-4-tablet-only {
    --columnGap: 1rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-4-touch {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-4-desktop {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-4-desktop-only {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-4-widescreen {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-4-widescreen-only {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-4-fullhd {
    --columnGap: 1rem;
  }
}

.columns.is-variable.is-5 {
  --columnGap: 1.25rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-5-mobile {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-5-tablet {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-5-tablet-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-5-touch {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-5-desktop {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-5-desktop-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-5-widescreen {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-5-widescreen-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-5-fullhd {
    --columnGap: 1.25rem;
  }
}

.columns.is-variable.is-6 {
  --columnGap: 1.5rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-6-mobile {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-6-tablet {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-6-tablet-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-6-touch {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-6-desktop {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-6-desktop-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-6-widescreen {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-6-widescreen-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-6-fullhd {
    --columnGap: 1.5rem;
  }
}

.columns.is-variable.is-7 {
  --columnGap: 1.75rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-7-mobile {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-7-tablet {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-7-tablet-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-7-touch {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-7-desktop {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-7-desktop-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-7-widescreen {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-7-widescreen-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-7-fullhd {
    --columnGap: 1.75rem;
  }
}

.columns.is-variable.is-8 {
  --columnGap: 2rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-8-mobile {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-8-tablet {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-8-tablet-only {
    --columnGap: 2rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-8-touch {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-8-desktop {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-8-desktop-only {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-8-widescreen {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-8-widescreen-only {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-8-fullhd {
    --columnGap: 2rem;
  }
}

.tile {
  flex: 1 1 0;
  align-items: stretch;
  min-height: -webkit-min-content;
  min-height: min-content;
  display: block;
}

.tile.is-ancestor {
  margin-top: -.75rem;
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.tile.is-ancestor:last-child {
  margin-bottom: -.75rem;
}

.tile.is-ancestor:not(:last-child) {
  margin-bottom: .75rem;
}

.tile.is-child {
  margin: 0 !important;
}

.tile.is-parent {
  padding: .75rem;
}

.tile.is-vertical {
  flex-direction: column;
}

.tile.is-vertical > .tile.is-child:not(:last-child) {
  margin-bottom: 1.5rem !important;
}

@media screen and (min-width: 769px), print {
  .tile:not(.is-child) {
    display: flex;
  }

  .tile.is-1 {
    flex: none;
    width: 8.33333%;
  }

  .tile.is-2 {
    flex: none;
    width: 16.6667%;
  }

  .tile.is-3 {
    flex: none;
    width: 25%;
  }

  .tile.is-4 {
    flex: none;
    width: 33.3333%;
  }

  .tile.is-5 {
    flex: none;
    width: 41.6667%;
  }

  .tile.is-6 {
    flex: none;
    width: 50%;
  }

  .tile.is-7 {
    flex: none;
    width: 58.3333%;
  }

  .tile.is-8 {
    flex: none;
    width: 66.6667%;
  }

  .tile.is-9 {
    flex: none;
    width: 75%;
  }

  .tile.is-10 {
    flex: none;
    width: 83.3333%;
  }

  .tile.is-11 {
    flex: none;
    width: 91.6667%;
  }

  .tile.is-12 {
    flex: none;
    width: 100%;
  }
}

.has-text-white {
  color: #fff !important;
}

a.has-text-white:hover, a.has-text-white:focus {
  color: #e6e6e6 !important;
}

.has-background-white {
  background-color: #fff !important;
}

.has-text-black {
  color: #0a0a0a !important;
}

a.has-text-black:hover, a.has-text-black:focus {
  color: #000 !important;
}

.has-background-black {
  background-color: #0a0a0a !important;
}

.has-text-light {
  color: #f5f5f5 !important;
}

a.has-text-light:hover, a.has-text-light:focus {
  color: #dbdbdb !important;
}

.has-background-light {
  background-color: #f5f5f5 !important;
}

.has-text-dark {
  color: #363636 !important;
}

a.has-text-dark:hover, a.has-text-dark:focus {
  color: #1c1c1c !important;
}

.has-background-dark {
  background-color: #363636 !important;
}

.has-text-primary {
  color: #00d1b2 !important;
}

a.has-text-primary:hover, a.has-text-primary:focus {
  color: #009e86 !important;
}

.has-background-primary {
  background-color: #00d1b2 !important;
}

.has-text-primary-light {
  color: #ebfffc !important;
}

a.has-text-primary-light:hover, a.has-text-primary-light:focus {
  color: #b8fff4 !important;
}

.has-background-primary-light {
  background-color: #ebfffc !important;
}

.has-text-primary-dark {
  color: #00947e !important;
}

a.has-text-primary-dark:hover, a.has-text-primary-dark:focus {
  color: #00c7a9 !important;
}

.has-background-primary-dark {
  background-color: #00947e !important;
}

.has-text-link {
  color: #485fc7 !important;
}

a.has-text-link:hover, a.has-text-link:focus {
  color: #3449a8 !important;
}

.has-background-link {
  background-color: #485fc7 !important;
}

.has-text-link-light {
  color: #eff1fa !important;
}

a.has-text-link-light:hover, a.has-text-link-light:focus {
  color: #c8cfee !important;
}

.has-background-link-light {
  background-color: #eff1fa !important;
}

.has-text-link-dark {
  color: #3850b7 !important;
}

a.has-text-link-dark:hover, a.has-text-link-dark:focus {
  color: #576dcb !important;
}

.has-background-link-dark {
  background-color: #3850b7 !important;
}

.has-text-info {
  color: #3e8ed0 !important;
}

a.has-text-info:hover, a.has-text-info:focus {
  color: #2b74b1 !important;
}

.has-background-info {
  background-color: #3e8ed0 !important;
}

.has-text-info-light {
  color: #eff5fb !important;
}

a.has-text-info-light:hover, a.has-text-info-light:focus {
  color: #c6ddf1 !important;
}

.has-background-info-light {
  background-color: #eff5fb !important;
}

.has-text-info-dark {
  color: #296fa8 !important;
}

a.has-text-info-dark:hover, a.has-text-info-dark:focus {
  color: #368ace !important;
}

.has-background-info-dark {
  background-color: #296fa8 !important;
}

.has-text-success {
  color: #48c78e !important;
}

a.has-text-success:hover, a.has-text-success:focus {
  color: #34a873 !important;
}

.has-background-success {
  background-color: #48c78e !important;
}

.has-text-success-light {
  color: #effaf5 !important;
}

a.has-text-success-light:hover, a.has-text-success-light:focus {
  color: #c8eedd !important;
}

.has-background-success-light {
  background-color: #effaf5 !important;
}

.has-text-success-dark {
  color: #257953 !important;
}

a.has-text-success-dark:hover, a.has-text-success-dark:focus {
  color: #31a06e !important;
}

.has-background-success-dark {
  background-color: #257953 !important;
}

.has-text-warning {
  color: #ffe08a !important;
}

a.has-text-warning:hover, a.has-text-warning:focus {
  color: #ffd257 !important;
}

.has-background-warning {
  background-color: #ffe08a !important;
}

.has-text-warning-light {
  color: #fffaeb !important;
}

a.has-text-warning-light:hover, a.has-text-warning-light:focus {
  color: #ffecb8 !important;
}

.has-background-warning-light {
  background-color: #fffaeb !important;
}

.has-text-warning-dark {
  color: #946c00 !important;
}

a.has-text-warning-dark:hover, a.has-text-warning-dark:focus {
  color: #c79200 !important;
}

.has-background-warning-dark {
  background-color: #946c00 !important;
}

.has-text-danger {
  color: #f14668 !important;
}

a.has-text-danger:hover, a.has-text-danger:focus {
  color: #ee1742 !important;
}

.has-background-danger {
  background-color: #f14668 !important;
}

.has-text-danger-light {
  color: #feecf0 !important;
}

a.has-text-danger-light:hover, a.has-text-danger-light:focus {
  color: #fabdc9 !important;
}

.has-background-danger-light {
  background-color: #feecf0 !important;
}

.has-text-danger-dark {
  color: #cc0f35 !important;
}

a.has-text-danger-dark:hover, a.has-text-danger-dark:focus {
  color: #ee2049 !important;
}

.has-background-danger-dark {
  background-color: #cc0f35 !important;
}

.has-text-black-bis {
  color: #121212 !important;
}

.has-background-black-bis {
  background-color: #121212 !important;
}

.has-text-black-ter {
  color: #242424 !important;
}

.has-background-black-ter {
  background-color: #242424 !important;
}

.has-text-grey-darker {
  color: #363636 !important;
}

.has-background-grey-darker {
  background-color: #363636 !important;
}

.has-text-grey-dark {
  color: #4a4a4a !important;
}

.has-background-grey-dark {
  background-color: #4a4a4a !important;
}

.has-text-grey {
  color: #7a7a7a !important;
}

.has-background-grey {
  background-color: #7a7a7a !important;
}

.has-text-grey-light {
  color: #b5b5b5 !important;
}

.has-background-grey-light {
  background-color: #b5b5b5 !important;
}

.has-text-grey-lighter {
  color: #dbdbdb !important;
}

.has-background-grey-lighter {
  background-color: #dbdbdb !important;
}

.has-text-white-ter {
  color: #f5f5f5 !important;
}

.has-background-white-ter {
  background-color: #f5f5f5 !important;
}

.has-text-white-bis {
  color: #fafafa !important;
}

.has-background-white-bis {
  background-color: #fafafa !important;
}

.is-flex-direction-row {
  flex-direction: row !important;
}

.is-flex-direction-row-reverse {
  flex-direction: row-reverse !important;
}

.is-flex-direction-column {
  flex-direction: column !important;
}

.is-flex-direction-column-reverse {
  flex-direction: column-reverse !important;
}

.is-flex-wrap-nowrap {
  flex-wrap: nowrap !important;
}

.is-flex-wrap-wrap {
  flex-wrap: wrap !important;
}

.is-flex-wrap-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.is-justify-content-flex-start {
  justify-content: flex-start !important;
}

.is-justify-content-flex-end {
  justify-content: flex-end !important;
}

.is-justify-content-center {
  justify-content: center !important;
}

.is-justify-content-space-between {
  justify-content: space-between !important;
}

.is-justify-content-space-around {
  justify-content: space-around !important;
}

.is-justify-content-space-evenly {
  justify-content: space-evenly !important;
}

.is-justify-content-start {
  justify-content: start !important;
}

.is-justify-content-end {
  justify-content: end !important;
}

.is-justify-content-left {
  justify-content: left !important;
}

.is-justify-content-right {
  justify-content: right !important;
}

.is-align-content-flex-start {
  align-content: flex-start !important;
}

.is-align-content-flex-end {
  align-content: flex-end !important;
}

.is-align-content-center {
  align-content: center !important;
}

.is-align-content-space-between {
  align-content: space-between !important;
}

.is-align-content-space-around {
  align-content: space-around !important;
}

.is-align-content-space-evenly {
  align-content: space-evenly !important;
}

.is-align-content-stretch {
  align-content: stretch !important;
}

.is-align-content-start {
  align-content: start !important;
}

.is-align-content-end {
  align-content: end !important;
}

.is-align-content-baseline {
  align-content: baseline !important;
}

.is-align-items-stretch {
  align-items: stretch !important;
}

.is-align-items-flex-start {
  align-items: flex-start !important;
}

.is-align-items-flex-end {
  align-items: flex-end !important;
}

.is-align-items-center {
  align-items: center !important;
}

.is-align-items-baseline {
  align-items: baseline !important;
}

.is-align-items-start {
  align-items: start !important;
}

.is-align-items-end {
  align-items: end !important;
}

.is-align-items-self-start {
  align-items: self-start !important;
}

.is-align-items-self-end {
  align-items: self-end !important;
}

.is-align-self-auto {
  align-self: auto !important;
}

.is-align-self-flex-start {
  align-self: flex-start !important;
}

.is-align-self-flex-end {
  align-self: flex-end !important;
}

.is-align-self-center {
  align-self: center !important;
}

.is-align-self-baseline {
  align-self: baseline !important;
}

.is-align-self-stretch {
  align-self: stretch !important;
}

.is-flex-grow-0 {
  flex-grow: 0 !important;
}

.is-flex-grow-1 {
  flex-grow: 1 !important;
}

.is-flex-grow-2 {
  flex-grow: 2 !important;
}

.is-flex-grow-3 {
  flex-grow: 3 !important;
}

.is-flex-grow-4 {
  flex-grow: 4 !important;
}

.is-flex-grow-5 {
  flex-grow: 5 !important;
}

.is-flex-shrink-0 {
  flex-shrink: 0 !important;
}

.is-flex-shrink-1 {
  flex-shrink: 1 !important;
}

.is-flex-shrink-2 {
  flex-shrink: 2 !important;
}

.is-flex-shrink-3 {
  flex-shrink: 3 !important;
}

.is-flex-shrink-4 {
  flex-shrink: 4 !important;
}

.is-flex-shrink-5 {
  flex-shrink: 5 !important;
}

.is-clearfix:after {
  clear: both;
  content: " ";
  display: table;
}

.is-pulled-left {
  float: left !important;
}

.is-pulled-right {
  float: right !important;
}

.is-radiusless {
  border-radius: 0 !important;
}

.is-shadowless {
  box-shadow: none !important;
}

.is-clickable {
  cursor: pointer !important;
  pointer-events: all !important;
}

.is-clipped {
  overflow: hidden !important;
}

.is-relative {
  position: relative !important;
}

.is-marginless {
  margin: 0 !important;
}

.is-paddingless {
  padding: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.mx-3 {
  margin-left: .75rem !important;
  margin-right: .75rem !important;
}

.my-3 {
  margin-top: .75rem !important;
  margin-bottom: .75rem !important;
}

.mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.mx-5 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.my-5 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.m-6 {
  margin: 3rem !important;
}

.mt-6 {
  margin-top: 3rem !important;
}

.mr-6 {
  margin-right: 3rem !important;
}

.mb-6 {
  margin-bottom: 3rem !important;
}

.ml-6 {
  margin-left: 3rem !important;
}

.mx-6 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.my-6 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.px-3 {
  padding-left: .75rem !important;
  padding-right: .75rem !important;
}

.py-3 {
  padding-top: .75rem !important;
  padding-bottom: .75rem !important;
}

.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.px-5 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.py-5 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.p-6 {
  padding: 3rem !important;
}

.pt-6 {
  padding-top: 3rem !important;
}

.pr-6 {
  padding-right: 3rem !important;
}

.pb-6 {
  padding-bottom: 3rem !important;
}

.pl-6 {
  padding-left: 3rem !important;
}

.px-6 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.py-6 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.p-auto {
  padding: auto !important;
}

.pt-auto {
  padding-top: auto !important;
}

.pr-auto {
  padding-right: auto !important;
}

.pb-auto {
  padding-bottom: auto !important;
}

.pl-auto {
  padding-left: auto !important;
}

.px-auto {
  padding-left: auto !important;
  padding-right: auto !important;
}

.py-auto {
  padding-top: auto !important;
  padding-bottom: auto !important;
}

.is-size-1 {
  font-size: 3rem !important;
}

.is-size-2 {
  font-size: 2.5rem !important;
}

.is-size-3 {
  font-size: 2rem !important;
}

.is-size-4 {
  font-size: 1.5rem !important;
}

.is-size-5 {
  font-size: 1.25rem !important;
}

.is-size-6 {
  font-size: 1rem !important;
}

.is-size-7 {
  font-size: .75rem !important;
}

@media screen and (max-width: 768px) {
  .is-size-1-mobile {
    font-size: 3rem !important;
  }

  .is-size-2-mobile {
    font-size: 2.5rem !important;
  }

  .is-size-3-mobile {
    font-size: 2rem !important;
  }

  .is-size-4-mobile {
    font-size: 1.5rem !important;
  }

  .is-size-5-mobile {
    font-size: 1.25rem !important;
  }

  .is-size-6-mobile {
    font-size: 1rem !important;
  }

  .is-size-7-mobile {
    font-size: .75rem !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-size-1-tablet {
    font-size: 3rem !important;
  }

  .is-size-2-tablet {
    font-size: 2.5rem !important;
  }

  .is-size-3-tablet {
    font-size: 2rem !important;
  }

  .is-size-4-tablet {
    font-size: 1.5rem !important;
  }

  .is-size-5-tablet {
    font-size: 1.25rem !important;
  }

  .is-size-6-tablet {
    font-size: 1rem !important;
  }

  .is-size-7-tablet {
    font-size: .75rem !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-size-1-touch {
    font-size: 3rem !important;
  }

  .is-size-2-touch {
    font-size: 2.5rem !important;
  }

  .is-size-3-touch {
    font-size: 2rem !important;
  }

  .is-size-4-touch {
    font-size: 1.5rem !important;
  }

  .is-size-5-touch {
    font-size: 1.25rem !important;
  }

  .is-size-6-touch {
    font-size: 1rem !important;
  }

  .is-size-7-touch {
    font-size: .75rem !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-size-1-desktop {
    font-size: 3rem !important;
  }

  .is-size-2-desktop {
    font-size: 2.5rem !important;
  }

  .is-size-3-desktop {
    font-size: 2rem !important;
  }

  .is-size-4-desktop {
    font-size: 1.5rem !important;
  }

  .is-size-5-desktop {
    font-size: 1.25rem !important;
  }

  .is-size-6-desktop {
    font-size: 1rem !important;
  }

  .is-size-7-desktop {
    font-size: .75rem !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-size-1-widescreen {
    font-size: 3rem !important;
  }

  .is-size-2-widescreen {
    font-size: 2.5rem !important;
  }

  .is-size-3-widescreen {
    font-size: 2rem !important;
  }

  .is-size-4-widescreen {
    font-size: 1.5rem !important;
  }

  .is-size-5-widescreen {
    font-size: 1.25rem !important;
  }

  .is-size-6-widescreen {
    font-size: 1rem !important;
  }

  .is-size-7-widescreen {
    font-size: .75rem !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-size-1-fullhd {
    font-size: 3rem !important;
  }

  .is-size-2-fullhd {
    font-size: 2.5rem !important;
  }

  .is-size-3-fullhd {
    font-size: 2rem !important;
  }

  .is-size-4-fullhd {
    font-size: 1.5rem !important;
  }

  .is-size-5-fullhd {
    font-size: 1.25rem !important;
  }

  .is-size-6-fullhd {
    font-size: 1rem !important;
  }

  .is-size-7-fullhd {
    font-size: .75rem !important;
  }
}

.has-text-centered {
  text-align: center !important;
}

.has-text-justified {
  text-align: justify !important;
}

.has-text-left {
  text-align: left !important;
}

.has-text-right {
  text-align: right !important;
}

@media screen and (max-width: 768px) {
  .has-text-centered-mobile {
    text-align: center !important;
  }
}

@media screen and (min-width: 769px), print {
  .has-text-centered-tablet {
    text-align: center !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-text-centered-tablet-only {
    text-align: center !important;
  }
}

@media screen and (max-width: 1023px) {
  .has-text-centered-touch {
    text-align: center !important;
  }
}

@media screen and (min-width: 1024px) {
  .has-text-centered-desktop {
    text-align: center !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .has-text-centered-desktop-only {
    text-align: center !important;
  }
}

@media screen and (min-width: 1216px) {
  .has-text-centered-widescreen {
    text-align: center !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .has-text-centered-widescreen-only {
    text-align: center !important;
  }
}

@media screen and (min-width: 1408px) {
  .has-text-centered-fullhd {
    text-align: center !important;
  }
}

@media screen and (max-width: 768px) {
  .has-text-justified-mobile {
    text-align: justify !important;
  }
}

@media screen and (min-width: 769px), print {
  .has-text-justified-tablet {
    text-align: justify !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-text-justified-tablet-only {
    text-align: justify !important;
  }
}

@media screen and (max-width: 1023px) {
  .has-text-justified-touch {
    text-align: justify !important;
  }
}

@media screen and (min-width: 1024px) {
  .has-text-justified-desktop {
    text-align: justify !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .has-text-justified-desktop-only {
    text-align: justify !important;
  }
}

@media screen and (min-width: 1216px) {
  .has-text-justified-widescreen {
    text-align: justify !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .has-text-justified-widescreen-only {
    text-align: justify !important;
  }
}

@media screen and (min-width: 1408px) {
  .has-text-justified-fullhd {
    text-align: justify !important;
  }
}

@media screen and (max-width: 768px) {
  .has-text-left-mobile {
    text-align: left !important;
  }
}

@media screen and (min-width: 769px), print {
  .has-text-left-tablet {
    text-align: left !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-text-left-tablet-only {
    text-align: left !important;
  }
}

@media screen and (max-width: 1023px) {
  .has-text-left-touch {
    text-align: left !important;
  }
}

@media screen and (min-width: 1024px) {
  .has-text-left-desktop {
    text-align: left !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .has-text-left-desktop-only {
    text-align: left !important;
  }
}

@media screen and (min-width: 1216px) {
  .has-text-left-widescreen {
    text-align: left !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .has-text-left-widescreen-only {
    text-align: left !important;
  }
}

@media screen and (min-width: 1408px) {
  .has-text-left-fullhd {
    text-align: left !important;
  }
}

@media screen and (max-width: 768px) {
  .has-text-right-mobile {
    text-align: right !important;
  }
}

@media screen and (min-width: 769px), print {
  .has-text-right-tablet {
    text-align: right !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-text-right-tablet-only {
    text-align: right !important;
  }
}

@media screen and (max-width: 1023px) {
  .has-text-right-touch {
    text-align: right !important;
  }
}

@media screen and (min-width: 1024px) {
  .has-text-right-desktop {
    text-align: right !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .has-text-right-desktop-only {
    text-align: right !important;
  }
}

@media screen and (min-width: 1216px) {
  .has-text-right-widescreen {
    text-align: right !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .has-text-right-widescreen-only {
    text-align: right !important;
  }
}

@media screen and (min-width: 1408px) {
  .has-text-right-fullhd {
    text-align: right !important;
  }
}

.is-capitalized {
  text-transform: capitalize !important;
}

.is-lowercase {
  text-transform: lowercase !important;
}

.is-uppercase {
  text-transform: uppercase !important;
}

.is-italic {
  font-style: italic !important;
}

.is-underlined {
  text-decoration: underline !important;
}

.has-text-weight-light {
  font-weight: 300 !important;
}

.has-text-weight-normal {
  font-weight: 400 !important;
}

.has-text-weight-medium {
  font-weight: 500 !important;
}

.has-text-weight-semibold {
  font-weight: 600 !important;
}

.has-text-weight-bold {
  font-weight: 700 !important;
}

.is-family-primary, .is-family-secondary, .is-family-sans-serif {
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif !important;
}

.is-family-monospace, .is-family-code {
  font-family: monospace !important;
}

.is-block {
  display: block !important;
}

@media screen and (max-width: 768px) {
  .is-block-mobile {
    display: block !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-block-tablet {
    display: block !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-block-tablet-only {
    display: block !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-block-touch {
    display: block !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-block-desktop {
    display: block !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-block-desktop-only {
    display: block !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-block-widescreen {
    display: block !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-block-widescreen-only {
    display: block !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-block-fullhd {
    display: block !important;
  }
}

.is-flex {
  display: flex !important;
}

@media screen and (max-width: 768px) {
  .is-flex-mobile {
    display: flex !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-flex-tablet {
    display: flex !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-flex-tablet-only {
    display: flex !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-flex-touch {
    display: flex !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-flex-desktop {
    display: flex !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-flex-desktop-only {
    display: flex !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-flex-widescreen {
    display: flex !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-flex-widescreen-only {
    display: flex !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-flex-fullhd {
    display: flex !important;
  }
}

.is-inline {
  display: inline !important;
}

@media screen and (max-width: 768px) {
  .is-inline-mobile {
    display: inline !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-inline-tablet {
    display: inline !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-inline-tablet-only {
    display: inline !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-inline-touch {
    display: inline !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-inline-desktop {
    display: inline !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-inline-desktop-only {
    display: inline !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-inline-widescreen {
    display: inline !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-inline-widescreen-only {
    display: inline !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-inline-fullhd {
    display: inline !important;
  }
}

.is-inline-block {
  display: inline-block !important;
}

@media screen and (max-width: 768px) {
  .is-inline-block-mobile {
    display: inline-block !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-inline-block-tablet {
    display: inline-block !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-inline-block-tablet-only {
    display: inline-block !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-inline-block-touch {
    display: inline-block !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-inline-block-desktop {
    display: inline-block !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-inline-block-desktop-only {
    display: inline-block !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-inline-block-widescreen {
    display: inline-block !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-inline-block-widescreen-only {
    display: inline-block !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-inline-block-fullhd {
    display: inline-block !important;
  }
}

.is-inline-flex {
  display: inline-flex !important;
}

@media screen and (max-width: 768px) {
  .is-inline-flex-mobile {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-inline-flex-tablet {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-inline-flex-tablet-only {
    display: inline-flex !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-inline-flex-touch {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-inline-flex-desktop {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-inline-flex-desktop-only {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-inline-flex-widescreen {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-inline-flex-widescreen-only {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-inline-flex-fullhd {
    display: inline-flex !important;
  }
}

.is-hidden {
  display: none !important;
}

.is-sr-only {
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: none !important;
  width: .01em !important;
  height: .01em !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

@media screen and (max-width: 768px) {
  .is-hidden-mobile {
    display: none !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-hidden-tablet {
    display: none !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-hidden-tablet-only {
    display: none !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-hidden-touch {
    display: none !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-hidden-desktop {
    display: none !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-hidden-desktop-only {
    display: none !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-hidden-widescreen {
    display: none !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-hidden-widescreen-only {
    display: none !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-hidden-fullhd {
    display: none !important;
  }
}

.is-invisible {
  visibility: hidden !important;
}

@media screen and (max-width: 768px) {
  .is-invisible-mobile {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-invisible-tablet {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-invisible-tablet-only {
    visibility: hidden !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-invisible-touch {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-invisible-desktop {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-invisible-desktop-only {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-invisible-widescreen {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-invisible-widescreen-only {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-invisible-fullhd {
    visibility: hidden !important;
  }
}

.hero {
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.hero .navbar {
  background: none;
}

.hero .tabs ul {
  border-bottom: none;
}

.hero.is-white {
  color: #0a0a0a;
  background-color: #fff;
}

.hero.is-white a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-white strong {
  color: inherit;
}

.hero.is-white .title {
  color: #0a0a0a;
}

.hero.is-white .subtitle {
  color: rgba(10, 10, 10, .9);
}

.hero.is-white .subtitle a:not(.button), .hero.is-white .subtitle strong {
  color: #0a0a0a;
}

@media screen and (max-width: 1023px) {
  .hero.is-white .navbar-menu {
    background-color: #fff;
  }
}

.hero.is-white .navbar-item, .hero.is-white .navbar-link {
  color: rgba(10, 10, 10, .7);
}

.hero.is-white a.navbar-item:hover, .hero.is-white a.navbar-item.is-active, .hero.is-white .navbar-link:hover, .hero.is-white .navbar-link.is-active {
  color: #0a0a0a;
  background-color: #f2f2f2;
}

.hero.is-white .tabs a {
  color: #0a0a0a;
  opacity: .9;
}

.hero.is-white .tabs a:hover {
  opacity: 1;
}

.hero.is-white .tabs li.is-active a {
  opacity: 1;
  color: #fff !important;
}

.hero.is-white .tabs.is-boxed a, .hero.is-white .tabs.is-toggle a {
  color: #0a0a0a;
}

.hero.is-white .tabs.is-boxed a:hover, .hero.is-white .tabs.is-toggle a:hover {
  background-color: rgba(10, 10, 10, .1);
}

.hero.is-white .tabs.is-boxed li.is-active a, .hero.is-white .tabs.is-boxed li.is-active a:hover, .hero.is-white .tabs.is-toggle li.is-active a, .hero.is-white .tabs.is-toggle li.is-active a:hover {
  color: #fff;
  background-color: #0a0a0a;
  border-color: #0a0a0a;
}

.hero.is-white.is-bold {
  background-image: linear-gradient(141deg, #e8e3e4 0%, #fff 71%, #fff 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-white.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #e8e3e4 0%, #fff 71%, #fff 100%);
  }
}

.hero.is-black {
  color: #fff;
  background-color: #0a0a0a;
}

.hero.is-black a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-black strong {
  color: inherit;
}

.hero.is-black .title {
  color: #fff;
}

.hero.is-black .subtitle {
  color: rgba(255, 255, 255, .9);
}

.hero.is-black .subtitle a:not(.button), .hero.is-black .subtitle strong {
  color: #fff;
}

@media screen and (max-width: 1023px) {
  .hero.is-black .navbar-menu {
    background-color: #0a0a0a;
  }
}

.hero.is-black .navbar-item, .hero.is-black .navbar-link {
  color: rgba(255, 255, 255, .7);
}

.hero.is-black a.navbar-item:hover, .hero.is-black a.navbar-item.is-active, .hero.is-black .navbar-link:hover, .hero.is-black .navbar-link.is-active {
  color: #fff;
  background-color: #000;
}

.hero.is-black .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-black .tabs a:hover {
  opacity: 1;
}

.hero.is-black .tabs li.is-active a {
  opacity: 1;
  color: #0a0a0a !important;
}

.hero.is-black .tabs.is-boxed a, .hero.is-black .tabs.is-toggle a {
  color: #fff;
}

.hero.is-black .tabs.is-boxed a:hover, .hero.is-black .tabs.is-toggle a:hover {
  background-color: rgba(10, 10, 10, .1);
}

.hero.is-black .tabs.is-boxed li.is-active a, .hero.is-black .tabs.is-boxed li.is-active a:hover, .hero.is-black .tabs.is-toggle li.is-active a, .hero.is-black .tabs.is-toggle li.is-active a:hover {
  color: #0a0a0a;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-black.is-bold {
  background-image: linear-gradient(141deg, #000 0%, #0a0a0a 71%, #181616 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-black.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #000 0%, #0a0a0a 71%, #181616 100%);
  }
}

.hero.is-light {
  color: rgba(0, 0, 0, .7);
  background-color: #f5f5f5;
}

.hero.is-light a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-light strong {
  color: inherit;
}

.hero.is-light .title {
  color: rgba(0, 0, 0, .7);
}

.hero.is-light .subtitle {
  color: rgba(0, 0, 0, .9);
}

.hero.is-light .subtitle a:not(.button), .hero.is-light .subtitle strong {
  color: rgba(0, 0, 0, .7);
}

@media screen and (max-width: 1023px) {
  .hero.is-light .navbar-menu {
    background-color: #f5f5f5;
  }
}

.hero.is-light .navbar-item, .hero.is-light .navbar-link {
  color: rgba(0, 0, 0, .7);
}

.hero.is-light a.navbar-item:hover, .hero.is-light a.navbar-item.is-active, .hero.is-light .navbar-link:hover, .hero.is-light .navbar-link.is-active {
  color: rgba(0, 0, 0, .7);
  background-color: #e8e8e8;
}

.hero.is-light .tabs a {
  color: rgba(0, 0, 0, .7);
  opacity: .9;
}

.hero.is-light .tabs a:hover {
  opacity: 1;
}

.hero.is-light .tabs li.is-active a {
  opacity: 1;
  color: #f5f5f5 !important;
}

.hero.is-light .tabs.is-boxed a, .hero.is-light .tabs.is-toggle a {
  color: rgba(0, 0, 0, .7);
}

.hero.is-light .tabs.is-boxed a:hover, .hero.is-light .tabs.is-toggle a:hover {
  background-color: rgba(10, 10, 10, .1);
}

.hero.is-light .tabs.is-boxed li.is-active a, .hero.is-light .tabs.is-boxed li.is-active a:hover, .hero.is-light .tabs.is-toggle li.is-active a, .hero.is-light .tabs.is-toggle li.is-active a:hover {
  color: #f5f5f5;
  background-color: rgba(0, 0, 0, .7);
  border-color: rgba(0, 0, 0, .7);
}

.hero.is-light.is-bold {
  background-image: linear-gradient(141deg, #dfd8d9 0%, #f5f5f5 71%, #fff 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-light.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #dfd8d9 0%, #f5f5f5 71%, #fff 100%);
  }
}

.hero.is-dark {
  color: #fff;
  background-color: #363636;
}

.hero.is-dark a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-dark strong {
  color: inherit;
}

.hero.is-dark .title {
  color: #fff;
}

.hero.is-dark .subtitle {
  color: rgba(255, 255, 255, .9);
}

.hero.is-dark .subtitle a:not(.button), .hero.is-dark .subtitle strong {
  color: #fff;
}

@media screen and (max-width: 1023px) {
  .hero.is-dark .navbar-menu {
    background-color: #363636;
  }
}

.hero.is-dark .navbar-item, .hero.is-dark .navbar-link {
  color: rgba(255, 255, 255, .7);
}

.hero.is-dark a.navbar-item:hover, .hero.is-dark a.navbar-item.is-active, .hero.is-dark .navbar-link:hover, .hero.is-dark .navbar-link.is-active {
  color: #fff;
  background-color: #292929;
}

.hero.is-dark .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-dark .tabs a:hover {
  opacity: 1;
}

.hero.is-dark .tabs li.is-active a {
  opacity: 1;
  color: #363636 !important;
}

.hero.is-dark .tabs.is-boxed a, .hero.is-dark .tabs.is-toggle a {
  color: #fff;
}

.hero.is-dark .tabs.is-boxed a:hover, .hero.is-dark .tabs.is-toggle a:hover {
  background-color: rgba(10, 10, 10, .1);
}

.hero.is-dark .tabs.is-boxed li.is-active a, .hero.is-dark .tabs.is-boxed li.is-active a:hover, .hero.is-dark .tabs.is-toggle li.is-active a, .hero.is-dark .tabs.is-toggle li.is-active a:hover {
  color: #363636;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-dark.is-bold {
  background-image: linear-gradient(141deg, #1f191a 0%, #363636 71%, #46403f 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-dark.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #1f191a 0%, #363636 71%, #46403f 100%);
  }
}

.hero.is-primary {
  color: #fff;
  background-color: #00d1b2;
}

.hero.is-primary a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-primary strong {
  color: inherit;
}

.hero.is-primary .title {
  color: #fff;
}

.hero.is-primary .subtitle {
  color: rgba(255, 255, 255, .9);
}

.hero.is-primary .subtitle a:not(.button), .hero.is-primary .subtitle strong {
  color: #fff;
}

@media screen and (max-width: 1023px) {
  .hero.is-primary .navbar-menu {
    background-color: #00d1b2;
  }
}

.hero.is-primary .navbar-item, .hero.is-primary .navbar-link {
  color: rgba(255, 255, 255, .7);
}

.hero.is-primary a.navbar-item:hover, .hero.is-primary a.navbar-item.is-active, .hero.is-primary .navbar-link:hover, .hero.is-primary .navbar-link.is-active {
  color: #fff;
  background-color: #00b89c;
}

.hero.is-primary .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-primary .tabs a:hover {
  opacity: 1;
}

.hero.is-primary .tabs li.is-active a {
  opacity: 1;
  color: #00d1b2 !important;
}

.hero.is-primary .tabs.is-boxed a, .hero.is-primary .tabs.is-toggle a {
  color: #fff;
}

.hero.is-primary .tabs.is-boxed a:hover, .hero.is-primary .tabs.is-toggle a:hover {
  background-color: rgba(10, 10, 10, .1);
}

.hero.is-primary .tabs.is-boxed li.is-active a, .hero.is-primary .tabs.is-boxed li.is-active a:hover, .hero.is-primary .tabs.is-toggle li.is-active a, .hero.is-primary .tabs.is-toggle li.is-active a:hover {
  color: #00d1b2;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-primary.is-bold {
  background-image: linear-gradient(141deg, #009e6c 0%, #00d1b2 71%, #00e7eb 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-primary.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #009e6c 0%, #00d1b2 71%, #00e7eb 100%);
  }
}

.hero.is-link {
  color: #fff;
  background-color: #485fc7;
}

.hero.is-link a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-link strong {
  color: inherit;
}

.hero.is-link .title {
  color: #fff;
}

.hero.is-link .subtitle {
  color: rgba(255, 255, 255, .9);
}

.hero.is-link .subtitle a:not(.button), .hero.is-link .subtitle strong {
  color: #fff;
}

@media screen and (max-width: 1023px) {
  .hero.is-link .navbar-menu {
    background-color: #485fc7;
  }
}

.hero.is-link .navbar-item, .hero.is-link .navbar-link {
  color: rgba(255, 255, 255, .7);
}

.hero.is-link a.navbar-item:hover, .hero.is-link a.navbar-item.is-active, .hero.is-link .navbar-link:hover, .hero.is-link .navbar-link.is-active {
  color: #fff;
  background-color: #3a51bb;
}

.hero.is-link .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-link .tabs a:hover {
  opacity: 1;
}

.hero.is-link .tabs li.is-active a {
  opacity: 1;
  color: #485fc7 !important;
}

.hero.is-link .tabs.is-boxed a, .hero.is-link .tabs.is-toggle a {
  color: #fff;
}

.hero.is-link .tabs.is-boxed a:hover, .hero.is-link .tabs.is-toggle a:hover {
  background-color: rgba(10, 10, 10, .1);
}

.hero.is-link .tabs.is-boxed li.is-active a, .hero.is-link .tabs.is-boxed li.is-active a:hover, .hero.is-link .tabs.is-toggle li.is-active a, .hero.is-link .tabs.is-toggle li.is-active a:hover {
  color: #485fc7;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-link.is-bold {
  background-image: linear-gradient(141deg, #2959b3 0%, #485fc7 71%, #5658d2 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-link.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #2959b3 0%, #485fc7 71%, #5658d2 100%);
  }
}

.hero.is-info {
  color: #fff;
  background-color: #3e8ed0;
}

.hero.is-info a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-info strong {
  color: inherit;
}

.hero.is-info .title {
  color: #fff;
}

.hero.is-info .subtitle {
  color: rgba(255, 255, 255, .9);
}

.hero.is-info .subtitle a:not(.button), .hero.is-info .subtitle strong {
  color: #fff;
}

@media screen and (max-width: 1023px) {
  .hero.is-info .navbar-menu {
    background-color: #3e8ed0;
  }
}

.hero.is-info .navbar-item, .hero.is-info .navbar-link {
  color: rgba(255, 255, 255, .7);
}

.hero.is-info a.navbar-item:hover, .hero.is-info a.navbar-item.is-active, .hero.is-info .navbar-link:hover, .hero.is-info .navbar-link.is-active {
  color: #fff;
  background-color: #3082c5;
}

.hero.is-info .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-info .tabs a:hover {
  opacity: 1;
}

.hero.is-info .tabs li.is-active a {
  opacity: 1;
  color: #3e8ed0 !important;
}

.hero.is-info .tabs.is-boxed a, .hero.is-info .tabs.is-toggle a {
  color: #fff;
}

.hero.is-info .tabs.is-boxed a:hover, .hero.is-info .tabs.is-toggle a:hover {
  background-color: rgba(10, 10, 10, .1);
}

.hero.is-info .tabs.is-boxed li.is-active a, .hero.is-info .tabs.is-boxed li.is-active a:hover, .hero.is-info .tabs.is-toggle li.is-active a, .hero.is-info .tabs.is-toggle li.is-active a:hover {
  color: #3e8ed0;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-info.is-bold {
  background-image: linear-gradient(141deg, #208fbc 0%, #3e8ed0 71%, #4d83db 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-info.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #208fbc 0%, #3e8ed0 71%, #4d83db 100%);
  }
}

.hero.is-success {
  color: #fff;
  background-color: #48c78e;
}

.hero.is-success a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-success strong {
  color: inherit;
}

.hero.is-success .title {
  color: #fff;
}

.hero.is-success .subtitle {
  color: rgba(255, 255, 255, .9);
}

.hero.is-success .subtitle a:not(.button), .hero.is-success .subtitle strong {
  color: #fff;
}

@media screen and (max-width: 1023px) {
  .hero.is-success .navbar-menu {
    background-color: #48c78e;
  }
}

.hero.is-success .navbar-item, .hero.is-success .navbar-link {
  color: rgba(255, 255, 255, .7);
}

.hero.is-success a.navbar-item:hover, .hero.is-success a.navbar-item.is-active, .hero.is-success .navbar-link:hover, .hero.is-success .navbar-link.is-active {
  color: #fff;
  background-color: #3abb81;
}

.hero.is-success .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-success .tabs a:hover {
  opacity: 1;
}

.hero.is-success .tabs li.is-active a {
  opacity: 1;
  color: #48c78e !important;
}

.hero.is-success .tabs.is-boxed a, .hero.is-success .tabs.is-toggle a {
  color: #fff;
}

.hero.is-success .tabs.is-boxed a:hover, .hero.is-success .tabs.is-toggle a:hover {
  background-color: rgba(10, 10, 10, .1);
}

.hero.is-success .tabs.is-boxed li.is-active a, .hero.is-success .tabs.is-boxed li.is-active a:hover, .hero.is-success .tabs.is-toggle li.is-active a, .hero.is-success .tabs.is-toggle li.is-active a:hover {
  color: #48c78e;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-success.is-bold {
  background-image: linear-gradient(141deg, #29b35e 0%, #48c78e 71%, #56d2af 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-success.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #29b35e 0%, #48c78e 71%, #56d2af 100%);
  }
}

.hero.is-warning {
  color: rgba(0, 0, 0, .7);
  background-color: #ffe08a;
}

.hero.is-warning a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-warning strong {
  color: inherit;
}

.hero.is-warning .title {
  color: rgba(0, 0, 0, .7);
}

.hero.is-warning .subtitle {
  color: rgba(0, 0, 0, .9);
}

.hero.is-warning .subtitle a:not(.button), .hero.is-warning .subtitle strong {
  color: rgba(0, 0, 0, .7);
}

@media screen and (max-width: 1023px) {
  .hero.is-warning .navbar-menu {
    background-color: #ffe08a;
  }
}

.hero.is-warning .navbar-item, .hero.is-warning .navbar-link {
  color: rgba(0, 0, 0, .7);
}

.hero.is-warning a.navbar-item:hover, .hero.is-warning a.navbar-item.is-active, .hero.is-warning .navbar-link:hover, .hero.is-warning .navbar-link.is-active {
  color: rgba(0, 0, 0, .7);
  background-color: #ffd970;
}

.hero.is-warning .tabs a {
  color: rgba(0, 0, 0, .7);
  opacity: .9;
}

.hero.is-warning .tabs a:hover {
  opacity: 1;
}

.hero.is-warning .tabs li.is-active a {
  opacity: 1;
  color: #ffe08a !important;
}

.hero.is-warning .tabs.is-boxed a, .hero.is-warning .tabs.is-toggle a {
  color: rgba(0, 0, 0, .7);
}

.hero.is-warning .tabs.is-boxed a:hover, .hero.is-warning .tabs.is-toggle a:hover {
  background-color: rgba(10, 10, 10, .1);
}

.hero.is-warning .tabs.is-boxed li.is-active a, .hero.is-warning .tabs.is-boxed li.is-active a:hover, .hero.is-warning .tabs.is-toggle li.is-active a, .hero.is-warning .tabs.is-toggle li.is-active a:hover {
  color: #ffe08a;
  background-color: rgba(0, 0, 0, .7);
  border-color: rgba(0, 0, 0, .7);
}

.hero.is-warning.is-bold {
  background-image: linear-gradient(141deg, #ffb657 0%, #ffe08a 71%, #fff6a3 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-warning.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #ffb657 0%, #ffe08a 71%, #fff6a3 100%);
  }
}

.hero.is-danger {
  color: #fff;
  background-color: #f14668;
}

.hero.is-danger a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-danger strong {
  color: inherit;
}

.hero.is-danger .title {
  color: #fff;
}

.hero.is-danger .subtitle {
  color: rgba(255, 255, 255, .9);
}

.hero.is-danger .subtitle a:not(.button), .hero.is-danger .subtitle strong {
  color: #fff;
}

@media screen and (max-width: 1023px) {
  .hero.is-danger .navbar-menu {
    background-color: #f14668;
  }
}

.hero.is-danger .navbar-item, .hero.is-danger .navbar-link {
  color: rgba(255, 255, 255, .7);
}

.hero.is-danger a.navbar-item:hover, .hero.is-danger a.navbar-item.is-active, .hero.is-danger .navbar-link:hover, .hero.is-danger .navbar-link.is-active {
  color: #fff;
  background-color: #ef2e55;
}

.hero.is-danger .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-danger .tabs a:hover {
  opacity: 1;
}

.hero.is-danger .tabs li.is-active a {
  opacity: 1;
  color: #f14668 !important;
}

.hero.is-danger .tabs.is-boxed a, .hero.is-danger .tabs.is-toggle a {
  color: #fff;
}

.hero.is-danger .tabs.is-boxed a:hover, .hero.is-danger .tabs.is-toggle a:hover {
  background-color: rgba(10, 10, 10, .1);
}

.hero.is-danger .tabs.is-boxed li.is-active a, .hero.is-danger .tabs.is-boxed li.is-active a:hover, .hero.is-danger .tabs.is-toggle li.is-active a, .hero.is-danger .tabs.is-toggle li.is-active a:hover {
  color: #f14668;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-danger.is-bold {
  background-image: linear-gradient(141deg, #fa0a62 0%, #f14668 71%, #f7595f 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-danger.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #fa0a62 0%, #f14668 71%, #f7595f 100%);
  }
}

.hero.is-small .hero-body {
  padding: 1.5rem;
}

@media screen and (min-width: 769px), print {
  .hero.is-medium .hero-body {
    padding: 9rem 4.5rem;
  }

  .hero.is-large .hero-body {
    padding: 18rem 6rem;
  }
}

.hero.is-halfheight .hero-body, .hero.is-fullheight .hero-body, .hero.is-fullheight-with-navbar .hero-body {
  align-items: center;
  display: flex;
}

.hero.is-halfheight .hero-body > .container, .hero.is-fullheight .hero-body > .container, .hero.is-fullheight-with-navbar .hero-body > .container {
  flex-grow: 1;
  flex-shrink: 1;
}

.hero.is-halfheight {
  min-height: 50vh;
}

.hero.is-fullheight {
  min-height: 100vh;
}

.hero-video {
  overflow: hidden;
}

.hero-video video {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

.hero-video.is-transparent {
  opacity: .3;
}

@media screen and (max-width: 768px) {
  .hero-video {
    display: none;
  }
}

.hero-buttons {
  margin-top: 1.5rem;
}

@media screen and (max-width: 768px) {
  .hero-buttons .button {
    display: flex;
  }

  .hero-buttons .button:not(:last-child) {
    margin-bottom: .75rem;
  }
}

@media screen and (min-width: 769px), print {
  .hero-buttons {
    justify-content: center;
    display: flex;
  }

  .hero-buttons .button:not(:last-child) {
    margin-right: 1.5rem;
  }
}

.hero-head, .hero-foot {
  flex-grow: 0;
  flex-shrink: 0;
}

.hero-body {
  flex-grow: 1;
  flex-shrink: 0;
  padding: 3rem 1.5rem;
}

@media screen and (min-width: 769px), print {
  .hero-body {
    padding: 3rem;
  }
}

.section {
  padding: 3rem 1.5rem;
}

@media screen and (min-width: 1024px) {
  .section {
    padding: 3rem;
  }

  .section.is-medium {
    padding: 9rem 4.5rem;
  }

  .section.is-large {
    padding: 18rem 6rem;
  }
}

.footer {
  background-color: #fafafa;
  padding: 3rem 1.5rem 6rem;
}

body {
  background: #f2f2f2;
  min-height: 100vh;
}

.m-0 {
  margin: 0;
}

.m-05 {
  margin: .5rem;
}

.m-1 {
  margin: 1rem;
}

.m-2 {
  margin: 2rem;
}

.m-3 {
  margin: 3rem;
}

.m-4 {
  margin: 4rem;
}

.m-5 {
  margin: 5rem;
}

.ml-0 {
  margin-left: 0;
}

.ml-05 {
  margin-left: .5rem;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-2 {
  margin-left: 2rem;
}

.ml-3 {
  margin-left: 3rem;
}

.ml-4 {
  margin-left: 4rem;
}

.ml-5 {
  margin-left: 5rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-05 {
  margin-top: .5rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mt-4 {
  margin-top: 4rem;
}

.mt-5 {
  margin-top: 5rem;
}

.mr-0 {
  margin-right: 0;
}

.mr-05 {
  margin-right: .5rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}

.mr-3 {
  margin-right: 3rem;
}

.mr-4 {
  margin-right: 4rem;
}

.mr-5 {
  margin-right: 5rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-05 {
  margin-bottom: .5rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mb-4 {
  margin-bottom: 4rem;
}

.mb-5 {
  margin-bottom: 5rem;
}

.p-0 {
  padding: 0;
}

.p-05 {
  padding: .5rem;
}

.p-1 {
  padding: 1rem;
}

.p-2 {
  padding: 2rem;
}

.p-3 {
  padding: 3rem;
}

.p-4 {
  padding: 4rem;
}

.p-5 {
  padding: 5rem;
}

.pl-0 {
  padding-left: 0;
}

.pl-05 {
  padding-left: .5rem;
}

.pl-1 {
  padding-left: 1rem;
}

.pl-2 {
  padding-left: 2rem;
}

.pl-3 {
  padding-left: 3rem;
}

.pl-4 {
  padding-left: 4rem;
}

.pl-5 {
  padding-left: 5rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-05 {
  padding-top: .5rem;
}

.pt-1 {
  padding-top: 1rem;
}

.pt-2 {
  padding-top: 2rem;
}

.pt-3 {
  padding-top: 3rem;
}

.pt-4 {
  padding-top: 4rem;
}

.pt-5 {
  padding-top: 5rem;
}

.pr-0 {
  padding-right: 0;
}

.pr-05 {
  padding-right: .5rem;
}

.pr-1 {
  padding-right: 1rem;
}

.pr-2 {
  padding-right: 2rem;
}

.pr-3 {
  padding-right: 3rem;
}

.pr-4 {
  padding-right: 4rem;
}

.pr-5 {
  padding-right: 5rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-05 {
  padding-bottom: .5rem;
}

.pb-1 {
  padding-bottom: 1rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.pb-3 {
  padding-bottom: 3rem;
}

.pb-4 {
  padding-bottom: 4rem;
}

.pb-5 {
  padding-bottom: 5rem;
}

@font-face {
  font-family: Material Icons;
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("MaterialIcons-Regular.e9e55c63.eot");
  src: local(☺), url("MaterialIcons-Regular.b99eb5ce.woff2") format("woff2"), url("MaterialIcons-Regular.12b3b105.woff") format("woff"), url("MaterialIcons-Regular.333251c4.ttf") format("truetype");
}

.material-icons {
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: inherit;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
  font-family: Material Icons;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

.material-icons._10k:before {
  content: "";
}

.material-icons._10mp:before {
  content: "";
}

.material-icons._11mp:before {
  content: "";
}

.material-icons._123:before {
  content: "";
}

.material-icons._12mp:before {
  content: "";
}

.material-icons._13mp:before {
  content: "";
}

.material-icons._14mp:before {
  content: "";
}

.material-icons._15mp:before {
  content: "";
}

.material-icons._16mp:before {
  content: "";
}

.material-icons._17mp:before {
  content: "";
}

.material-icons._18_up_rating:before {
  content: "";
}

.material-icons._18mp:before {
  content: "";
}

.material-icons._19mp:before {
  content: "";
}

.material-icons._1k:before {
  content: "";
}

.material-icons._1k_plus:before {
  content: "";
}

.material-icons._1x_mobiledata:before {
  content: "";
}

.material-icons._20mp:before {
  content: "";
}

.material-icons._21mp:before {
  content: "";
}

.material-icons._22mp:before {
  content: "";
}

.material-icons._23mp:before {
  content: "";
}

.material-icons._24mp:before {
  content: "";
}

.material-icons._2k:before {
  content: "";
}

.material-icons._2k_plus:before {
  content: "";
}

.material-icons._2mp:before {
  content: "";
}

.material-icons._30fps:before {
  content: "";
}

.material-icons._30fps_select:before {
  content: "";
}

.material-icons._360:before {
  content: "";
}

.material-icons._3d_rotation:before {
  content: "";
}

.material-icons._3g_mobiledata:before {
  content: "";
}

.material-icons._3k:before {
  content: "";
}

.material-icons._3k_plus:before {
  content: "";
}

.material-icons._3mp:before {
  content: "";
}

.material-icons._3p:before {
  content: "";
}

.material-icons._4g_mobiledata:before {
  content: "";
}

.material-icons._4g_plus_mobiledata:before {
  content: "";
}

.material-icons._4k:before {
  content: "";
}

.material-icons._4k_plus:before {
  content: "";
}

.material-icons._4mp:before {
  content: "";
}

.material-icons._5g:before {
  content: "";
}

.material-icons._5k:before {
  content: "";
}

.material-icons._5k_plus:before {
  content: "";
}

.material-icons._5mp:before {
  content: "";
}

.material-icons._60fps:before {
  content: "";
}

.material-icons._60fps_select:before {
  content: "";
}

.material-icons._6_ft_apart:before {
  content: "";
}

.material-icons._6k:before {
  content: "";
}

.material-icons._6k_plus:before {
  content: "";
}

.material-icons._6mp:before {
  content: "";
}

.material-icons._7k:before {
  content: "";
}

.material-icons._7k_plus:before {
  content: "";
}

.material-icons._7mp:before {
  content: "";
}

.material-icons._8k:before {
  content: "";
}

.material-icons._8k_plus:before {
  content: "";
}

.material-icons._8mp:before {
  content: "";
}

.material-icons._9k:before {
  content: "";
}

.material-icons._9k_plus:before {
  content: "";
}

.material-icons._9mp:before {
  content: "";
}

.material-icons.abc:before {
  content: "";
}

.material-icons.ac_unit:before {
  content: "";
}

.material-icons.access_alarm:before {
  content: "";
}

.material-icons.access_alarms:before {
  content: "";
}

.material-icons.access_time:before {
  content: "";
}

.material-icons.access_time_filled:before {
  content: "";
}

.material-icons.accessibility:before {
  content: "";
}

.material-icons.accessibility_new:before {
  content: "";
}

.material-icons.accessible:before {
  content: "";
}

.material-icons.accessible_forward:before {
  content: "";
}

.material-icons.account_balance:before {
  content: "";
}

.material-icons.account_balance_wallet:before {
  content: "";
}

.material-icons.account_box:before {
  content: "";
}

.material-icons.account_circle:before {
  content: "";
}

.material-icons.account_tree:before {
  content: "";
}

.material-icons.ad_units:before {
  content: "";
}

.material-icons.adb:before {
  content: "";
}

.material-icons.add:before {
  content: "";
}

.material-icons.add_a_photo:before {
  content: "";
}

.material-icons.add_alarm:before {
  content: "";
}

.material-icons.add_alert:before {
  content: "";
}

.material-icons.add_box:before {
  content: "";
}

.material-icons.add_business:before {
  content: "";
}

.material-icons.add_call:before {
  content: "";
}

.material-icons.add_card:before {
  content: "";
}

.material-icons.add_chart:before {
  content: "";
}

.material-icons.add_circle:before {
  content: "";
}

.material-icons.add_circle_outline:before {
  content: "";
}

.material-icons.add_comment:before {
  content: "";
}

.material-icons.add_home:before {
  content: "";
}

.material-icons.add_home_work:before {
  content: "";
}

.material-icons.add_ic_call:before {
  content: "";
}

.material-icons.add_link:before {
  content: "";
}

.material-icons.add_location:before {
  content: "";
}

.material-icons.add_location_alt:before {
  content: "";
}

.material-icons.add_moderator:before {
  content: "";
}

.material-icons.add_photo_alternate:before {
  content: "";
}

.material-icons.add_reaction:before {
  content: "";
}

.material-icons.add_road:before {
  content: "";
}

.material-icons.add_shopping_cart:before {
  content: "";
}

.material-icons.add_task:before {
  content: "";
}

.material-icons.add_to_drive:before {
  content: "";
}

.material-icons.add_to_home_screen:before {
  content: "";
}

.material-icons.add_to_photos:before {
  content: "";
}

.material-icons.add_to_queue:before {
  content: "";
}

.material-icons.addchart:before {
  content: "";
}

.material-icons.adf_scanner:before {
  content: "";
}

.material-icons.adjust:before {
  content: "";
}

.material-icons.admin_panel_settings:before {
  content: "";
}

.material-icons.adobe:before {
  content: "";
}

.material-icons.ads_click:before {
  content: "";
}

.material-icons.agriculture:before {
  content: "";
}

.material-icons.air:before {
  content: "";
}

.material-icons.airline_seat_flat:before {
  content: "";
}

.material-icons.airline_seat_flat_angled:before {
  content: "";
}

.material-icons.airline_seat_individual_suite:before {
  content: "";
}

.material-icons.airline_seat_legroom_extra:before {
  content: "";
}

.material-icons.airline_seat_legroom_normal:before {
  content: "";
}

.material-icons.airline_seat_legroom_reduced:before {
  content: "";
}

.material-icons.airline_seat_recline_extra:before {
  content: "";
}

.material-icons.airline_seat_recline_normal:before {
  content: "";
}

.material-icons.airline_stops:before {
  content: "";
}

.material-icons.airlines:before {
  content: "";
}

.material-icons.airplane_ticket:before {
  content: "";
}

.material-icons.airplanemode_active:before {
  content: "";
}

.material-icons.airplanemode_inactive:before, .material-icons.airplanemode_off:before {
  content: "";
}

.material-icons.airplanemode_on:before {
  content: "";
}

.material-icons.airplay:before {
  content: "";
}

.material-icons.airport_shuttle:before {
  content: "";
}

.material-icons.alarm:before {
  content: "";
}

.material-icons.alarm_add:before {
  content: "";
}

.material-icons.alarm_off:before {
  content: "";
}

.material-icons.alarm_on:before {
  content: "";
}

.material-icons.album:before {
  content: "";
}

.material-icons.align_horizontal_center:before {
  content: "";
}

.material-icons.align_horizontal_left:before {
  content: "";
}

.material-icons.align_horizontal_right:before {
  content: "";
}

.material-icons.align_vertical_bottom:before {
  content: "";
}

.material-icons.align_vertical_center:before {
  content: "";
}

.material-icons.align_vertical_top:before {
  content: "";
}

.material-icons.all_inbox:before {
  content: "";
}

.material-icons.all_inclusive:before {
  content: "";
}

.material-icons.all_out:before {
  content: "";
}

.material-icons.alt_route:before {
  content: "";
}

.material-icons.alternate_email:before {
  content: "";
}

.material-icons.amp_stories:before {
  content: "";
}

.material-icons.analytics:before {
  content: "";
}

.material-icons.anchor:before {
  content: "";
}

.material-icons.android:before {
  content: "";
}

.material-icons.animation:before {
  content: "";
}

.material-icons.announcement:before {
  content: "";
}

.material-icons.aod:before {
  content: "";
}

.material-icons.apartment:before {
  content: "";
}

.material-icons.api:before {
  content: "";
}

.material-icons.app_blocking:before {
  content: "";
}

.material-icons.app_registration:before {
  content: "";
}

.material-icons.app_settings_alt:before {
  content: "";
}

.material-icons.app_shortcut:before {
  content: "";
}

.material-icons.apple:before {
  content: "";
}

.material-icons.approval:before {
  content: "";
}

.material-icons.apps:before {
  content: "";
}

.material-icons.apps_outage:before {
  content: "";
}

.material-icons.architecture:before {
  content: "";
}

.material-icons.archive:before {
  content: "";
}

.material-icons.area_chart:before {
  content: "";
}

.material-icons.arrow_back:before {
  content: "";
}

.material-icons.arrow_back_ios:before {
  content: "";
}

.material-icons.arrow_back_ios_new:before {
  content: "";
}

.material-icons.arrow_circle_down:before {
  content: "";
}

.material-icons.arrow_circle_left:before {
  content: "";
}

.material-icons.arrow_circle_right:before {
  content: "";
}

.material-icons.arrow_circle_up:before {
  content: "";
}

.material-icons.arrow_downward:before {
  content: "";
}

.material-icons.arrow_drop_down:before {
  content: "";
}

.material-icons.arrow_drop_down_circle:before {
  content: "";
}

.material-icons.arrow_drop_up:before {
  content: "";
}

.material-icons.arrow_forward:before {
  content: "";
}

.material-icons.arrow_forward_ios:before {
  content: "";
}

.material-icons.arrow_left:before {
  content: "";
}

.material-icons.arrow_right:before {
  content: "";
}

.material-icons.arrow_right_alt:before {
  content: "";
}

.material-icons.arrow_upward:before {
  content: "";
}

.material-icons.art_track:before {
  content: "";
}

.material-icons.article:before {
  content: "";
}

.material-icons.aspect_ratio:before {
  content: "";
}

.material-icons.assessment:before {
  content: "";
}

.material-icons.assignment:before {
  content: "";
}

.material-icons.assignment_ind:before {
  content: "";
}

.material-icons.assignment_late:before {
  content: "";
}

.material-icons.assignment_return:before {
  content: "";
}

.material-icons.assignment_returned:before {
  content: "";
}

.material-icons.assignment_turned_in:before {
  content: "";
}

.material-icons.assistant:before {
  content: "";
}

.material-icons.assistant_direction:before {
  content: "";
}

.material-icons.assistant_navigation:before {
  content: "";
}

.material-icons.assistant_photo:before {
  content: "";
}

.material-icons.assured_workload:before {
  content: "";
}

.material-icons.atm:before {
  content: "";
}

.material-icons.attach_email:before {
  content: "";
}

.material-icons.attach_file:before {
  content: "";
}

.material-icons.attach_money:before {
  content: "";
}

.material-icons.attachment:before {
  content: "";
}

.material-icons.attractions:before {
  content: "";
}

.material-icons.attribution:before {
  content: "";
}

.material-icons.audio_file:before {
  content: "";
}

.material-icons.audiotrack:before {
  content: "";
}

.material-icons.auto_awesome:before {
  content: "";
}

.material-icons.auto_awesome_mosaic:before {
  content: "";
}

.material-icons.auto_awesome_motion:before {
  content: "";
}

.material-icons.auto_delete:before {
  content: "";
}

.material-icons.auto_fix_high:before {
  content: "";
}

.material-icons.auto_fix_normal:before {
  content: "";
}

.material-icons.auto_fix_off:before {
  content: "";
}

.material-icons.auto_graph:before {
  content: "";
}

.material-icons.auto_mode:before {
  content: "";
}

.material-icons.auto_stories:before {
  content: "";
}

.material-icons.autofps_select:before {
  content: "";
}

.material-icons.autorenew:before {
  content: "";
}

.material-icons.av_timer:before {
  content: "";
}

.material-icons.baby_changing_station:before {
  content: "";
}

.material-icons.back_hand:before {
  content: "";
}

.material-icons.backpack:before {
  content: "";
}

.material-icons.backspace:before {
  content: "";
}

.material-icons.backup:before {
  content: "";
}

.material-icons.backup_table:before {
  content: "";
}

.material-icons.badge:before {
  content: "";
}

.material-icons.bakery_dining:before {
  content: "";
}

.material-icons.balance:before {
  content: "";
}

.material-icons.balcony:before {
  content: "";
}

.material-icons.ballot:before {
  content: "";
}

.material-icons.bar_chart:before {
  content: "";
}

.material-icons.batch_prediction:before {
  content: "";
}

.material-icons.bathroom:before {
  content: "";
}

.material-icons.bathtub:before {
  content: "";
}

.material-icons.battery_0_bar:before {
  content: "";
}

.material-icons.battery_1_bar:before {
  content: "";
}

.material-icons.battery_2_bar:before {
  content: "";
}

.material-icons.battery_3_bar:before {
  content: "";
}

.material-icons.battery_4_bar:before {
  content: "";
}

.material-icons.battery_5_bar:before {
  content: "";
}

.material-icons.battery_6_bar:before {
  content: "";
}

.material-icons.battery_alert:before {
  content: "";
}

.material-icons.battery_charging_full:before {
  content: "";
}

.material-icons.battery_full:before {
  content: "";
}

.material-icons.battery_saver:before {
  content: "";
}

.material-icons.battery_std:before {
  content: "";
}

.material-icons.battery_unknown:before {
  content: "";
}

.material-icons.beach_access:before {
  content: "";
}

.material-icons.bed:before {
  content: "";
}

.material-icons.bedroom_baby:before {
  content: "";
}

.material-icons.bedroom_child:before {
  content: "";
}

.material-icons.bedroom_parent:before {
  content: "";
}

.material-icons.bedtime:before {
  content: "";
}

.material-icons.bedtime_off:before {
  content: "";
}

.material-icons.beenhere:before {
  content: "";
}

.material-icons.bento:before {
  content: "";
}

.material-icons.bike_scooter:before {
  content: "";
}

.material-icons.biotech:before {
  content: "";
}

.material-icons.blender:before {
  content: "";
}

.material-icons.blinds:before {
  content: "";
}

.material-icons.blinds_closed:before {
  content: "";
}

.material-icons.block:before {
  content: "";
}

.material-icons.block_flipped:before {
  content: "";
}

.material-icons.bloodtype:before {
  content: "";
}

.material-icons.bluetooth:before {
  content: "";
}

.material-icons.bluetooth_audio:before {
  content: "";
}

.material-icons.bluetooth_connected:before {
  content: "";
}

.material-icons.bluetooth_disabled:before {
  content: "";
}

.material-icons.bluetooth_drive:before {
  content: "";
}

.material-icons.bluetooth_searching:before {
  content: "";
}

.material-icons.blur_circular:before {
  content: "";
}

.material-icons.blur_linear:before {
  content: "";
}

.material-icons.blur_off:before {
  content: "";
}

.material-icons.blur_on:before {
  content: "";
}

.material-icons.bolt:before {
  content: "";
}

.material-icons.book:before {
  content: "";
}

.material-icons.book_online:before {
  content: "";
}

.material-icons.bookmark:before {
  content: "";
}

.material-icons.bookmark_add:before {
  content: "";
}

.material-icons.bookmark_added:before {
  content: "";
}

.material-icons.bookmark_border:before, .material-icons.bookmark_outline:before {
  content: "";
}

.material-icons.bookmark_remove:before {
  content: "";
}

.material-icons.bookmarks:before {
  content: "";
}

.material-icons.border_all:before {
  content: "";
}

.material-icons.border_bottom:before {
  content: "";
}

.material-icons.border_clear:before {
  content: "";
}

.material-icons.border_color:before {
  content: "";
}

.material-icons.border_horizontal:before {
  content: "";
}

.material-icons.border_inner:before {
  content: "";
}

.material-icons.border_left:before {
  content: "";
}

.material-icons.border_outer:before {
  content: "";
}

.material-icons.border_right:before {
  content: "";
}

.material-icons.border_style:before {
  content: "";
}

.material-icons.border_top:before {
  content: "";
}

.material-icons.border_vertical:before {
  content: "";
}

.material-icons.boy:before {
  content: "";
}

.material-icons.branding_watermark:before {
  content: "";
}

.material-icons.breakfast_dining:before {
  content: "";
}

.material-icons.brightness_1:before {
  content: "";
}

.material-icons.brightness_2:before {
  content: "";
}

.material-icons.brightness_3:before {
  content: "";
}

.material-icons.brightness_4:before {
  content: "";
}

.material-icons.brightness_5:before {
  content: "";
}

.material-icons.brightness_6:before {
  content: "";
}

.material-icons.brightness_7:before {
  content: "";
}

.material-icons.brightness_auto:before {
  content: "";
}

.material-icons.brightness_high:before {
  content: "";
}

.material-icons.brightness_low:before {
  content: "";
}

.material-icons.brightness_medium:before {
  content: "";
}

.material-icons.broadcast_on_home:before {
  content: "";
}

.material-icons.broadcast_on_personal:before {
  content: "";
}

.material-icons.broken_image:before {
  content: "";
}

.material-icons.browse_gallery:before {
  content: "";
}

.material-icons.browser_not_supported:before {
  content: "";
}

.material-icons.browser_updated:before {
  content: "";
}

.material-icons.brunch_dining:before {
  content: "";
}

.material-icons.brush:before {
  content: "";
}

.material-icons.bubble_chart:before {
  content: "";
}

.material-icons.bug_report:before {
  content: "";
}

.material-icons.build:before {
  content: "";
}

.material-icons.build_circle:before {
  content: "";
}

.material-icons.bungalow:before {
  content: "";
}

.material-icons.burst_mode:before {
  content: "";
}

.material-icons.bus_alert:before {
  content: "";
}

.material-icons.business:before {
  content: "";
}

.material-icons.business_center:before {
  content: "";
}

.material-icons.cabin:before {
  content: "";
}

.material-icons.cable:before {
  content: "";
}

.material-icons.cached:before {
  content: "";
}

.material-icons.cake:before {
  content: "";
}

.material-icons.calculate:before {
  content: "";
}

.material-icons.calendar_month:before {
  content: "";
}

.material-icons.calendar_today:before {
  content: "";
}

.material-icons.calendar_view_day:before {
  content: "";
}

.material-icons.calendar_view_month:before {
  content: "";
}

.material-icons.calendar_view_week:before {
  content: "";
}

.material-icons.call:before {
  content: "";
}

.material-icons.call_end:before {
  content: "";
}

.material-icons.call_made:before {
  content: "";
}

.material-icons.call_merge:before {
  content: "";
}

.material-icons.call_missed:before {
  content: "";
}

.material-icons.call_missed_outgoing:before {
  content: "";
}

.material-icons.call_received:before {
  content: "";
}

.material-icons.call_split:before {
  content: "";
}

.material-icons.call_to_action:before {
  content: "";
}

.material-icons.camera:before {
  content: "";
}

.material-icons.camera_alt:before {
  content: "";
}

.material-icons.camera_enhance:before {
  content: "";
}

.material-icons.camera_front:before {
  content: "";
}

.material-icons.camera_indoor:before {
  content: "";
}

.material-icons.camera_outdoor:before {
  content: "";
}

.material-icons.camera_rear:before {
  content: "";
}

.material-icons.camera_roll:before {
  content: "";
}

.material-icons.cameraswitch:before {
  content: "";
}

.material-icons.campaign:before {
  content: "";
}

.material-icons.cancel:before {
  content: "";
}

.material-icons.cancel_presentation:before {
  content: "";
}

.material-icons.cancel_schedule_send:before {
  content: "";
}

.material-icons.candlestick_chart:before {
  content: "";
}

.material-icons.car_crash:before {
  content: "";
}

.material-icons.car_rental:before {
  content: "";
}

.material-icons.car_repair:before {
  content: "";
}

.material-icons.card_giftcard:before {
  content: "";
}

.material-icons.card_membership:before {
  content: "";
}

.material-icons.card_travel:before {
  content: "";
}

.material-icons.carpenter:before {
  content: "";
}

.material-icons.cases:before {
  content: "";
}

.material-icons.casino:before {
  content: "";
}

.material-icons.cast:before {
  content: "";
}

.material-icons.cast_connected:before {
  content: "";
}

.material-icons.cast_for_education:before {
  content: "";
}

.material-icons.castle:before {
  content: "";
}

.material-icons.catching_pokemon:before {
  content: "";
}

.material-icons.category:before {
  content: "";
}

.material-icons.celebration:before {
  content: "";
}

.material-icons.cell_tower:before {
  content: "";
}

.material-icons.cell_wifi:before {
  content: "";
}

.material-icons.center_focus_strong:before {
  content: "";
}

.material-icons.center_focus_weak:before {
  content: "";
}

.material-icons.chair:before {
  content: "";
}

.material-icons.chair_alt:before {
  content: "";
}

.material-icons.chalet:before {
  content: "";
}

.material-icons.change_circle:before {
  content: "";
}

.material-icons.change_history:before {
  content: "";
}

.material-icons.charging_station:before {
  content: "";
}

.material-icons.chat:before {
  content: "";
}

.material-icons.chat_bubble:before {
  content: "";
}

.material-icons.chat_bubble_outline:before {
  content: "";
}

.material-icons.check:before {
  content: "";
}

.material-icons.check_box:before {
  content: "";
}

.material-icons.check_box_outline_blank:before {
  content: "";
}

.material-icons.check_circle:before {
  content: "";
}

.material-icons.check_circle_outline:before {
  content: "";
}

.material-icons.checklist:before {
  content: "";
}

.material-icons.checklist_rtl:before {
  content: "";
}

.material-icons.checkroom:before {
  content: "";
}

.material-icons.chevron_left:before {
  content: "";
}

.material-icons.chevron_right:before {
  content: "";
}

.material-icons.child_care:before {
  content: "";
}

.material-icons.child_friendly:before {
  content: "";
}

.material-icons.chrome_reader_mode:before {
  content: "";
}

.material-icons.church:before {
  content: "";
}

.material-icons.circle:before {
  content: "";
}

.material-icons.circle_notifications:before {
  content: "";
}

.material-icons.class:before {
  content: "";
}

.material-icons.clean_hands:before {
  content: "";
}

.material-icons.cleaning_services:before {
  content: "";
}

.material-icons.clear:before {
  content: "";
}

.material-icons.clear_all:before {
  content: "";
}

.material-icons.close:before {
  content: "";
}

.material-icons.close_fullscreen:before {
  content: "";
}

.material-icons.closed_caption:before {
  content: "";
}

.material-icons.closed_caption_disabled:before {
  content: "";
}

.material-icons.closed_caption_off:before {
  content: "";
}

.material-icons.cloud:before {
  content: "";
}

.material-icons.cloud_circle:before {
  content: "";
}

.material-icons.cloud_done:before {
  content: "";
}

.material-icons.cloud_download:before {
  content: "";
}

.material-icons.cloud_off:before {
  content: "";
}

.material-icons.cloud_queue:before {
  content: "";
}

.material-icons.cloud_sync:before {
  content: "";
}

.material-icons.cloud_upload:before {
  content: "";
}

.material-icons.cloudy_snowing:before {
  content: "";
}

.material-icons.co2:before {
  content: "";
}

.material-icons.co_present:before {
  content: "";
}

.material-icons.code:before {
  content: "";
}

.material-icons.code_off:before {
  content: "";
}

.material-icons.coffee:before {
  content: "";
}

.material-icons.coffee_maker:before {
  content: "";
}

.material-icons.collections:before {
  content: "";
}

.material-icons.collections_bookmark:before {
  content: "";
}

.material-icons.color_lens:before {
  content: "";
}

.material-icons.colorize:before {
  content: "";
}

.material-icons.comment:before {
  content: "";
}

.material-icons.comment_bank:before {
  content: "";
}

.material-icons.comments_disabled:before {
  content: "";
}

.material-icons.commit:before {
  content: "";
}

.material-icons.commute:before {
  content: "";
}

.material-icons.compare:before {
  content: "";
}

.material-icons.compare_arrows:before {
  content: "";
}

.material-icons.compass_calibration:before {
  content: "";
}

.material-icons.compost:before {
  content: "";
}

.material-icons.compress:before {
  content: "";
}

.material-icons.computer:before {
  content: "";
}

.material-icons.confirmation_num:before, .material-icons.confirmation_number:before {
  content: "";
}

.material-icons.connect_without_contact:before {
  content: "";
}

.material-icons.connected_tv:before {
  content: "";
}

.material-icons.connecting_airports:before {
  content: "";
}

.material-icons.construction:before {
  content: "";
}

.material-icons.contact_mail:before {
  content: "";
}

.material-icons.contact_page:before {
  content: "";
}

.material-icons.contact_phone:before {
  content: "";
}

.material-icons.contact_support:before {
  content: "";
}

.material-icons.contactless:before {
  content: "";
}

.material-icons.contacts:before {
  content: "";
}

.material-icons.content_copy:before {
  content: "";
}

.material-icons.content_cut:before {
  content: "";
}

.material-icons.content_paste:before {
  content: "";
}

.material-icons.content_paste_go:before {
  content: "";
}

.material-icons.content_paste_off:before {
  content: "";
}

.material-icons.content_paste_search:before {
  content: "";
}

.material-icons.contrast:before {
  content: "";
}

.material-icons.control_camera:before {
  content: "";
}

.material-icons.control_point:before {
  content: "";
}

.material-icons.control_point_duplicate:before {
  content: "";
}

.material-icons.cookie:before {
  content: "";
}

.material-icons.copy_all:before {
  content: "";
}

.material-icons.copyright:before {
  content: "";
}

.material-icons.coronavirus:before {
  content: "";
}

.material-icons.corporate_fare:before {
  content: "";
}

.material-icons.cottage:before {
  content: "";
}

.material-icons.countertops:before {
  content: "";
}

.material-icons.create:before {
  content: "";
}

.material-icons.create_new_folder:before {
  content: "";
}

.material-icons.credit_card:before {
  content: "";
}

.material-icons.credit_card_off:before {
  content: "";
}

.material-icons.credit_score:before {
  content: "";
}

.material-icons.crib:before {
  content: "";
}

.material-icons.crisis_alert:before {
  content: "";
}

.material-icons.crop:before {
  content: "";
}

.material-icons.crop_16_9:before {
  content: "";
}

.material-icons.crop_3_2:before {
  content: "";
}

.material-icons.crop_5_4:before {
  content: "";
}

.material-icons.crop_7_5:before {
  content: "";
}

.material-icons.crop_din:before {
  content: "";
}

.material-icons.crop_free:before {
  content: "";
}

.material-icons.crop_landscape:before {
  content: "";
}

.material-icons.crop_original:before {
  content: "";
}

.material-icons.crop_portrait:before {
  content: "";
}

.material-icons.crop_rotate:before {
  content: "";
}

.material-icons.crop_square:before {
  content: "";
}

.material-icons.cruelty_free:before {
  content: "";
}

.material-icons.css:before {
  content: "";
}

.material-icons.currency_bitcoin:before {
  content: "";
}

.material-icons.currency_exchange:before {
  content: "";
}

.material-icons.currency_franc:before {
  content: "";
}

.material-icons.currency_lira:before {
  content: "";
}

.material-icons.currency_pound:before {
  content: "";
}

.material-icons.currency_ruble:before {
  content: "";
}

.material-icons.currency_rupee:before {
  content: "";
}

.material-icons.currency_yen:before {
  content: "";
}

.material-icons.currency_yuan:before {
  content: "";
}

.material-icons.curtains:before {
  content: "";
}

.material-icons.curtains_closed:before {
  content: "";
}

.material-icons.cyclone:before {
  content: "";
}

.material-icons.dangerous:before {
  content: "";
}

.material-icons.dark_mode:before {
  content: "";
}

.material-icons.dashboard:before {
  content: "";
}

.material-icons.dashboard_customize:before {
  content: "";
}

.material-icons.data_array:before {
  content: "";
}

.material-icons.data_exploration:before {
  content: "";
}

.material-icons.data_object:before {
  content: "";
}

.material-icons.data_saver_off:before {
  content: "";
}

.material-icons.data_saver_on:before {
  content: "";
}

.material-icons.data_thresholding:before {
  content: "";
}

.material-icons.data_usage:before {
  content: "";
}

.material-icons.dataset:before {
  content: "";
}

.material-icons.dataset_linked:before {
  content: "";
}

.material-icons.date_range:before {
  content: "";
}

.material-icons.deblur:before {
  content: "";
}

.material-icons.deck:before {
  content: "";
}

.material-icons.dehaze:before {
  content: "";
}

.material-icons.delete:before {
  content: "";
}

.material-icons.delete_forever:before {
  content: "";
}

.material-icons.delete_outline:before {
  content: "";
}

.material-icons.delete_sweep:before {
  content: "";
}

.material-icons.delivery_dining:before {
  content: "";
}

.material-icons.density_large:before {
  content: "";
}

.material-icons.density_medium:before {
  content: "";
}

.material-icons.density_small:before {
  content: "";
}

.material-icons.departure_board:before {
  content: "";
}

.material-icons.description:before {
  content: "";
}

.material-icons.deselect:before {
  content: "";
}

.material-icons.design_services:before {
  content: "";
}

.material-icons.desk:before {
  content: "";
}

.material-icons.desktop_access_disabled:before {
  content: "";
}

.material-icons.desktop_mac:before {
  content: "";
}

.material-icons.desktop_windows:before {
  content: "";
}

.material-icons.details:before {
  content: "";
}

.material-icons.developer_board:before {
  content: "";
}

.material-icons.developer_board_off:before {
  content: "";
}

.material-icons.developer_mode:before {
  content: "";
}

.material-icons.device_hub:before {
  content: "";
}

.material-icons.device_thermostat:before {
  content: "";
}

.material-icons.device_unknown:before {
  content: "";
}

.material-icons.devices:before {
  content: "";
}

.material-icons.devices_fold:before {
  content: "";
}

.material-icons.devices_other:before {
  content: "";
}

.material-icons.dialer_sip:before {
  content: "";
}

.material-icons.dialpad:before {
  content: "";
}

.material-icons.diamond:before {
  content: "";
}

.material-icons.difference:before {
  content: "";
}

.material-icons.dining:before {
  content: "";
}

.material-icons.dinner_dining:before {
  content: "";
}

.material-icons.directions:before {
  content: "";
}

.material-icons.directions_bike:before {
  content: "";
}

.material-icons.directions_boat:before {
  content: "";
}

.material-icons.directions_boat_filled:before {
  content: "";
}

.material-icons.directions_bus:before {
  content: "";
}

.material-icons.directions_bus_filled:before {
  content: "";
}

.material-icons.directions_car:before {
  content: "";
}

.material-icons.directions_car_filled:before {
  content: "";
}

.material-icons.directions_ferry:before {
  content: "";
}

.material-icons.directions_off:before {
  content: "";
}

.material-icons.directions_railway:before {
  content: "";
}

.material-icons.directions_railway_filled:before {
  content: "";
}

.material-icons.directions_run:before {
  content: "";
}

.material-icons.directions_subway:before {
  content: "";
}

.material-icons.directions_subway_filled:before {
  content: "";
}

.material-icons.directions_train:before {
  content: "";
}

.material-icons.directions_transit:before {
  content: "";
}

.material-icons.directions_transit_filled:before {
  content: "";
}

.material-icons.directions_walk:before {
  content: "";
}

.material-icons.dirty_lens:before {
  content: "";
}

.material-icons.disabled_by_default:before {
  content: "";
}

.material-icons.disabled_visible:before {
  content: "";
}

.material-icons.disc_full:before {
  content: "";
}

.material-icons.discord:before {
  content: "";
}

.material-icons.discount:before {
  content: "";
}

.material-icons.display_settings:before {
  content: "";
}

.material-icons.dnd_forwardslash:before {
  content: "";
}

.material-icons.dns:before {
  content: "";
}

.material-icons.do_disturb:before {
  content: "";
}

.material-icons.do_disturb_alt:before {
  content: "";
}

.material-icons.do_disturb_off:before {
  content: "";
}

.material-icons.do_disturb_on:before {
  content: "";
}

.material-icons.do_not_disturb:before {
  content: "";
}

.material-icons.do_not_disturb_alt:before {
  content: "";
}

.material-icons.do_not_disturb_off:before {
  content: "";
}

.material-icons.do_not_disturb_on:before {
  content: "";
}

.material-icons.do_not_disturb_on_total_silence:before {
  content: "";
}

.material-icons.do_not_step:before {
  content: "";
}

.material-icons.do_not_touch:before {
  content: "";
}

.material-icons.dock:before {
  content: "";
}

.material-icons.document_scanner:before {
  content: "";
}

.material-icons.domain:before {
  content: "";
}

.material-icons.domain_add:before {
  content: "";
}

.material-icons.domain_disabled:before {
  content: "";
}

.material-icons.domain_verification:before {
  content: "";
}

.material-icons.done:before {
  content: "";
}

.material-icons.done_all:before {
  content: "";
}

.material-icons.done_outline:before {
  content: "";
}

.material-icons.donut_large:before {
  content: "";
}

.material-icons.donut_small:before {
  content: "";
}

.material-icons.door_back:before {
  content: "";
}

.material-icons.door_front:before {
  content: "";
}

.material-icons.door_sliding:before {
  content: "";
}

.material-icons.doorbell:before {
  content: "";
}

.material-icons.double_arrow:before {
  content: "";
}

.material-icons.downhill_skiing:before {
  content: "";
}

.material-icons.download:before {
  content: "";
}

.material-icons.download_done:before {
  content: "";
}

.material-icons.download_for_offline:before {
  content: "";
}

.material-icons.downloading:before {
  content: "";
}

.material-icons.drafts:before {
  content: "";
}

.material-icons.drag_handle:before {
  content: "";
}

.material-icons.drag_indicator:before {
  content: "";
}

.material-icons.draw:before {
  content: "";
}

.material-icons.drive_eta:before {
  content: "";
}

.material-icons.drive_file_move:before {
  content: "";
}

.material-icons.drive_file_move_outline:before {
  content: "";
}

.material-icons.drive_file_move_rtl:before {
  content: "";
}

.material-icons.drive_file_rename_outline:before {
  content: "";
}

.material-icons.drive_folder_upload:before {
  content: "";
}

.material-icons.dry:before {
  content: "";
}

.material-icons.dry_cleaning:before {
  content: "";
}

.material-icons.duo:before {
  content: "";
}

.material-icons.dvr:before {
  content: "";
}

.material-icons.dynamic_feed:before {
  content: "";
}

.material-icons.dynamic_form:before {
  content: "";
}

.material-icons.e_mobiledata:before {
  content: "";
}

.material-icons.earbuds:before {
  content: "";
}

.material-icons.earbuds_battery:before {
  content: "";
}

.material-icons.east:before {
  content: "";
}

.material-icons.eco:before {
  content: "";
}

.material-icons.edgesensor_high:before {
  content: "";
}

.material-icons.edgesensor_low:before {
  content: "";
}

.material-icons.edit:before {
  content: "";
}

.material-icons.edit_attributes:before {
  content: "";
}

.material-icons.edit_calendar:before {
  content: "";
}

.material-icons.edit_location:before {
  content: "";
}

.material-icons.edit_location_alt:before {
  content: "";
}

.material-icons.edit_note:before {
  content: "";
}

.material-icons.edit_notifications:before {
  content: "";
}

.material-icons.edit_off:before {
  content: "";
}

.material-icons.edit_road:before {
  content: "";
}

.material-icons.egg:before {
  content: "";
}

.material-icons.egg_alt:before {
  content: "";
}

.material-icons.eject:before {
  content: "";
}

.material-icons.elderly:before {
  content: "";
}

.material-icons.elderly_woman:before {
  content: "";
}

.material-icons.electric_bike:before {
  content: "";
}

.material-icons.electric_bolt:before {
  content: "";
}

.material-icons.electric_car:before {
  content: "";
}

.material-icons.electric_meter:before {
  content: "";
}

.material-icons.electric_moped:before {
  content: "";
}

.material-icons.electric_rickshaw:before {
  content: "";
}

.material-icons.electric_scooter:before {
  content: "";
}

.material-icons.electrical_services:before {
  content: "";
}

.material-icons.elevator:before {
  content: "";
}

.material-icons.email:before {
  content: "";
}

.material-icons.emergency:before {
  content: "";
}

.material-icons.emergency_recording:before {
  content: "";
}

.material-icons.emergency_share:before {
  content: "";
}

.material-icons.emoji_emotions:before {
  content: "";
}

.material-icons.emoji_events:before {
  content: "";
}

.material-icons.emoji_flags:before {
  content: "";
}

.material-icons.emoji_food_beverage:before {
  content: "";
}

.material-icons.emoji_nature:before {
  content: "";
}

.material-icons.emoji_objects:before {
  content: "";
}

.material-icons.emoji_people:before {
  content: "";
}

.material-icons.emoji_symbols:before {
  content: "";
}

.material-icons.emoji_transportation:before {
  content: "";
}

.material-icons.energy_savings_leaf:before {
  content: "";
}

.material-icons.engineering:before {
  content: "";
}

.material-icons.enhance_photo_translate:before {
  content: "";
}

.material-icons.enhanced_encryption:before {
  content: "";
}

.material-icons.equalizer:before {
  content: "";
}

.material-icons.error:before {
  content: "";
}

.material-icons.error_outline:before {
  content: "";
}

.material-icons.escalator:before {
  content: "";
}

.material-icons.escalator_warning:before {
  content: "";
}

.material-icons.euro:before {
  content: "";
}

.material-icons.euro_symbol:before {
  content: "";
}

.material-icons.ev_station:before {
  content: "";
}

.material-icons.event:before {
  content: "";
}

.material-icons.event_available:before {
  content: "";
}

.material-icons.event_busy:before {
  content: "";
}

.material-icons.event_note:before {
  content: "";
}

.material-icons.event_repeat:before {
  content: "";
}

.material-icons.event_seat:before {
  content: "";
}

.material-icons.exit_to_app:before {
  content: "";
}

.material-icons.expand:before {
  content: "";
}

.material-icons.expand_circle_down:before {
  content: "";
}

.material-icons.expand_less:before {
  content: "";
}

.material-icons.expand_more:before {
  content: "";
}

.material-icons.explicit:before {
  content: "";
}

.material-icons.explore:before {
  content: "";
}

.material-icons.explore_off:before {
  content: "";
}

.material-icons.exposure:before {
  content: "";
}

.material-icons.exposure_minus_1:before {
  content: "";
}

.material-icons.exposure_minus_2:before {
  content: "";
}

.material-icons.exposure_neg_1:before {
  content: "";
}

.material-icons.exposure_neg_2:before {
  content: "";
}

.material-icons.exposure_plus_1:before {
  content: "";
}

.material-icons.exposure_plus_2:before {
  content: "";
}

.material-icons.exposure_zero:before {
  content: "";
}

.material-icons.extension:before {
  content: "";
}

.material-icons.extension_off:before {
  content: "";
}

.material-icons.face:before {
  content: "";
}

.material-icons.face_retouching_natural:before {
  content: "";
}

.material-icons.face_retouching_off:before {
  content: "";
}

.material-icons.facebook:before {
  content: "";
}

.material-icons.fact_check:before {
  content: "";
}

.material-icons.factory:before {
  content: "";
}

.material-icons.family_restroom:before {
  content: "";
}

.material-icons.fast_forward:before {
  content: "";
}

.material-icons.fast_rewind:before {
  content: "";
}

.material-icons.fastfood:before {
  content: "";
}

.material-icons.favorite:before {
  content: "";
}

.material-icons.favorite_border:before, .material-icons.favorite_outline:before {
  content: "";
}

.material-icons.fax:before {
  content: "";
}

.material-icons.featured_play_list:before {
  content: "";
}

.material-icons.featured_video:before {
  content: "";
}

.material-icons.feed:before {
  content: "";
}

.material-icons.feedback:before {
  content: "";
}

.material-icons.female:before {
  content: "";
}

.material-icons.fence:before {
  content: "";
}

.material-icons.festival:before {
  content: "";
}

.material-icons.fiber_dvr:before {
  content: "";
}

.material-icons.fiber_manual_record:before {
  content: "";
}

.material-icons.fiber_new:before {
  content: "";
}

.material-icons.fiber_pin:before {
  content: "";
}

.material-icons.fiber_smart_record:before {
  content: "";
}

.material-icons.file_copy:before {
  content: "";
}

.material-icons.file_download:before {
  content: "";
}

.material-icons.file_download_done:before {
  content: "";
}

.material-icons.file_download_off:before {
  content: "";
}

.material-icons.file_open:before {
  content: "";
}

.material-icons.file_present:before {
  content: "";
}

.material-icons.file_upload:before {
  content: "";
}

.material-icons.filter:before {
  content: "";
}

.material-icons.filter_1:before {
  content: "";
}

.material-icons.filter_2:before {
  content: "";
}

.material-icons.filter_3:before {
  content: "";
}

.material-icons.filter_4:before {
  content: "";
}

.material-icons.filter_5:before {
  content: "";
}

.material-icons.filter_6:before {
  content: "";
}

.material-icons.filter_7:before {
  content: "";
}

.material-icons.filter_8:before {
  content: "";
}

.material-icons.filter_9:before {
  content: "";
}

.material-icons.filter_9_plus:before {
  content: "";
}

.material-icons.filter_alt:before {
  content: "";
}

.material-icons.filter_alt_off:before {
  content: "";
}

.material-icons.filter_b_and_w:before {
  content: "";
}

.material-icons.filter_center_focus:before {
  content: "";
}

.material-icons.filter_drama:before {
  content: "";
}

.material-icons.filter_frames:before {
  content: "";
}

.material-icons.filter_hdr:before {
  content: "";
}

.material-icons.filter_list:before {
  content: "";
}

.material-icons.filter_list_alt:before {
  content: "";
}

.material-icons.filter_list_off:before {
  content: "";
}

.material-icons.filter_none:before {
  content: "";
}

.material-icons.filter_tilt_shift:before {
  content: "";
}

.material-icons.filter_vintage:before {
  content: "";
}

.material-icons.find_in_page:before {
  content: "";
}

.material-icons.find_replace:before {
  content: "";
}

.material-icons.fingerprint:before {
  content: "";
}

.material-icons.fire_extinguisher:before {
  content: "";
}

.material-icons.fire_hydrant:before {
  content: "";
}

.material-icons.fire_hydrant_alt:before {
  content: "";
}

.material-icons.fire_truck:before {
  content: "";
}

.material-icons.fireplace:before {
  content: "";
}

.material-icons.first_page:before {
  content: "";
}

.material-icons.fit_screen:before {
  content: "";
}

.material-icons.fitbit:before {
  content: "";
}

.material-icons.fitness_center:before {
  content: "";
}

.material-icons.flag:before {
  content: "";
}

.material-icons.flag_circle:before {
  content: "";
}

.material-icons.flaky:before {
  content: "";
}

.material-icons.flare:before {
  content: "";
}

.material-icons.flash_auto:before {
  content: "";
}

.material-icons.flash_off:before {
  content: "";
}

.material-icons.flash_on:before {
  content: "";
}

.material-icons.flashlight_off:before {
  content: "";
}

.material-icons.flashlight_on:before {
  content: "";
}

.material-icons.flatware:before {
  content: "";
}

.material-icons.flight:before {
  content: "";
}

.material-icons.flight_class:before {
  content: "";
}

.material-icons.flight_land:before {
  content: "";
}

.material-icons.flight_takeoff:before {
  content: "";
}

.material-icons.flip:before {
  content: "";
}

.material-icons.flip_camera_android:before {
  content: "";
}

.material-icons.flip_camera_ios:before {
  content: "";
}

.material-icons.flip_to_back:before {
  content: "";
}

.material-icons.flip_to_front:before {
  content: "";
}

.material-icons.flood:before {
  content: "";
}

.material-icons.flourescent:before {
  content: "";
}

.material-icons.flutter_dash:before {
  content: "";
}

.material-icons.fmd_bad:before {
  content: "";
}

.material-icons.fmd_good:before {
  content: "";
}

.material-icons.foggy:before {
  content: "";
}

.material-icons.folder:before {
  content: "";
}

.material-icons.folder_copy:before {
  content: "";
}

.material-icons.folder_delete:before {
  content: "";
}

.material-icons.folder_off:before {
  content: "";
}

.material-icons.folder_open:before {
  content: "";
}

.material-icons.folder_shared:before {
  content: "";
}

.material-icons.folder_special:before {
  content: "";
}

.material-icons.folder_zip:before {
  content: "";
}

.material-icons.follow_the_signs:before {
  content: "";
}

.material-icons.font_download:before {
  content: "";
}

.material-icons.font_download_off:before {
  content: "";
}

.material-icons.food_bank:before {
  content: "";
}

.material-icons.forest:before {
  content: "";
}

.material-icons.fork_left:before {
  content: "";
}

.material-icons.fork_right:before {
  content: "";
}

.material-icons.format_align_center:before {
  content: "";
}

.material-icons.format_align_justify:before {
  content: "";
}

.material-icons.format_align_left:before {
  content: "";
}

.material-icons.format_align_right:before {
  content: "";
}

.material-icons.format_bold:before {
  content: "";
}

.material-icons.format_clear:before {
  content: "";
}

.material-icons.format_color_fill:before {
  content: "";
}

.material-icons.format_color_reset:before {
  content: "";
}

.material-icons.format_color_text:before {
  content: "";
}

.material-icons.format_indent_decrease:before {
  content: "";
}

.material-icons.format_indent_increase:before {
  content: "";
}

.material-icons.format_italic:before {
  content: "";
}

.material-icons.format_line_spacing:before {
  content: "";
}

.material-icons.format_list_bulleted:before {
  content: "";
}

.material-icons.format_list_numbered:before {
  content: "";
}

.material-icons.format_list_numbered_rtl:before {
  content: "";
}

.material-icons.format_overline:before {
  content: "";
}

.material-icons.format_paint:before {
  content: "";
}

.material-icons.format_quote:before {
  content: "";
}

.material-icons.format_shapes:before {
  content: "";
}

.material-icons.format_size:before {
  content: "";
}

.material-icons.format_strikethrough:before {
  content: "";
}

.material-icons.format_textdirection_l_to_r:before {
  content: "";
}

.material-icons.format_textdirection_r_to_l:before {
  content: "";
}

.material-icons.format_underline:before, .material-icons.format_underlined:before {
  content: "";
}

.material-icons.fort:before {
  content: "";
}

.material-icons.forum:before {
  content: "";
}

.material-icons.forward:before {
  content: "";
}

.material-icons.forward_10:before {
  content: "";
}

.material-icons.forward_30:before {
  content: "";
}

.material-icons.forward_5:before {
  content: "";
}

.material-icons.forward_to_inbox:before {
  content: "";
}

.material-icons.foundation:before {
  content: "";
}

.material-icons.free_breakfast:before {
  content: "";
}

.material-icons.free_cancellation:before {
  content: "";
}

.material-icons.front_hand:before {
  content: "";
}

.material-icons.fullscreen:before {
  content: "";
}

.material-icons.fullscreen_exit:before {
  content: "";
}

.material-icons.functions:before {
  content: "";
}

.material-icons.g_mobiledata:before {
  content: "";
}

.material-icons.g_translate:before {
  content: "";
}

.material-icons.gamepad:before {
  content: "";
}

.material-icons.games:before {
  content: "";
}

.material-icons.garage:before {
  content: "";
}

.material-icons.gas_meter:before {
  content: "";
}

.material-icons.gavel:before {
  content: "";
}

.material-icons.generating_tokens:before {
  content: "";
}

.material-icons.gesture:before {
  content: "";
}

.material-icons.get_app:before {
  content: "";
}

.material-icons.gif:before {
  content: "";
}

.material-icons.gif_box:before {
  content: "";
}

.material-icons.girl:before {
  content: "";
}

.material-icons.gite:before {
  content: "";
}

.material-icons.goat:before {
  content: "";
}

.material-icons.golf_course:before {
  content: "";
}

.material-icons.gpp_bad:before {
  content: "";
}

.material-icons.gpp_good:before {
  content: "";
}

.material-icons.gpp_maybe:before {
  content: "";
}

.material-icons.gps_fixed:before {
  content: "";
}

.material-icons.gps_not_fixed:before {
  content: "";
}

.material-icons.gps_off:before {
  content: "";
}

.material-icons.grade:before {
  content: "";
}

.material-icons.gradient:before {
  content: "";
}

.material-icons.grading:before {
  content: "";
}

.material-icons.grain:before {
  content: "";
}

.material-icons.graphic_eq:before {
  content: "";
}

.material-icons.grass:before {
  content: "";
}

.material-icons.grid_3x3:before {
  content: "";
}

.material-icons.grid_4x4:before {
  content: "";
}

.material-icons.grid_goldenratio:before {
  content: "";
}

.material-icons.grid_off:before {
  content: "";
}

.material-icons.grid_on:before {
  content: "";
}

.material-icons.grid_view:before {
  content: "";
}

.material-icons.group:before {
  content: "";
}

.material-icons.group_add:before {
  content: "";
}

.material-icons.group_off:before {
  content: "";
}

.material-icons.group_remove:before {
  content: "";
}

.material-icons.group_work:before {
  content: "";
}

.material-icons.groups:before {
  content: "";
}

.material-icons.h_mobiledata:before {
  content: "";
}

.material-icons.h_plus_mobiledata:before {
  content: "";
}

.material-icons.hail:before {
  content: "";
}

.material-icons.handshake:before {
  content: "";
}

.material-icons.handyman:before {
  content: "";
}

.material-icons.hardware:before {
  content: "";
}

.material-icons.hd:before {
  content: "";
}

.material-icons.hdr_auto:before {
  content: "";
}

.material-icons.hdr_auto_select:before {
  content: "";
}

.material-icons.hdr_enhanced_select:before {
  content: "";
}

.material-icons.hdr_off:before {
  content: "";
}

.material-icons.hdr_off_select:before {
  content: "";
}

.material-icons.hdr_on:before {
  content: "";
}

.material-icons.hdr_on_select:before {
  content: "";
}

.material-icons.hdr_plus:before {
  content: "";
}

.material-icons.hdr_strong:before {
  content: "";
}

.material-icons.hdr_weak:before {
  content: "";
}

.material-icons.headphones:before {
  content: "";
}

.material-icons.headphones_battery:before {
  content: "";
}

.material-icons.headset:before {
  content: "";
}

.material-icons.headset_mic:before {
  content: "";
}

.material-icons.headset_off:before {
  content: "";
}

.material-icons.healing:before {
  content: "";
}

.material-icons.health_and_safety:before {
  content: "";
}

.material-icons.hearing:before {
  content: "";
}

.material-icons.hearing_disabled:before {
  content: "";
}

.material-icons.heart_broken:before {
  content: "";
}

.material-icons.heat_pump:before {
  content: "";
}

.material-icons.height:before {
  content: "";
}

.material-icons.help:before {
  content: "";
}

.material-icons.help_center:before {
  content: "";
}

.material-icons.help_outline:before {
  content: "";
}

.material-icons.hevc:before {
  content: "";
}

.material-icons.hexagon:before {
  content: "";
}

.material-icons.hide_image:before {
  content: "";
}

.material-icons.hide_source:before {
  content: "";
}

.material-icons.high_quality:before {
  content: "";
}

.material-icons.highlight:before {
  content: "";
}

.material-icons.highlight_alt:before {
  content: "";
}

.material-icons.highlight_off:before, .material-icons.highlight_remove:before {
  content: "";
}

.material-icons.hiking:before {
  content: "";
}

.material-icons.history:before {
  content: "";
}

.material-icons.history_edu:before {
  content: "";
}

.material-icons.history_toggle_off:before {
  content: "";
}

.material-icons.hive:before {
  content: "";
}

.material-icons.hls:before {
  content: "";
}

.material-icons.hls_off:before {
  content: "";
}

.material-icons.holiday_village:before {
  content: "";
}

.material-icons.home:before {
  content: "";
}

.material-icons.home_filled:before {
  content: "";
}

.material-icons.home_max:before {
  content: "";
}

.material-icons.home_mini:before {
  content: "";
}

.material-icons.home_repair_service:before {
  content: "";
}

.material-icons.home_work:before {
  content: "";
}

.material-icons.horizontal_distribute:before {
  content: "";
}

.material-icons.horizontal_rule:before {
  content: "";
}

.material-icons.horizontal_split:before {
  content: "";
}

.material-icons.hot_tub:before {
  content: "";
}

.material-icons.hotel:before {
  content: "";
}

.material-icons.hotel_class:before {
  content: "";
}

.material-icons.hourglass_bottom:before {
  content: "";
}

.material-icons.hourglass_disabled:before {
  content: "";
}

.material-icons.hourglass_empty:before {
  content: "";
}

.material-icons.hourglass_full:before {
  content: "";
}

.material-icons.hourglass_top:before {
  content: "";
}

.material-icons.house:before {
  content: "";
}

.material-icons.house_siding:before {
  content: "";
}

.material-icons.houseboat:before {
  content: "";
}

.material-icons.how_to_reg:before {
  content: "";
}

.material-icons.how_to_vote:before {
  content: "";
}

.material-icons.html:before {
  content: "";
}

.material-icons.http:before {
  content: "";
}

.material-icons.https:before {
  content: "";
}

.material-icons.hub:before {
  content: "";
}

.material-icons.hvac:before {
  content: "";
}

.material-icons.ice_skating:before {
  content: "";
}

.material-icons.icecream:before {
  content: "";
}

.material-icons.image:before {
  content: "";
}

.material-icons.image_aspect_ratio:before {
  content: "";
}

.material-icons.image_not_supported:before {
  content: "";
}

.material-icons.image_search:before {
  content: "";
}

.material-icons.imagesearch_roller:before {
  content: "";
}

.material-icons.import_contacts:before {
  content: "";
}

.material-icons.import_export:before {
  content: "";
}

.material-icons.important_devices:before {
  content: "";
}

.material-icons.inbox:before {
  content: "";
}

.material-icons.incomplete_circle:before {
  content: "";
}

.material-icons.indeterminate_check_box:before {
  content: "";
}

.material-icons.info:before {
  content: "";
}

.material-icons.info_outline:before {
  content: "";
}

.material-icons.input:before {
  content: "";
}

.material-icons.insert_chart:before {
  content: "";
}

.material-icons.insert_chart_outlined:before {
  content: "";
}

.material-icons.insert_comment:before {
  content: "";
}

.material-icons.insert_drive_file:before {
  content: "";
}

.material-icons.insert_emoticon:before {
  content: "";
}

.material-icons.insert_invitation:before {
  content: "";
}

.material-icons.insert_link:before {
  content: "";
}

.material-icons.insert_page_break:before {
  content: "";
}

.material-icons.insert_photo:before {
  content: "";
}

.material-icons.insights:before {
  content: "";
}

.material-icons.install_desktop:before {
  content: "";
}

.material-icons.install_mobile:before {
  content: "";
}

.material-icons.integration_instructions:before {
  content: "";
}

.material-icons.interests:before {
  content: "";
}

.material-icons.interpreter_mode:before {
  content: "";
}

.material-icons.inventory:before {
  content: "";
}

.material-icons.inventory_2:before {
  content: "";
}

.material-icons.invert_colors:before {
  content: "";
}

.material-icons.invert_colors_off:before {
  content: "";
}

.material-icons.invert_colors_on:before {
  content: "";
}

.material-icons.ios_share:before {
  content: "";
}

.material-icons.iron:before {
  content: "";
}

.material-icons.iso:before {
  content: "";
}

.material-icons.javascript:before {
  content: "";
}

.material-icons.join_full:before {
  content: "";
}

.material-icons.join_inner:before {
  content: "";
}

.material-icons.join_left:before {
  content: "";
}

.material-icons.join_right:before {
  content: "";
}

.material-icons.kayaking:before {
  content: "";
}

.material-icons.kebab_dining:before {
  content: "";
}

.material-icons.key:before {
  content: "";
}

.material-icons.key_off:before {
  content: "";
}

.material-icons.keyboard:before {
  content: "";
}

.material-icons.keyboard_alt:before {
  content: "";
}

.material-icons.keyboard_arrow_down:before {
  content: "";
}

.material-icons.keyboard_arrow_left:before {
  content: "";
}

.material-icons.keyboard_arrow_right:before {
  content: "";
}

.material-icons.keyboard_arrow_up:before {
  content: "";
}

.material-icons.keyboard_backspace:before {
  content: "";
}

.material-icons.keyboard_capslock:before {
  content: "";
}

.material-icons.keyboard_command:before {
  content: "";
}

.material-icons.keyboard_command_key:before {
  content: "";
}

.material-icons.keyboard_control:before {
  content: "";
}

.material-icons.keyboard_control_key:before {
  content: "";
}

.material-icons.keyboard_double_arrow_down:before {
  content: "";
}

.material-icons.keyboard_double_arrow_left:before {
  content: "";
}

.material-icons.keyboard_double_arrow_right:before {
  content: "";
}

.material-icons.keyboard_double_arrow_up:before {
  content: "";
}

.material-icons.keyboard_hide:before {
  content: "";
}

.material-icons.keyboard_option:before {
  content: "";
}

.material-icons.keyboard_option_key:before {
  content: "";
}

.material-icons.keyboard_return:before {
  content: "";
}

.material-icons.keyboard_tab:before {
  content: "";
}

.material-icons.keyboard_voice:before {
  content: "";
}

.material-icons.king_bed:before {
  content: "";
}

.material-icons.kitchen:before {
  content: "";
}

.material-icons.kitesurfing:before {
  content: "";
}

.material-icons.label:before {
  content: "";
}

.material-icons.label_important:before {
  content: "";
}

.material-icons.label_important_outline:before {
  content: "";
}

.material-icons.label_off:before {
  content: "";
}

.material-icons.label_outline:before {
  content: "";
}

.material-icons.lan:before {
  content: "";
}

.material-icons.landscape:before {
  content: "";
}

.material-icons.landslide:before {
  content: "";
}

.material-icons.language:before {
  content: "";
}

.material-icons.laptop:before {
  content: "";
}

.material-icons.laptop_chromebook:before {
  content: "";
}

.material-icons.laptop_mac:before {
  content: "";
}

.material-icons.laptop_windows:before {
  content: "";
}

.material-icons.last_page:before {
  content: "";
}

.material-icons.launch:before {
  content: "";
}

.material-icons.layers:before {
  content: "";
}

.material-icons.layers_clear:before {
  content: "";
}

.material-icons.leaderboard:before {
  content: "";
}

.material-icons.leak_add:before {
  content: "";
}

.material-icons.leak_remove:before {
  content: "";
}

.material-icons.leave_bags_at_home:before {
  content: "";
}

.material-icons.legend_toggle:before {
  content: "";
}

.material-icons.lens:before {
  content: "";
}

.material-icons.lens_blur:before {
  content: "";
}

.material-icons.library_add:before {
  content: "";
}

.material-icons.library_add_check:before {
  content: "";
}

.material-icons.library_books:before {
  content: "";
}

.material-icons.library_music:before {
  content: "";
}

.material-icons.light:before {
  content: "";
}

.material-icons.light_mode:before {
  content: "";
}

.material-icons.lightbulb:before {
  content: "";
}

.material-icons.lightbulb_circle:before {
  content: "";
}

.material-icons.lightbulb_outline:before {
  content: "";
}

.material-icons.line_axis:before {
  content: "";
}

.material-icons.line_style:before {
  content: "";
}

.material-icons.line_weight:before {
  content: "";
}

.material-icons.linear_scale:before {
  content: "";
}

.material-icons.link:before {
  content: "";
}

.material-icons.link_off:before {
  content: "";
}

.material-icons.linked_camera:before {
  content: "";
}

.material-icons.liquor:before {
  content: "";
}

.material-icons.list:before {
  content: "";
}

.material-icons.list_alt:before {
  content: "";
}

.material-icons.live_help:before {
  content: "";
}

.material-icons.live_tv:before {
  content: "";
}

.material-icons.living:before {
  content: "";
}

.material-icons.local_activity:before {
  content: "";
}

.material-icons.local_airport:before {
  content: "";
}

.material-icons.local_atm:before {
  content: "";
}

.material-icons.local_attraction:before {
  content: "";
}

.material-icons.local_bar:before {
  content: "";
}

.material-icons.local_cafe:before {
  content: "";
}

.material-icons.local_car_wash:before {
  content: "";
}

.material-icons.local_convenience_store:before {
  content: "";
}

.material-icons.local_dining:before {
  content: "";
}

.material-icons.local_drink:before {
  content: "";
}

.material-icons.local_fire_department:before {
  content: "";
}

.material-icons.local_florist:before {
  content: "";
}

.material-icons.local_gas_station:before {
  content: "";
}

.material-icons.local_grocery_store:before {
  content: "";
}

.material-icons.local_hospital:before {
  content: "";
}

.material-icons.local_hotel:before {
  content: "";
}

.material-icons.local_laundry_service:before {
  content: "";
}

.material-icons.local_library:before {
  content: "";
}

.material-icons.local_mall:before {
  content: "";
}

.material-icons.local_movies:before {
  content: "";
}

.material-icons.local_offer:before {
  content: "";
}

.material-icons.local_parking:before {
  content: "";
}

.material-icons.local_pharmacy:before {
  content: "";
}

.material-icons.local_phone:before {
  content: "";
}

.material-icons.local_pizza:before {
  content: "";
}

.material-icons.local_play:before {
  content: "";
}

.material-icons.local_police:before {
  content: "";
}

.material-icons.local_post_office:before {
  content: "";
}

.material-icons.local_print_shop:before, .material-icons.local_printshop:before {
  content: "";
}

.material-icons.local_restaurant:before {
  content: "";
}

.material-icons.local_see:before {
  content: "";
}

.material-icons.local_shipping:before {
  content: "";
}

.material-icons.local_taxi:before {
  content: "";
}

.material-icons.location_city:before {
  content: "";
}

.material-icons.location_disabled:before {
  content: "";
}

.material-icons.location_history:before {
  content: "";
}

.material-icons.location_off:before {
  content: "";
}

.material-icons.location_on:before {
  content: "";
}

.material-icons.location_pin:before {
  content: "";
}

.material-icons.location_searching:before {
  content: "";
}

.material-icons.lock:before {
  content: "";
}

.material-icons.lock_clock:before {
  content: "";
}

.material-icons.lock_open:before {
  content: "";
}

.material-icons.lock_outline:before {
  content: "";
}

.material-icons.lock_person:before {
  content: "";
}

.material-icons.lock_reset:before {
  content: "";
}

.material-icons.login:before {
  content: "";
}

.material-icons.logo_dev:before {
  content: "";
}

.material-icons.logout:before {
  content: "";
}

.material-icons.looks:before {
  content: "";
}

.material-icons.looks_3:before {
  content: "";
}

.material-icons.looks_4:before {
  content: "";
}

.material-icons.looks_5:before {
  content: "";
}

.material-icons.looks_6:before {
  content: "";
}

.material-icons.looks_one:before {
  content: "";
}

.material-icons.looks_two:before {
  content: "";
}

.material-icons.loop:before {
  content: "";
}

.material-icons.loupe:before {
  content: "";
}

.material-icons.low_priority:before {
  content: "";
}

.material-icons.loyalty:before {
  content: "";
}

.material-icons.lte_mobiledata:before {
  content: "";
}

.material-icons.lte_plus_mobiledata:before {
  content: "";
}

.material-icons.luggage:before {
  content: "";
}

.material-icons.lunch_dining:before {
  content: "";
}

.material-icons.lyrics:before {
  content: "";
}

.material-icons.mail:before {
  content: "";
}

.material-icons.mail_lock:before {
  content: "";
}

.material-icons.mail_outline:before {
  content: "";
}

.material-icons.male:before {
  content: "";
}

.material-icons.man:before {
  content: "";
}

.material-icons.manage_accounts:before {
  content: "";
}

.material-icons.manage_history:before {
  content: "";
}

.material-icons.manage_search:before {
  content: "";
}

.material-icons.map:before {
  content: "";
}

.material-icons.maps_home_work:before {
  content: "";
}

.material-icons.maps_ugc:before {
  content: "";
}

.material-icons.margin:before {
  content: "";
}

.material-icons.mark_as_unread:before {
  content: "";
}

.material-icons.mark_chat_read:before {
  content: "";
}

.material-icons.mark_chat_unread:before {
  content: "";
}

.material-icons.mark_email_read:before {
  content: "";
}

.material-icons.mark_email_unread:before {
  content: "";
}

.material-icons.mark_unread_chat_alt:before {
  content: "";
}

.material-icons.markunread:before {
  content: "";
}

.material-icons.markunread_mailbox:before {
  content: "";
}

.material-icons.masks:before {
  content: "";
}

.material-icons.maximize:before {
  content: "";
}

.material-icons.media_bluetooth_off:before {
  content: "";
}

.material-icons.media_bluetooth_on:before {
  content: "";
}

.material-icons.mediation:before {
  content: "";
}

.material-icons.medical_information:before {
  content: "";
}

.material-icons.medical_services:before {
  content: "";
}

.material-icons.medication:before {
  content: "";
}

.material-icons.medication_liquid:before {
  content: "";
}

.material-icons.meeting_room:before {
  content: "";
}

.material-icons.memory:before {
  content: "";
}

.material-icons.menu:before {
  content: "";
}

.material-icons.menu_book:before {
  content: "";
}

.material-icons.menu_open:before {
  content: "";
}

.material-icons.merge:before {
  content: "";
}

.material-icons.merge_type:before {
  content: "";
}

.material-icons.message:before {
  content: "";
}

.material-icons.messenger:before {
  content: "";
}

.material-icons.messenger_outline:before {
  content: "";
}

.material-icons.mic:before {
  content: "";
}

.material-icons.mic_external_off:before {
  content: "";
}

.material-icons.mic_external_on:before {
  content: "";
}

.material-icons.mic_none:before {
  content: "";
}

.material-icons.mic_off:before {
  content: "";
}

.material-icons.microwave:before {
  content: "";
}

.material-icons.military_tech:before {
  content: "";
}

.material-icons.minimize:before {
  content: "";
}

.material-icons.minor_crash:before {
  content: "";
}

.material-icons.miscellaneous_services:before {
  content: "";
}

.material-icons.missed_video_call:before {
  content: "";
}

.material-icons.mms:before {
  content: "";
}

.material-icons.mobile_friendly:before {
  content: "";
}

.material-icons.mobile_off:before {
  content: "";
}

.material-icons.mobile_screen_share:before {
  content: "";
}

.material-icons.mobiledata_off:before {
  content: "";
}

.material-icons.mode:before {
  content: "";
}

.material-icons.mode_comment:before {
  content: "";
}

.material-icons.mode_edit:before {
  content: "";
}

.material-icons.mode_edit_outline:before {
  content: "";
}

.material-icons.mode_fan_off:before {
  content: "";
}

.material-icons.mode_night:before {
  content: "";
}

.material-icons.mode_of_travel:before {
  content: "";
}

.material-icons.mode_standby:before {
  content: "";
}

.material-icons.model_training:before {
  content: "";
}

.material-icons.monetization_on:before {
  content: "";
}

.material-icons.money:before {
  content: "";
}

.material-icons.money_off:before {
  content: "";
}

.material-icons.money_off_csred:before {
  content: "";
}

.material-icons.monitor:before {
  content: "";
}

.material-icons.monitor_heart:before {
  content: "";
}

.material-icons.monitor_weight:before {
  content: "";
}

.material-icons.monochrome_photos:before {
  content: "";
}

.material-icons.mood:before {
  content: "";
}

.material-icons.mood_bad:before {
  content: "";
}

.material-icons.moped:before {
  content: "";
}

.material-icons.more:before {
  content: "";
}

.material-icons.more_horiz:before {
  content: "";
}

.material-icons.more_time:before {
  content: "";
}

.material-icons.more_vert:before {
  content: "";
}

.material-icons.mosque:before {
  content: "";
}

.material-icons.motion_photos_auto:before {
  content: "";
}

.material-icons.motion_photos_off:before {
  content: "";
}

.material-icons.motion_photos_on:before {
  content: "";
}

.material-icons.motion_photos_pause:before {
  content: "";
}

.material-icons.motion_photos_paused:before {
  content: "";
}

.material-icons.motorcycle:before {
  content: "";
}

.material-icons.mouse:before {
  content: "";
}

.material-icons.move_down:before {
  content: "";
}

.material-icons.move_to_inbox:before {
  content: "";
}

.material-icons.move_up:before {
  content: "";
}

.material-icons.movie:before {
  content: "";
}

.material-icons.movie_creation:before {
  content: "";
}

.material-icons.movie_filter:before {
  content: "";
}

.material-icons.moving:before {
  content: "";
}

.material-icons.mp:before {
  content: "";
}

.material-icons.multiline_chart:before {
  content: "";
}

.material-icons.multiple_stop:before {
  content: "";
}

.material-icons.multitrack_audio:before {
  content: "";
}

.material-icons.museum:before {
  content: "";
}

.material-icons.music_note:before {
  content: "";
}

.material-icons.music_off:before {
  content: "";
}

.material-icons.music_video:before {
  content: "";
}

.material-icons.my_library_add:before {
  content: "";
}

.material-icons.my_library_books:before {
  content: "";
}

.material-icons.my_library_music:before {
  content: "";
}

.material-icons.my_location:before {
  content: "";
}

.material-icons.nat:before {
  content: "";
}

.material-icons.nature:before {
  content: "";
}

.material-icons.nature_people:before {
  content: "";
}

.material-icons.navigate_before:before {
  content: "";
}

.material-icons.navigate_next:before {
  content: "";
}

.material-icons.navigation:before {
  content: "";
}

.material-icons.near_me:before {
  content: "";
}

.material-icons.near_me_disabled:before {
  content: "";
}

.material-icons.nearby_error:before {
  content: "";
}

.material-icons.nearby_off:before {
  content: "";
}

.material-icons.nest_cam_wired_stand:before {
  content: "";
}

.material-icons.network_cell:before {
  content: "";
}

.material-icons.network_check:before {
  content: "";
}

.material-icons.network_locked:before {
  content: "";
}

.material-icons.network_ping:before {
  content: "";
}

.material-icons.network_wifi:before {
  content: "";
}

.material-icons.network_wifi_1_bar:before {
  content: "";
}

.material-icons.network_wifi_2_bar:before {
  content: "";
}

.material-icons.network_wifi_3_bar:before {
  content: "";
}

.material-icons.new_label:before {
  content: "";
}

.material-icons.new_releases:before {
  content: "";
}

.material-icons.newspaper:before {
  content: "";
}

.material-icons.next_plan:before {
  content: "";
}

.material-icons.next_week:before {
  content: "";
}

.material-icons.nfc:before {
  content: "";
}

.material-icons.night_shelter:before {
  content: "";
}

.material-icons.nightlife:before {
  content: "";
}

.material-icons.nightlight:before {
  content: "";
}

.material-icons.nightlight_round:before {
  content: "";
}

.material-icons.nights_stay:before {
  content: "";
}

.material-icons.no_accounts:before {
  content: "";
}

.material-icons.no_adult_content:before {
  content: "";
}

.material-icons.no_backpack:before {
  content: "";
}

.material-icons.no_cell:before {
  content: "";
}

.material-icons.no_crash:before {
  content: "";
}

.material-icons.no_drinks:before {
  content: "";
}

.material-icons.no_encryption:before {
  content: "";
}

.material-icons.no_encryption_gmailerrorred:before {
  content: "";
}

.material-icons.no_flash:before {
  content: "";
}

.material-icons.no_food:before {
  content: "";
}

.material-icons.no_luggage:before {
  content: "";
}

.material-icons.no_meals:before {
  content: "";
}

.material-icons.no_meals_ouline:before {
  content: "";
}

.material-icons.no_meeting_room:before {
  content: "";
}

.material-icons.no_photography:before {
  content: "";
}

.material-icons.no_sim:before {
  content: "";
}

.material-icons.no_stroller:before {
  content: "";
}

.material-icons.no_transfer:before {
  content: "";
}

.material-icons.noise_aware:before {
  content: "";
}

.material-icons.noise_control_off:before {
  content: "";
}

.material-icons.nordic_walking:before {
  content: "";
}

.material-icons.north:before {
  content: "";
}

.material-icons.north_east:before {
  content: "";
}

.material-icons.north_west:before {
  content: "";
}

.material-icons.not_accessible:before {
  content: "";
}

.material-icons.not_interested:before {
  content: "";
}

.material-icons.not_listed_location:before {
  content: "";
}

.material-icons.not_started:before {
  content: "";
}

.material-icons.note:before {
  content: "";
}

.material-icons.note_add:before {
  content: "";
}

.material-icons.note_alt:before {
  content: "";
}

.material-icons.notes:before {
  content: "";
}

.material-icons.notification_add:before {
  content: "";
}

.material-icons.notification_important:before {
  content: "";
}

.material-icons.notifications:before {
  content: "";
}

.material-icons.notifications_active:before {
  content: "";
}

.material-icons.notifications_none:before {
  content: "";
}

.material-icons.notifications_off:before {
  content: "";
}

.material-icons.notifications_on:before {
  content: "";
}

.material-icons.notifications_paused:before {
  content: "";
}

.material-icons.now_wallpaper:before {
  content: "";
}

.material-icons.now_widgets:before {
  content: "";
}

.material-icons.numbers:before {
  content: "";
}

.material-icons.offline_bolt:before {
  content: "";
}

.material-icons.offline_pin:before {
  content: "";
}

.material-icons.offline_share:before {
  content: "";
}

.material-icons.oil_barrel:before {
  content: "";
}

.material-icons.on_device_training:before {
  content: "";
}

.material-icons.ondemand_video:before {
  content: "";
}

.material-icons.online_prediction:before {
  content: "";
}

.material-icons.opacity:before {
  content: "";
}

.material-icons.open_in_browser:before {
  content: "";
}

.material-icons.open_in_full:before {
  content: "";
}

.material-icons.open_in_new:before {
  content: "";
}

.material-icons.open_in_new_off:before {
  content: "";
}

.material-icons.open_with:before {
  content: "";
}

.material-icons.other_houses:before {
  content: "";
}

.material-icons.outbond:before {
  content: "";
}

.material-icons.outbound:before {
  content: "";
}

.material-icons.outbox:before {
  content: "";
}

.material-icons.outdoor_grill:before {
  content: "";
}

.material-icons.outgoing_mail:before {
  content: "";
}

.material-icons.outlet:before {
  content: "";
}

.material-icons.outlined_flag:before {
  content: "";
}

.material-icons.output:before {
  content: "";
}

.material-icons.padding:before {
  content: "";
}

.material-icons.pages:before {
  content: "";
}

.material-icons.pageview:before {
  content: "";
}

.material-icons.paid:before {
  content: "";
}

.material-icons.palette:before {
  content: "";
}

.material-icons.pan_tool:before {
  content: "";
}

.material-icons.pan_tool_alt:before {
  content: "";
}

.material-icons.panorama:before {
  content: "";
}

.material-icons.panorama_fish_eye:before, .material-icons.panorama_fisheye:before {
  content: "";
}

.material-icons.panorama_horizontal:before {
  content: "";
}

.material-icons.panorama_horizontal_select:before {
  content: "";
}

.material-icons.panorama_photosphere:before {
  content: "";
}

.material-icons.panorama_photosphere_select:before {
  content: "";
}

.material-icons.panorama_vertical:before {
  content: "";
}

.material-icons.panorama_vertical_select:before {
  content: "";
}

.material-icons.panorama_wide_angle:before {
  content: "";
}

.material-icons.panorama_wide_angle_select:before {
  content: "";
}

.material-icons.paragliding:before {
  content: "";
}

.material-icons.park:before {
  content: "";
}

.material-icons.party_mode:before {
  content: "";
}

.material-icons.password:before {
  content: "";
}

.material-icons.pattern:before {
  content: "";
}

.material-icons.pause:before {
  content: "";
}

.material-icons.pause_circle:before {
  content: "";
}

.material-icons.pause_circle_filled:before {
  content: "";
}

.material-icons.pause_circle_outline:before {
  content: "";
}

.material-icons.pause_presentation:before {
  content: "";
}

.material-icons.payment:before {
  content: "";
}

.material-icons.payments:before {
  content: "";
}

.material-icons.paypal:before {
  content: "";
}

.material-icons.pedal_bike:before {
  content: "";
}

.material-icons.pending:before {
  content: "";
}

.material-icons.pending_actions:before {
  content: "";
}

.material-icons.pentagon:before {
  content: "";
}

.material-icons.people:before {
  content: "";
}

.material-icons.people_alt:before {
  content: "";
}

.material-icons.people_outline:before {
  content: "";
}

.material-icons.percent:before {
  content: "";
}

.material-icons.perm_camera_mic:before {
  content: "";
}

.material-icons.perm_contact_cal:before, .material-icons.perm_contact_calendar:before {
  content: "";
}

.material-icons.perm_data_setting:before {
  content: "";
}

.material-icons.perm_device_info:before, .material-icons.perm_device_information:before {
  content: "";
}

.material-icons.perm_identity:before {
  content: "";
}

.material-icons.perm_media:before {
  content: "";
}

.material-icons.perm_phone_msg:before {
  content: "";
}

.material-icons.perm_scan_wifi:before {
  content: "";
}

.material-icons.person:before {
  content: "";
}

.material-icons.person_add:before {
  content: "";
}

.material-icons.person_add_alt:before {
  content: "";
}

.material-icons.person_add_alt_1:before {
  content: "";
}

.material-icons.person_add_disabled:before {
  content: "";
}

.material-icons.person_off:before {
  content: "";
}

.material-icons.person_outline:before {
  content: "";
}

.material-icons.person_pin:before {
  content: "";
}

.material-icons.person_pin_circle:before {
  content: "";
}

.material-icons.person_remove:before {
  content: "";
}

.material-icons.person_remove_alt_1:before {
  content: "";
}

.material-icons.person_search:before {
  content: "";
}

.material-icons.personal_injury:before {
  content: "";
}

.material-icons.personal_video:before {
  content: "";
}

.material-icons.pest_control:before {
  content: "";
}

.material-icons.pest_control_rodent:before {
  content: "";
}

.material-icons.pets:before {
  content: "";
}

.material-icons.phishing:before {
  content: "";
}

.material-icons.phone:before {
  content: "";
}

.material-icons.phone_android:before {
  content: "";
}

.material-icons.phone_bluetooth_speaker:before {
  content: "";
}

.material-icons.phone_callback:before {
  content: "";
}

.material-icons.phone_disabled:before {
  content: "";
}

.material-icons.phone_enabled:before {
  content: "";
}

.material-icons.phone_forwarded:before {
  content: "";
}

.material-icons.phone_in_talk:before {
  content: "";
}

.material-icons.phone_iphone:before {
  content: "";
}

.material-icons.phone_locked:before {
  content: "";
}

.material-icons.phone_missed:before {
  content: "";
}

.material-icons.phone_paused:before {
  content: "";
}

.material-icons.phonelink:before {
  content: "";
}

.material-icons.phonelink_erase:before {
  content: "";
}

.material-icons.phonelink_lock:before {
  content: "";
}

.material-icons.phonelink_off:before {
  content: "";
}

.material-icons.phonelink_ring:before {
  content: "";
}

.material-icons.phonelink_setup:before {
  content: "";
}

.material-icons.photo:before {
  content: "";
}

.material-icons.photo_album:before {
  content: "";
}

.material-icons.photo_camera:before {
  content: "";
}

.material-icons.photo_camera_back:before {
  content: "";
}

.material-icons.photo_camera_front:before {
  content: "";
}

.material-icons.photo_filter:before {
  content: "";
}

.material-icons.photo_library:before {
  content: "";
}

.material-icons.photo_size_select_actual:before {
  content: "";
}

.material-icons.photo_size_select_large:before {
  content: "";
}

.material-icons.photo_size_select_small:before {
  content: "";
}

.material-icons.php:before {
  content: "";
}

.material-icons.piano:before {
  content: "";
}

.material-icons.piano_off:before {
  content: "";
}

.material-icons.picture_as_pdf:before {
  content: "";
}

.material-icons.picture_in_picture:before {
  content: "";
}

.material-icons.picture_in_picture_alt:before {
  content: "";
}

.material-icons.pie_chart:before {
  content: "";
}

.material-icons.pie_chart_outline:before {
  content: "";
}

.material-icons.pie_chart_outlined:before {
  content: "";
}

.material-icons.pin:before {
  content: "";
}

.material-icons.pin_drop:before {
  content: "";
}

.material-icons.pin_end:before {
  content: "";
}

.material-icons.pin_invoke:before {
  content: "";
}

.material-icons.pinch:before {
  content: "";
}

.material-icons.pivot_table_chart:before {
  content: "";
}

.material-icons.pix:before {
  content: "";
}

.material-icons.place:before {
  content: "";
}

.material-icons.plagiarism:before {
  content: "";
}

.material-icons.play_arrow:before {
  content: "";
}

.material-icons.play_circle:before {
  content: "";
}

.material-icons.play_circle_fill:before, .material-icons.play_circle_filled:before {
  content: "";
}

.material-icons.play_circle_outline:before {
  content: "";
}

.material-icons.play_disabled:before {
  content: "";
}

.material-icons.play_for_work:before {
  content: "";
}

.material-icons.play_lesson:before {
  content: "";
}

.material-icons.playlist_add:before {
  content: "";
}

.material-icons.playlist_add_check:before {
  content: "";
}

.material-icons.playlist_add_check_circle:before {
  content: "";
}

.material-icons.playlist_add_circle:before {
  content: "";
}

.material-icons.playlist_play:before {
  content: "";
}

.material-icons.playlist_remove:before {
  content: "";
}

.material-icons.plumbing:before {
  content: "";
}

.material-icons.plus_one:before {
  content: "";
}

.material-icons.podcasts:before {
  content: "";
}

.material-icons.point_of_sale:before {
  content: "";
}

.material-icons.policy:before {
  content: "";
}

.material-icons.poll:before {
  content: "";
}

.material-icons.polyline:before {
  content: "";
}

.material-icons.polymer:before {
  content: "";
}

.material-icons.pool:before {
  content: "";
}

.material-icons.portable_wifi_off:before {
  content: "";
}

.material-icons.portrait:before {
  content: "";
}

.material-icons.post_add:before {
  content: "";
}

.material-icons.power:before {
  content: "";
}

.material-icons.power_input:before {
  content: "";
}

.material-icons.power_off:before {
  content: "";
}

.material-icons.power_settings_new:before {
  content: "";
}

.material-icons.precision_manufacturing:before {
  content: "";
}

.material-icons.pregnant_woman:before {
  content: "";
}

.material-icons.present_to_all:before {
  content: "";
}

.material-icons.preview:before {
  content: "";
}

.material-icons.price_change:before {
  content: "";
}

.material-icons.price_check:before {
  content: "";
}

.material-icons.print:before {
  content: "";
}

.material-icons.print_disabled:before {
  content: "";
}

.material-icons.priority_high:before {
  content: "";
}

.material-icons.privacy_tip:before {
  content: "";
}

.material-icons.private_connectivity:before {
  content: "";
}

.material-icons.production_quantity_limits:before {
  content: "";
}

.material-icons.propane:before {
  content: "";
}

.material-icons.propane_tank:before {
  content: "";
}

.material-icons.psychology:before {
  content: "";
}

.material-icons.psychology_alt:before {
  content: "";
}

.material-icons.public:before {
  content: "";
}

.material-icons.public_off:before {
  content: "";
}

.material-icons.publish:before {
  content: "";
}

.material-icons.published_with_changes:before {
  content: "";
}

.material-icons.punch_clock:before {
  content: "";
}

.material-icons.push_pin:before {
  content: "";
}

.material-icons.qr_code:before {
  content: "";
}

.material-icons.qr_code_2:before {
  content: "";
}

.material-icons.qr_code_scanner:before {
  content: "";
}

.material-icons.query_builder:before {
  content: "";
}

.material-icons.query_stats:before {
  content: "";
}

.material-icons.question_answer:before {
  content: "";
}

.material-icons.question_mark:before {
  content: "";
}

.material-icons.queue:before {
  content: "";
}

.material-icons.queue_music:before {
  content: "";
}

.material-icons.queue_play_next:before {
  content: "";
}

.material-icons.quick_contacts_dialer:before {
  content: "";
}

.material-icons.quick_contacts_mail:before {
  content: "";
}

.material-icons.quickreply:before {
  content: "";
}

.material-icons.quiz:before {
  content: "";
}

.material-icons.quora:before {
  content: "";
}

.material-icons.r_mobiledata:before {
  content: "";
}

.material-icons.radar:before {
  content: "";
}

.material-icons.radio:before {
  content: "";
}

.material-icons.radio_button_checked:before {
  content: "";
}

.material-icons.radio_button_off:before {
  content: "";
}

.material-icons.radio_button_on:before {
  content: "";
}

.material-icons.radio_button_unchecked:before {
  content: "";
}

.material-icons.railway_alert:before {
  content: "";
}

.material-icons.ramen_dining:before {
  content: "";
}

.material-icons.ramp_left:before {
  content: "";
}

.material-icons.ramp_right:before {
  content: "";
}

.material-icons.rate_review:before {
  content: "";
}

.material-icons.raw_off:before {
  content: "";
}

.material-icons.raw_on:before {
  content: "";
}

.material-icons.read_more:before {
  content: "";
}

.material-icons.real_estate_agent:before {
  content: "";
}

.material-icons.receipt:before {
  content: "";
}

.material-icons.receipt_long:before {
  content: "";
}

.material-icons.recent_actors:before {
  content: "";
}

.material-icons.recommend:before {
  content: "";
}

.material-icons.record_voice_over:before {
  content: "";
}

.material-icons.rectangle:before {
  content: "";
}

.material-icons.recycling:before {
  content: "";
}

.material-icons.reddit:before {
  content: "";
}

.material-icons.redeem:before {
  content: "";
}

.material-icons.redo:before {
  content: "";
}

.material-icons.reduce_capacity:before {
  content: "";
}

.material-icons.refresh:before {
  content: "";
}

.material-icons.remember_me:before {
  content: "";
}

.material-icons.remove:before {
  content: "";
}

.material-icons.remove_circle:before {
  content: "";
}

.material-icons.remove_circle_outline:before {
  content: "";
}

.material-icons.remove_done:before {
  content: "";
}

.material-icons.remove_from_queue:before {
  content: "";
}

.material-icons.remove_moderator:before {
  content: "";
}

.material-icons.remove_red_eye:before {
  content: "";
}

.material-icons.remove_road:before {
  content: "";
}

.material-icons.remove_shopping_cart:before {
  content: "";
}

.material-icons.reorder:before {
  content: "";
}

.material-icons.repartition:before {
  content: "";
}

.material-icons.repeat:before {
  content: "";
}

.material-icons.repeat_on:before {
  content: "";
}

.material-icons.repeat_one:before {
  content: "";
}

.material-icons.repeat_one_on:before {
  content: "";
}

.material-icons.replay:before {
  content: "";
}

.material-icons.replay_10:before {
  content: "";
}

.material-icons.replay_30:before {
  content: "";
}

.material-icons.replay_5:before {
  content: "";
}

.material-icons.replay_circle_filled:before {
  content: "";
}

.material-icons.reply:before {
  content: "";
}

.material-icons.reply_all:before {
  content: "";
}

.material-icons.report:before {
  content: "";
}

.material-icons.report_gmailerrorred:before {
  content: "";
}

.material-icons.report_off:before {
  content: "";
}

.material-icons.report_problem:before {
  content: "";
}

.material-icons.request_page:before {
  content: "";
}

.material-icons.request_quote:before {
  content: "";
}

.material-icons.reset_tv:before {
  content: "";
}

.material-icons.restart_alt:before {
  content: "";
}

.material-icons.restaurant:before {
  content: "";
}

.material-icons.restaurant_menu:before {
  content: "";
}

.material-icons.restore:before {
  content: "";
}

.material-icons.restore_from_trash:before {
  content: "";
}

.material-icons.restore_page:before {
  content: "";
}

.material-icons.reviews:before {
  content: "";
}

.material-icons.rice_bowl:before {
  content: "";
}

.material-icons.ring_volume:before {
  content: "";
}

.material-icons.rocket:before {
  content: "";
}

.material-icons.rocket_launch:before {
  content: "";
}

.material-icons.roller_shades:before {
  content: "";
}

.material-icons.roller_shades_closed:before {
  content: "";
}

.material-icons.roller_skating:before {
  content: "";
}

.material-icons.roofing:before {
  content: "";
}

.material-icons.room:before {
  content: "";
}

.material-icons.room_preferences:before {
  content: "";
}

.material-icons.room_service:before {
  content: "";
}

.material-icons.rotate_90_degrees_ccw:before {
  content: "";
}

.material-icons.rotate_90_degrees_cw:before {
  content: "";
}

.material-icons.rotate_left:before {
  content: "";
}

.material-icons.rotate_right:before {
  content: "";
}

.material-icons.roundabout_left:before {
  content: "";
}

.material-icons.roundabout_right:before {
  content: "";
}

.material-icons.rounded_corner:before {
  content: "";
}

.material-icons.route:before {
  content: "";
}

.material-icons.router:before {
  content: "";
}

.material-icons.rowing:before {
  content: "";
}

.material-icons.rss_feed:before {
  content: "";
}

.material-icons.rsvp:before {
  content: "";
}

.material-icons.rtt:before {
  content: "";
}

.material-icons.rule:before {
  content: "";
}

.material-icons.rule_folder:before {
  content: "";
}

.material-icons.run_circle:before {
  content: "";
}

.material-icons.running_with_errors:before {
  content: "";
}

.material-icons.rv_hookup:before {
  content: "";
}

.material-icons.safety_check:before {
  content: "";
}

.material-icons.safety_divider:before {
  content: "";
}

.material-icons.sailing:before {
  content: "";
}

.material-icons.sanitizer:before {
  content: "";
}

.material-icons.satellite:before {
  content: "";
}

.material-icons.satellite_alt:before {
  content: "";
}

.material-icons.save:before {
  content: "";
}

.material-icons.save_alt:before {
  content: "";
}

.material-icons.save_as:before {
  content: "";
}

.material-icons.saved_search:before {
  content: "";
}

.material-icons.savings:before {
  content: "";
}

.material-icons.scale:before {
  content: "";
}

.material-icons.scanner:before {
  content: "";
}

.material-icons.scatter_plot:before {
  content: "";
}

.material-icons.schedule:before {
  content: "";
}

.material-icons.schedule_send:before {
  content: "";
}

.material-icons.schema:before {
  content: "";
}

.material-icons.school:before {
  content: "";
}

.material-icons.science:before {
  content: "";
}

.material-icons.score:before {
  content: "";
}

.material-icons.scoreboard:before {
  content: "";
}

.material-icons.screen_lock_landscape:before {
  content: "";
}

.material-icons.screen_lock_portrait:before {
  content: "";
}

.material-icons.screen_lock_rotation:before {
  content: "";
}

.material-icons.screen_rotation:before {
  content: "";
}

.material-icons.screen_rotation_alt:before {
  content: "";
}

.material-icons.screen_search_desktop:before {
  content: "";
}

.material-icons.screen_share:before {
  content: "";
}

.material-icons.screenshot:before {
  content: "";
}

.material-icons.screenshot_monitor:before {
  content: "";
}

.material-icons.scuba_diving:before {
  content: "";
}

.material-icons.sd:before {
  content: "";
}

.material-icons.sd_card:before {
  content: "";
}

.material-icons.sd_card_alert:before {
  content: "";
}

.material-icons.sd_storage:before {
  content: "";
}

.material-icons.search:before {
  content: "";
}

.material-icons.search_off:before {
  content: "";
}

.material-icons.security:before {
  content: "";
}

.material-icons.security_update:before {
  content: "";
}

.material-icons.security_update_good:before {
  content: "";
}

.material-icons.security_update_warning:before {
  content: "";
}

.material-icons.segment:before {
  content: "";
}

.material-icons.select_all:before {
  content: "";
}

.material-icons.self_improvement:before {
  content: "";
}

.material-icons.sell:before {
  content: "";
}

.material-icons.send:before {
  content: "";
}

.material-icons.send_and_archive:before {
  content: "";
}

.material-icons.send_time_extension:before {
  content: "";
}

.material-icons.send_to_mobile:before {
  content: "";
}

.material-icons.sensor_door:before {
  content: "";
}

.material-icons.sensor_occupied:before {
  content: "";
}

.material-icons.sensor_window:before {
  content: "";
}

.material-icons.sensors:before {
  content: "";
}

.material-icons.sensors_off:before {
  content: "";
}

.material-icons.sentiment_dissatisfied:before {
  content: "";
}

.material-icons.sentiment_neutral:before {
  content: "";
}

.material-icons.sentiment_satisfied:before {
  content: "";
}

.material-icons.sentiment_satisfied_alt:before {
  content: "";
}

.material-icons.sentiment_very_dissatisfied:before {
  content: "";
}

.material-icons.sentiment_very_satisfied:before {
  content: "";
}

.material-icons.set_meal:before {
  content: "";
}

.material-icons.settings:before {
  content: "";
}

.material-icons.settings_accessibility:before {
  content: "";
}

.material-icons.settings_applications:before {
  content: "";
}

.material-icons.settings_backup_restore:before {
  content: "";
}

.material-icons.settings_bluetooth:before {
  content: "";
}

.material-icons.settings_brightness:before {
  content: "";
}

.material-icons.settings_cell:before {
  content: "";
}

.material-icons.settings_display:before {
  content: "";
}

.material-icons.settings_ethernet:before {
  content: "";
}

.material-icons.settings_input_antenna:before {
  content: "";
}

.material-icons.settings_input_component:before {
  content: "";
}

.material-icons.settings_input_composite:before {
  content: "";
}

.material-icons.settings_input_hdmi:before {
  content: "";
}

.material-icons.settings_input_svideo:before {
  content: "";
}

.material-icons.settings_overscan:before {
  content: "";
}

.material-icons.settings_phone:before {
  content: "";
}

.material-icons.settings_power:before {
  content: "";
}

.material-icons.settings_remote:before {
  content: "";
}

.material-icons.settings_suggest:before {
  content: "";
}

.material-icons.settings_system_daydream:before {
  content: "";
}

.material-icons.settings_voice:before {
  content: "";
}

.material-icons.severe_cold:before {
  content: "";
}

.material-icons.share:before {
  content: "";
}

.material-icons.share_arrival_time:before {
  content: "";
}

.material-icons.share_location:before {
  content: "";
}

.material-icons.shield:before {
  content: "";
}

.material-icons.shield_moon:before {
  content: "";
}

.material-icons.shop:before {
  content: "";
}

.material-icons.shop_2:before {
  content: "";
}

.material-icons.shop_two:before {
  content: "";
}

.material-icons.shopify:before {
  content: "";
}

.material-icons.shopping_bag:before {
  content: "";
}

.material-icons.shopping_basket:before {
  content: "";
}

.material-icons.shopping_cart:before {
  content: "";
}

.material-icons.shopping_cart_checkout:before {
  content: "";
}

.material-icons.short_text:before {
  content: "";
}

.material-icons.shortcut:before {
  content: "";
}

.material-icons.show_chart:before {
  content: "";
}

.material-icons.shower:before {
  content: "";
}

.material-icons.shuffle:before {
  content: "";
}

.material-icons.shuffle_on:before {
  content: "";
}

.material-icons.shutter_speed:before {
  content: "";
}

.material-icons.sick:before {
  content: "";
}

.material-icons.sign_language:before {
  content: "";
}

.material-icons.signal_cellular_0_bar:before {
  content: "";
}

.material-icons.signal_cellular_4_bar:before {
  content: "";
}

.material-icons.signal_cellular_alt:before {
  content: "";
}

.material-icons.signal_cellular_alt_1_bar:before {
  content: "";
}

.material-icons.signal_cellular_alt_2_bar:before {
  content: "";
}

.material-icons.signal_cellular_connected_no_internet_0_bar:before {
  content: "";
}

.material-icons.signal_cellular_connected_no_internet_4_bar:before {
  content: "";
}

.material-icons.signal_cellular_no_sim:before {
  content: "";
}

.material-icons.signal_cellular_nodata:before {
  content: "";
}

.material-icons.signal_cellular_null:before {
  content: "";
}

.material-icons.signal_cellular_off:before {
  content: "";
}

.material-icons.signal_wifi_0_bar:before {
  content: "";
}

.material-icons.signal_wifi_4_bar:before {
  content: "";
}

.material-icons.signal_wifi_4_bar_lock:before {
  content: "";
}

.material-icons.signal_wifi_bad:before {
  content: "";
}

.material-icons.signal_wifi_connected_no_internet_4:before {
  content: "";
}

.material-icons.signal_wifi_off:before {
  content: "";
}

.material-icons.signal_wifi_statusbar_4_bar:before {
  content: "";
}

.material-icons.signal_wifi_statusbar_connected_no_internet_4:before {
  content: "";
}

.material-icons.signal_wifi_statusbar_null:before {
  content: "";
}

.material-icons.signpost:before {
  content: "";
}

.material-icons.sim_card:before {
  content: "";
}

.material-icons.sim_card_alert:before {
  content: "";
}

.material-icons.sim_card_download:before {
  content: "";
}

.material-icons.single_bed:before {
  content: "";
}

.material-icons.sip:before {
  content: "";
}

.material-icons.skateboarding:before {
  content: "";
}

.material-icons.skip_next:before {
  content: "";
}

.material-icons.skip_previous:before {
  content: "";
}

.material-icons.sledding:before {
  content: "";
}

.material-icons.slideshow:before {
  content: "";
}

.material-icons.slow_motion_video:before {
  content: "";
}

.material-icons.smart_button:before {
  content: "";
}

.material-icons.smart_display:before {
  content: "";
}

.material-icons.smart_screen:before {
  content: "";
}

.material-icons.smart_toy:before {
  content: "";
}

.material-icons.smartphone:before {
  content: "";
}

.material-icons.smoke_free:before {
  content: "";
}

.material-icons.smoking_rooms:before {
  content: "";
}

.material-icons.sms:before {
  content: "";
}

.material-icons.sms_failed:before {
  content: "";
}

.material-icons.snapchat:before {
  content: "";
}

.material-icons.snippet_folder:before {
  content: "";
}

.material-icons.snooze:before {
  content: "";
}

.material-icons.snowboarding:before {
  content: "";
}

.material-icons.snowing:before {
  content: "";
}

.material-icons.snowmobile:before {
  content: "";
}

.material-icons.snowshoeing:before {
  content: "";
}

.material-icons.soap:before {
  content: "";
}

.material-icons.social_distance:before {
  content: "";
}

.material-icons.solar_power:before {
  content: "";
}

.material-icons.sort:before {
  content: "";
}

.material-icons.sort_by_alpha:before {
  content: "";
}

.material-icons.sos:before {
  content: "";
}

.material-icons.soup_kitchen:before {
  content: "";
}

.material-icons.source:before {
  content: "";
}

.material-icons.south:before {
  content: "";
}

.material-icons.south_america:before {
  content: "";
}

.material-icons.south_east:before {
  content: "";
}

.material-icons.south_west:before {
  content: "";
}

.material-icons.spa:before {
  content: "";
}

.material-icons.space_bar:before {
  content: "";
}

.material-icons.space_dashboard:before {
  content: "";
}

.material-icons.spatial_audio:before {
  content: "";
}

.material-icons.spatial_audio_off:before {
  content: "";
}

.material-icons.spatial_tracking:before {
  content: "";
}

.material-icons.speaker:before {
  content: "";
}

.material-icons.speaker_group:before {
  content: "";
}

.material-icons.speaker_notes:before {
  content: "";
}

.material-icons.speaker_notes_off:before {
  content: "";
}

.material-icons.speaker_phone:before {
  content: "";
}

.material-icons.speed:before {
  content: "";
}

.material-icons.spellcheck:before {
  content: "";
}

.material-icons.splitscreen:before {
  content: "";
}

.material-icons.spoke:before {
  content: "";
}

.material-icons.sports:before {
  content: "";
}

.material-icons.sports_bar:before {
  content: "";
}

.material-icons.sports_baseball:before {
  content: "";
}

.material-icons.sports_basketball:before {
  content: "";
}

.material-icons.sports_cricket:before {
  content: "";
}

.material-icons.sports_esports:before {
  content: "";
}

.material-icons.sports_football:before {
  content: "";
}

.material-icons.sports_golf:before {
  content: "";
}

.material-icons.sports_gymnastics:before {
  content: "";
}

.material-icons.sports_handball:before {
  content: "";
}

.material-icons.sports_hockey:before {
  content: "";
}

.material-icons.sports_kabaddi:before {
  content: "";
}

.material-icons.sports_martial_arts:before {
  content: "";
}

.material-icons.sports_mma:before {
  content: "";
}

.material-icons.sports_motorsports:before {
  content: "";
}

.material-icons.sports_rugby:before {
  content: "";
}

.material-icons.sports_score:before {
  content: "";
}

.material-icons.sports_soccer:before {
  content: "";
}

.material-icons.sports_tennis:before {
  content: "";
}

.material-icons.sports_volleyball:before {
  content: "";
}

.material-icons.square:before {
  content: "";
}

.material-icons.square_foot:before {
  content: "";
}

.material-icons.ssid_chart:before {
  content: "";
}

.material-icons.stacked_bar_chart:before {
  content: "";
}

.material-icons.stacked_line_chart:before {
  content: "";
}

.material-icons.stadium:before {
  content: "";
}

.material-icons.stairs:before {
  content: "";
}

.material-icons.star:before {
  content: "";
}

.material-icons.star_border:before {
  content: "";
}

.material-icons.star_border_purple500:before {
  content: "";
}

.material-icons.star_half:before {
  content: "";
}

.material-icons.star_outline:before {
  content: "";
}

.material-icons.star_purple500:before {
  content: "";
}

.material-icons.star_rate:before {
  content: "";
}

.material-icons.stars:before {
  content: "";
}

.material-icons.start:before {
  content: "";
}

.material-icons.stay_current_landscape:before {
  content: "";
}

.material-icons.stay_current_portrait:before {
  content: "";
}

.material-icons.stay_primary_landscape:before {
  content: "";
}

.material-icons.stay_primary_portrait:before {
  content: "";
}

.material-icons.sticky_note_2:before {
  content: "";
}

.material-icons.stop:before {
  content: "";
}

.material-icons.stop_circle:before {
  content: "";
}

.material-icons.stop_screen_share:before {
  content: "";
}

.material-icons.storage:before {
  content: "";
}

.material-icons.store:before {
  content: "";
}

.material-icons.store_mall_directory:before {
  content: "";
}

.material-icons.storefront:before {
  content: "";
}

.material-icons.storm:before {
  content: "";
}

.material-icons.straight:before {
  content: "";
}

.material-icons.straighten:before {
  content: "";
}

.material-icons.stream:before {
  content: "";
}

.material-icons.streetview:before {
  content: "";
}

.material-icons.strikethrough_s:before {
  content: "";
}

.material-icons.stroller:before {
  content: "";
}

.material-icons.style:before {
  content: "";
}

.material-icons.subdirectory_arrow_left:before {
  content: "";
}

.material-icons.subdirectory_arrow_right:before {
  content: "";
}

.material-icons.subject:before {
  content: "";
}

.material-icons.subscript:before {
  content: "";
}

.material-icons.subscriptions:before {
  content: "";
}

.material-icons.subtitles:before {
  content: "";
}

.material-icons.subtitles_off:before {
  content: "";
}

.material-icons.subway:before {
  content: "";
}

.material-icons.summarize:before {
  content: "";
}

.material-icons.sunny:before {
  content: "";
}

.material-icons.sunny_snowing:before {
  content: "";
}

.material-icons.superscript:before {
  content: "";
}

.material-icons.supervised_user_circle:before {
  content: "";
}

.material-icons.supervisor_account:before {
  content: "";
}

.material-icons.support:before {
  content: "";
}

.material-icons.support_agent:before {
  content: "";
}

.material-icons.surfing:before {
  content: "";
}

.material-icons.surround_sound:before {
  content: "";
}

.material-icons.swap_calls:before {
  content: "";
}

.material-icons.swap_horiz:before {
  content: "";
}

.material-icons.swap_horizontal_circle:before {
  content: "";
}

.material-icons.swap_vert:before {
  content: "";
}

.material-icons.swap_vert_circle:before, .material-icons.swap_vertical_circle:before {
  content: "";
}

.material-icons.swipe:before {
  content: "";
}

.material-icons.swipe_down:before {
  content: "";
}

.material-icons.swipe_down_alt:before {
  content: "";
}

.material-icons.swipe_left:before {
  content: "";
}

.material-icons.swipe_left_alt:before {
  content: "";
}

.material-icons.swipe_right:before {
  content: "";
}

.material-icons.swipe_right_alt:before {
  content: "";
}

.material-icons.swipe_up:before {
  content: "";
}

.material-icons.swipe_up_alt:before {
  content: "";
}

.material-icons.swipe_vertical:before {
  content: "";
}

.material-icons.switch_access_shortcut:before {
  content: "";
}

.material-icons.switch_access_shortcut_add:before {
  content: "";
}

.material-icons.switch_account:before {
  content: "";
}

.material-icons.switch_camera:before {
  content: "";
}

.material-icons.switch_left:before {
  content: "";
}

.material-icons.switch_right:before {
  content: "";
}

.material-icons.switch_video:before {
  content: "";
}

.material-icons.synagogue:before {
  content: "";
}

.material-icons.sync:before {
  content: "";
}

.material-icons.sync_alt:before {
  content: "";
}

.material-icons.sync_disabled:before {
  content: "";
}

.material-icons.sync_lock:before {
  content: "";
}

.material-icons.sync_problem:before {
  content: "";
}

.material-icons.system_security_update:before {
  content: "";
}

.material-icons.system_security_update_good:before {
  content: "";
}

.material-icons.system_security_update_warning:before {
  content: "";
}

.material-icons.system_update:before {
  content: "";
}

.material-icons.system_update_alt:before, .material-icons.system_update_tv:before {
  content: "";
}

.material-icons.tab:before {
  content: "";
}

.material-icons.tab_unselected:before {
  content: "";
}

.material-icons.table_bar:before {
  content: "";
}

.material-icons.table_chart:before {
  content: "";
}

.material-icons.table_restaurant:before {
  content: "";
}

.material-icons.table_rows:before {
  content: "";
}

.material-icons.table_view:before {
  content: "";
}

.material-icons.tablet:before {
  content: "";
}

.material-icons.tablet_android:before {
  content: "";
}

.material-icons.tablet_mac:before {
  content: "";
}

.material-icons.tag:before {
  content: "";
}

.material-icons.tag_faces:before {
  content: "";
}

.material-icons.takeout_dining:before {
  content: "";
}

.material-icons.tap_and_play:before {
  content: "";
}

.material-icons.tapas:before {
  content: "";
}

.material-icons.task:before {
  content: "";
}

.material-icons.task_alt:before {
  content: "";
}

.material-icons.taxi_alert:before {
  content: "";
}

.material-icons.telegram:before {
  content: "";
}

.material-icons.temple_buddhist:before {
  content: "";
}

.material-icons.temple_hindu:before {
  content: "";
}

.material-icons.terminal:before {
  content: "";
}

.material-icons.terrain:before {
  content: "";
}

.material-icons.text_decrease:before {
  content: "";
}

.material-icons.text_fields:before {
  content: "";
}

.material-icons.text_format:before {
  content: "";
}

.material-icons.text_increase:before {
  content: "";
}

.material-icons.text_rotate_up:before {
  content: "";
}

.material-icons.text_rotate_vertical:before {
  content: "";
}

.material-icons.text_rotation_angledown:before {
  content: "";
}

.material-icons.text_rotation_angleup:before {
  content: "";
}

.material-icons.text_rotation_down:before {
  content: "";
}

.material-icons.text_rotation_none:before {
  content: "";
}

.material-icons.text_snippet:before {
  content: "";
}

.material-icons.textsms:before {
  content: "";
}

.material-icons.texture:before {
  content: "";
}

.material-icons.theater_comedy:before {
  content: "";
}

.material-icons.theaters:before {
  content: "";
}

.material-icons.thermostat:before {
  content: "";
}

.material-icons.thermostat_auto:before {
  content: "";
}

.material-icons.thumb_down:before {
  content: "";
}

.material-icons.thumb_down_alt:before {
  content: "";
}

.material-icons.thumb_down_off_alt:before {
  content: "";
}

.material-icons.thumb_up:before {
  content: "";
}

.material-icons.thumb_up_alt:before {
  content: "";
}

.material-icons.thumb_up_off_alt:before {
  content: "";
}

.material-icons.thumbs_up_down:before {
  content: "";
}

.material-icons.thunderstorm:before {
  content: "";
}

.material-icons.tiktok:before {
  content: "";
}

.material-icons.time_to_leave:before {
  content: "";
}

.material-icons.timelapse:before {
  content: "";
}

.material-icons.timeline:before {
  content: "";
}

.material-icons.timer:before {
  content: "";
}

.material-icons.timer_10:before {
  content: "";
}

.material-icons.timer_10_select:before {
  content: "";
}

.material-icons.timer_3:before {
  content: "";
}

.material-icons.timer_3_select:before {
  content: "";
}

.material-icons.timer_off:before {
  content: "";
}

.material-icons.tips_and_updates:before {
  content: "";
}

.material-icons.tire_repair:before {
  content: "";
}

.material-icons.title:before {
  content: "";
}

.material-icons.toc:before {
  content: "";
}

.material-icons.today:before {
  content: "";
}

.material-icons.toggle_off:before {
  content: "";
}

.material-icons.toggle_on:before {
  content: "";
}

.material-icons.token:before {
  content: "";
}

.material-icons.toll:before {
  content: "";
}

.material-icons.tonality:before {
  content: "";
}

.material-icons.topic:before {
  content: "";
}

.material-icons.tornado:before {
  content: "";
}

.material-icons.touch_app:before {
  content: "";
}

.material-icons.tour:before {
  content: "";
}

.material-icons.toys:before {
  content: "";
}

.material-icons.track_changes:before {
  content: "";
}

.material-icons.traffic:before {
  content: "";
}

.material-icons.train:before {
  content: "";
}

.material-icons.tram:before {
  content: "";
}

.material-icons.transcribe:before {
  content: "";
}

.material-icons.transfer_within_a_station:before {
  content: "";
}

.material-icons.transform:before {
  content: "";
}

.material-icons.transgender:before {
  content: "";
}

.material-icons.transit_enterexit:before {
  content: "";
}

.material-icons.translate:before {
  content: "";
}

.material-icons.travel_explore:before {
  content: "";
}

.material-icons.trending_down:before {
  content: "";
}

.material-icons.trending_flat:before, .material-icons.trending_neutral:before {
  content: "";
}

.material-icons.trending_up:before {
  content: "";
}

.material-icons.trip_origin:before {
  content: "";
}

.material-icons.troubleshoot:before {
  content: "";
}

.material-icons.try:before {
  content: "";
}

.material-icons.tsunami:before {
  content: "";
}

.material-icons.tty:before {
  content: "";
}

.material-icons.tune:before {
  content: "";
}

.material-icons.tungsten:before {
  content: "";
}

.material-icons.turn_left:before {
  content: "";
}

.material-icons.turn_right:before {
  content: "";
}

.material-icons.turn_sharp_left:before {
  content: "";
}

.material-icons.turn_sharp_right:before {
  content: "";
}

.material-icons.turn_slight_left:before {
  content: "";
}

.material-icons.turn_slight_right:before {
  content: "";
}

.material-icons.turned_in:before {
  content: "";
}

.material-icons.turned_in_not:before {
  content: "";
}

.material-icons.tv:before {
  content: "";
}

.material-icons.tv_off:before {
  content: "";
}

.material-icons.two_wheeler:before {
  content: "";
}

.material-icons.type_specimen:before {
  content: "";
}

.material-icons.u_turn_left:before {
  content: "";
}

.material-icons.u_turn_right:before {
  content: "";
}

.material-icons.umbrella:before {
  content: "";
}

.material-icons.unarchive:before {
  content: "";
}

.material-icons.undo:before {
  content: "";
}

.material-icons.unfold_less:before {
  content: "";
}

.material-icons.unfold_more:before {
  content: "";
}

.material-icons.unpublished:before {
  content: "";
}

.material-icons.unsubscribe:before {
  content: "";
}

.material-icons.upcoming:before {
  content: "";
}

.material-icons.update:before {
  content: "";
}

.material-icons.update_disabled:before {
  content: "";
}

.material-icons.upgrade:before {
  content: "";
}

.material-icons.upload:before {
  content: "";
}

.material-icons.upload_file:before {
  content: "";
}

.material-icons.usb:before {
  content: "";
}

.material-icons.usb_off:before {
  content: "";
}

.material-icons.vaccines:before {
  content: "";
}

.material-icons.vape_free:before {
  content: "";
}

.material-icons.vaping_rooms:before {
  content: "";
}

.material-icons.verified:before {
  content: "";
}

.material-icons.verified_user:before {
  content: "";
}

.material-icons.vertical_align_bottom:before {
  content: "";
}

.material-icons.vertical_align_center:before {
  content: "";
}

.material-icons.vertical_align_top:before {
  content: "";
}

.material-icons.vertical_distribute:before {
  content: "";
}

.material-icons.vertical_shades:before {
  content: "";
}

.material-icons.vertical_shades_closed:before {
  content: "";
}

.material-icons.vertical_split:before {
  content: "";
}

.material-icons.vibration:before {
  content: "";
}

.material-icons.video_call:before {
  content: "";
}

.material-icons.video_camera_back:before {
  content: "";
}

.material-icons.video_camera_front:before {
  content: "";
}

.material-icons.video_collection:before {
  content: "";
}

.material-icons.video_file:before {
  content: "";
}

.material-icons.video_label:before {
  content: "";
}

.material-icons.video_library:before {
  content: "";
}

.material-icons.video_settings:before {
  content: "";
}

.material-icons.video_stable:before {
  content: "";
}

.material-icons.videocam:before {
  content: "";
}

.material-icons.videocam_off:before {
  content: "";
}

.material-icons.videogame_asset:before {
  content: "";
}

.material-icons.videogame_asset_off:before {
  content: "";
}

.material-icons.view_agenda:before {
  content: "";
}

.material-icons.view_array:before {
  content: "";
}

.material-icons.view_carousel:before {
  content: "";
}

.material-icons.view_column:before {
  content: "";
}

.material-icons.view_comfortable:before, .material-icons.view_comfy:before {
  content: "";
}

.material-icons.view_comfy_alt:before {
  content: "";
}

.material-icons.view_compact:before {
  content: "";
}

.material-icons.view_compact_alt:before {
  content: "";
}

.material-icons.view_cozy:before {
  content: "";
}

.material-icons.view_day:before {
  content: "";
}

.material-icons.view_headline:before {
  content: "";
}

.material-icons.view_in_ar:before {
  content: "";
}

.material-icons.view_kanban:before {
  content: "";
}

.material-icons.view_list:before {
  content: "";
}

.material-icons.view_module:before {
  content: "";
}

.material-icons.view_quilt:before {
  content: "";
}

.material-icons.view_sidebar:before {
  content: "";
}

.material-icons.view_stream:before {
  content: "";
}

.material-icons.view_timeline:before {
  content: "";
}

.material-icons.view_week:before {
  content: "";
}

.material-icons.vignette:before {
  content: "";
}

.material-icons.villa:before {
  content: "";
}

.material-icons.visibility:before {
  content: "";
}

.material-icons.visibility_off:before {
  content: "";
}

.material-icons.voice_chat:before {
  content: "";
}

.material-icons.voice_over_off:before {
  content: "";
}

.material-icons.voicemail:before {
  content: "";
}

.material-icons.volcano:before {
  content: "";
}

.material-icons.volume_down:before {
  content: "";
}

.material-icons.volume_down_alt:before {
  content: "";
}

.material-icons.volume_mute:before {
  content: "";
}

.material-icons.volume_off:before {
  content: "";
}

.material-icons.volume_up:before {
  content: "";
}

.material-icons.volunteer_activism:before {
  content: "";
}

.material-icons.vpn_key:before {
  content: "";
}

.material-icons.vpn_key_off:before {
  content: "";
}

.material-icons.vpn_lock:before {
  content: "";
}

.material-icons.vrpano:before {
  content: "";
}

.material-icons.wallet:before {
  content: "";
}

.material-icons.wallet_giftcard:before {
  content: "";
}

.material-icons.wallet_membership:before {
  content: "";
}

.material-icons.wallet_travel:before {
  content: "";
}

.material-icons.wallpaper:before {
  content: "";
}

.material-icons.warehouse:before {
  content: "";
}

.material-icons.warning:before {
  content: "";
}

.material-icons.warning_amber:before {
  content: "";
}

.material-icons.wash:before {
  content: "";
}

.material-icons.watch:before {
  content: "";
}

.material-icons.watch_later:before {
  content: "";
}

.material-icons.watch_off:before {
  content: "";
}

.material-icons.water:before {
  content: "";
}

.material-icons.water_damage:before {
  content: "";
}

.material-icons.water_drop:before {
  content: "";
}

.material-icons.waterfall_chart:before {
  content: "";
}

.material-icons.waves:before {
  content: "";
}

.material-icons.waving_hand:before {
  content: "";
}

.material-icons.wb_auto:before {
  content: "";
}

.material-icons.wb_cloudy:before {
  content: "";
}

.material-icons.wb_incandescent:before {
  content: "";
}

.material-icons.wb_iridescent:before {
  content: "";
}

.material-icons.wb_shade:before {
  content: "";
}

.material-icons.wb_sunny:before {
  content: "";
}

.material-icons.wb_twighlight:before {
  content: "";
}

.material-icons.wb_twilight:before {
  content: "";
}

.material-icons.wc:before {
  content: "";
}

.material-icons.web:before {
  content: "";
}

.material-icons.web_asset:before {
  content: "";
}

.material-icons.web_asset_off:before {
  content: "";
}

.material-icons.web_stories:before {
  content: "";
}

.material-icons.webhook:before {
  content: "";
}

.material-icons.wechat:before {
  content: "";
}

.material-icons.weekend:before {
  content: "";
}

.material-icons.west:before {
  content: "";
}

.material-icons.whatsapp:before {
  content: "";
}

.material-icons.whatshot:before {
  content: "";
}

.material-icons.wheelchair_pickup:before {
  content: "";
}

.material-icons.where_to_vote:before {
  content: "";
}

.material-icons.widgets:before {
  content: "";
}

.material-icons.width_full:before {
  content: "";
}

.material-icons.width_normal:before {
  content: "";
}

.material-icons.width_wide:before {
  content: "";
}

.material-icons.wifi:before {
  content: "";
}

.material-icons.wifi_1_bar:before {
  content: "";
}

.material-icons.wifi_2_bar:before {
  content: "";
}

.material-icons.wifi_calling:before {
  content: "";
}

.material-icons.wifi_calling_3:before {
  content: "";
}

.material-icons.wifi_channel:before {
  content: "";
}

.material-icons.wifi_find:before {
  content: "";
}

.material-icons.wifi_lock:before {
  content: "";
}

.material-icons.wifi_off:before {
  content: "";
}

.material-icons.wifi_password:before {
  content: "";
}

.material-icons.wifi_protected_setup:before {
  content: "";
}

.material-icons.wifi_tethering:before {
  content: "";
}

.material-icons.wifi_tethering_error:before {
  content: "";
}

.material-icons.wifi_tethering_error_rounded:before {
  content: "";
}

.material-icons.wifi_tethering_off:before {
  content: "";
}

.material-icons.wind_power:before {
  content: "";
}

.material-icons.window:before {
  content: "";
}

.material-icons.wine_bar:before {
  content: "";
}

.material-icons.woman:before {
  content: "";
}

.material-icons.woo_commerce:before {
  content: "";
}

.material-icons.wordpress:before {
  content: "";
}

.material-icons.work:before {
  content: "";
}

.material-icons.work_history:before {
  content: "";
}

.material-icons.work_off:before {
  content: "";
}

.material-icons.work_outline:before {
  content: "";
}

.material-icons.workspace_premium:before {
  content: "";
}

.material-icons.workspaces:before {
  content: "";
}

.material-icons.workspaces_filled:before {
  content: "";
}

.material-icons.workspaces_outline:before {
  content: "";
}

.material-icons.wrap_text:before {
  content: "";
}

.material-icons.wrong_location:before {
  content: "";
}

.material-icons.wysiwyg:before {
  content: "";
}

.material-icons.yard:before {
  content: "";
}

.material-icons.youtube_searched_for:before {
  content: "";
}

.material-icons.zoom_in:before {
  content: "";
}

.material-icons.zoom_in_map:before {
  content: "";
}

.material-icons.zoom_out:before {
  content: "";
}

.material-icons.zoom_out_map:before {
  content: "";
}

/*# sourceMappingURL=index.e4aad34c.css.map */
